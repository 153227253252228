import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import Loading from '../../common/components/Loading'
import NoContent from '../../common/components/NoContent'
import Paginate from '../../common/components/Paginate'
import {slug, sttPage, urlImage} from '../../common/utils/Utils'
import {Link} from 'react-router-dom'
import {dateUtils} from "../../common/utils/DateUtils";
import {standardStore} from "./StandardStore";
import {newsStore} from "../news/NewsStore";
import PopupConfirm from "../../common/components/PopupConfirm";


const StandardPage = () => {

    const handlePageClick = async (data: any) => {
        standardStore.page = data.selected;
        await standardStore.getStandard();
    };

    const returnType = (type: any) => {
        switch (type) {
            case "GAP_STANDARD":
                return "Tiêu chuẩn GAP"
            case "ORGANIC_AGRICULTURE_STANDARD":
                return "Tiêu chuẩn nông nghiệp hữu cơ"
        }
    }

    useEffect(() => {
        standardStore.getStandard().then()
    }, [])

    return (
        <>
            <div className='d-flex align-items-center justify-content-between mb-6'>
                <h1 className='d-flex text-dark fw-bolder fs-3 align-items-center my-1'>Bài viết</h1>
                <Link to={'/add-standard?type=add'} onClick={() => standardStore.clearForm()}>
                    <button className='btn btn-sm btn-primary'>Tạo mới</button>
                </Link>
            </div>

            <div className="card-title mb-6 d-flex align-items-center">
                <div className="d-flex align-items-center">
                    <div className='d-flex align-items-center mr-2 position-relative'>
                        <i className='bi bi-search cursor-pointer fs-1x fs-3 position-absolute ms-4'
                           onClick={async () => standardStore.getStandard()}/>
                        <input type='text' value={standardStore.keyword}
                               onChange={(e: any) => standardStore.keyword = e.currentTarget.value}
                               onKeyDown={async (e: any) => {
                                   if (e.key === 'Enter') {
                                       await standardStore.getStandard()
                                   }
                               }} data-kt-ecommerce-order-filter='search'
                               className='form-control form-control-solid w-250px ps-12'
                               placeholder='Tìm kiếm theo tiêu đề'/>
                    </div>
                </div>
                <div className="w-100 mw-250px" style={{marginLeft: 20}}>
                    <select onChange={async (e: any) => {
                        standardStore.state = e.currentTarget.value;
                        await standardStore.getStandard();
                    }} className="form-select form-select-solid">
                        <option value="">Chọn loại bài viết</option>
                        <option value="GAP_STANDARD">Tiêu chuẩn GAP</option>
                        <option value="ORGANIC_AGRICULTURE_STANDARD">Tiêu chuẩn nông nghiệp hữu cơ</option>
                    </select>
                </div>
            </div>

            <div id="kt_ecommerce_sales_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">

                {standardStore.isLoading ? <Loading/> : <div className='table-responsive'>
                    {standardStore.listStandard && standardStore.listStandard.length > 0 ?
                        <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
                            <thead>
                            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                <th>STT</th>
                                <th>Ảnh</th>
                                <th>Tiêu đề</th>
                                <th>Loại</th>
                                <th>Thời gian cập nhật</th>
                                <th>Trạng thái</th>
                                <th className="text-center">Thao tác</th>
                            </tr>
                            </thead>
                            <tbody>
                            {standardStore.listStandard.map((item: any, i: number) => {
                                return (
                                    <tr key={i}>
                                        <td>{sttPage(standardStore.page, i)}</td>
                                        <td>
                                            <div className="symbol symbol-30px symbol-md-40px">
                                                {item.thumbnail && <img src={urlImage(item.thumbnail)} alt=""/>}
                                            </div>
                                        </td>
                                        <td>{item.title}</td>
                                        <td>{returnType(item.type)}</td>
                                        <td>{dateUtils.formatDate(item.updatedAt)}</td>
                                        <td>{item.status == 'PUBLIC' ? 'Hiển thị' : 'Ẩn'}</td>
                                        <td width="15%" className="text-center">
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <i title={item.status == 'PUBLIC' ? 'Ẩn bài viết' : 'Hiển thị bài viết'}
                                                   onClick={() => newsStore.changeStatus(item)}
                                                   className={`fas cursor-pointer fa-toggle-${item.status === "PUBLIC" ? 'on text-primary' : 'off'}`}
                                                   style={{fontSize: 32}}/>
                                                <span className="p-2"/>
                                                <Link to={`/update-standard/${slug(item.title)}/${item.id}?type=edit`}
                                                      title="Chỉnh sửa" className="btn btn-icon btn-warning btn-sm"><i
                                                    className="fas fa-pen"/></Link>
                                                <span className="p-2"/>
                                                <a href="#" title="Xóa" className="btn btn-icon btn-danger btn-sm"
                                                   data-bs-toggle='modal' data-bs-target='#popup_confirm'
                                                   onClick={() => standardStore.id = item.id}><i
                                                    className="fas fa-trash-alt"/></a>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table> : <NoContent/>}
                    <Paginate callback={handlePageClick} totalPage={standardStore.totalPage} currentPage={standardStore.page}/>
                </div>}
            </div>
            <PopupConfirm isLoading={standardStore.isLoadingBt} action={standardStore.delete}/>
        </>
    )
}

export default observer(StandardPage)