import {observer} from 'mobx-react'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import Loading from '../../common/components/Loading'
import NoContent from '../../common/components/NoContent'
import Paginate from '../../common/components/Paginate'
import {recruitmentStore} from './RecruitmentStore'
import PopupConfirm from '../../common/components/PopupConfirm'
import {number_format, slug, sttPage} from '../../common/utils/Utils'
import {dateUtils} from '../../common/utils/DateUtils'
import {occupationStore} from "./occupation/OccupationStore";
import {Moment} from "../../common/utils/Moment";


const RecruitmentPage = () => {
    const handlePageClick = async (data: any) => {
        recruitmentStore.page = data.selected
        await recruitmentStore.getRecruitment()
    }

    const rangeWage = (item: any) => {
        if (!item.salaryTo && !item.salaryFrom) {
            return 'Thỏa thuận'
        } else if (item.salaryTo && item.salaryFrom) {
            return `${number_format(item.salaryFrom)} Đ - ${number_format(item.salaryTo)} Đ`
        } else if (!item.salaryTo && item.salaryFrom) {
            return `Up to ${number_format(item.salaryFrom)} Đ`
        } else if (item.salaryTo && !item.salaryFrom) {
            return `Up to ${number_format(item.salaryTo)} Đ`
        }
    }



    useEffect(() => {
        recruitmentStore.getRecruitment().then()
        occupationStore.getOccupation(100).then()
    }, [])


    return (
        <>
            <div className='d-flex align-items-center justify-content-between mb-6'>
                <h1 className='d-flex text-dark fw-bolder fs-3 align-items-center my-1'>Tin tuyển dụng</h1>
                <Link to={'/add-recruitment?type=add'} onClick={() => recruitmentStore.clearForm()}>
                    <button className='btn btn-sm btn-primary'>Tạo mới</button>
                </Link>
            </div>

            <div id='kt_ecommerce_sales_table_wrapper' className='dataTables_wrapper dt-bootstrap4 no-footer'>
                <div className="d-flex mb-4">
                    <div className='d-flex align-items-center position-relative'>
                        <i className='bi bi-search cursor-pointer fs-1x fs-3 position-absolute ms-4'
                           onClick={async () => {
                               recruitmentStore.page = 0;
                               await recruitmentStore.getRecruitment()
                           }}/>
                        <input type='text' value={recruitmentStore.name}
                               onChange={(e: any) => recruitmentStore.name = e.currentTarget.value}
                               onKeyDown={async (e: any) => {
                                   if (e.key === 'Enter') {
                                       recruitmentStore.page = 0;
                                       await recruitmentStore.getRecruitment()
                                   }
                               }} data-kt-ecommerce-order-filter='search'
                               className='form-control form-control-solid w-250px ps-12'
                               placeholder='Tìm kiếm vị trí ứng tuyển'/>
                    </div>
                    <div className="w-100 mw-250px" style={{marginLeft: 16}}>
                        <select onChange={async (e: any) => {
                            recruitmentStore.occupationId = e.currentTarget.value;
                            recruitmentStore.page = 0;
                            await recruitmentStore.getRecruitment()
                        }} className="form-select form-select-solid">
                            <option value="">Chọn ngành nghề</option>
                            {occupationStore.listOccupation && occupationStore.listOccupation.map((item, i) => {
                                return <option value={item.id} key={i}>{item.name}</option>
                            })}
                        </select>
                    </div>
                </div>
                {recruitmentStore.isLoading ? <Loading/> : <div className='table-responsive'>
                    {recruitmentStore.listRecruitment && recruitmentStore.listRecruitment.length > 0 ?
                        <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
                            <thead>
                            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                <th>STT</th>
                                <th>Vị trí ứng tuyển</th>
                                <th>Tên cty</th>
                                <th>Ngành nghề</th>
                                <th>Kinh nghiệm</th>
                                <th>Offer</th>
                                <th>Hạn nộp</th>
                                <th>Địa chỉ</th>
                                <th>Trạng thái</th>
                                <th className='text-center'>Thao tác</th>
                            </tr>
                            </thead>
                            <tbody>
                            {recruitmentStore.listRecruitment.map((item: any, i: number) => {
                                let date: any = new Date()
                                let expiredDate: any = Moment.plusDays(item.expiredDate, 1)
                                return (
                                    <tr key={i}>
                                        <td>{sttPage(recruitmentStore.page, i)}</td>
                                        <td>{item.name}</td>
                                        <td>{item.companyName}</td>
                                        <td>{item.occupation?.name}</td>
                                        <td>{item.workExperience}</td>
                                        <td>{rangeWage(item)}</td>
                                        <td>{dateUtils.formatDate(item.expiredDate)}</td>
                                        <td>{item.address}</td>
                                        <td>{(expiredDate.getTime() > date.getTime()) ? <div className="badge badge-light-success">Đang tuyển</div> : <div className="badge badge-light-danger">Hết hạn</div>}</td>
                                        <td width='15%' className='text-center'>
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <Link to={`/update-recruitment/${slug(item.name)}/${item.id}?type=edit`}
                                                      title='Chỉnh sửa' className='btn btn-icon btn-warning btn-sm'><i
                                                    className='fas fa-pen'/></Link>
                                                <span className='p-2'/>
                                                <a href='#' title='Xóa' className='btn btn-icon btn-danger btn-sm'
                                                   data-bs-toggle='modal'
                                                   data-bs-target='#popup_confirm'
                                                   onClick={() => recruitmentStore.id = item.id}><i
                                                    className='fas fa-trash-alt'/></a>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table> : <NoContent/>}
                    <Paginate callback={handlePageClick} totalPage={recruitmentStore.totalPage}
                              currentPage={recruitmentStore.page}/>
                </div>}

                <PopupConfirm isLoading={recruitmentStore.isLoadingBt} action={recruitmentStore.delete}/>
            </div>
        </>
    )

}

export default observer(RecruitmentPage)