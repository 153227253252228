import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {occupationStore} from './OccupationStore'
import Loading from '../../../common/components/Loading'
import NoContent from '../../../common/components/NoContent'
import Paginate from '../../../common/components/Paginate'
import PopupConfirm from '../../../common/components/PopupConfirm'
import AddOrEditOccupation from './components/AddOrEditOccupation'
import {dateUtils} from "../../../common/utils/DateUtils";
import {sttPage} from "../../../common/utils/Utils";


const OccupationPage = () => {

    const handlePageClick = async (data: any) => {
        occupationStore.page = data.selected;
        await occupationStore.getOccupation();
    };

    useEffect(() => {
        occupationStore.getOccupation()
    }, [])

    return (
        <>
            <div className='d-flex align-items-center justify-content-between mb-6'>
                <h1 className='d-flex text-dark fw-bolder fs-3 align-items-center my-1'>Ngành nghề</h1>
                <button className='btn btn-sm btn-primary' data-bs-toggle="modal" data-bs-target="#add_edit_occupation"
                        onClick={() => occupationStore.clearForm()}>Tạo mới
                </button>
            </div>

            <div id="kt_ecommerce_sales_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">

                {occupationStore.isLoading ? <Loading/> : <div className='table-responsive'>
                    {occupationStore.listOccupation && occupationStore.listOccupation.length > 0 ?
                        <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
                            <thead>
                            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                <th>STT</th>
                                <th>Tên ngành nghề</th>
                                <th>Thời gian cập nhật</th>
                                <th className="text-center">Thao tác</th>
                            </tr>
                            </thead>
                            <tbody>
                            {occupationStore.listOccupation.map((item: any, i: number) => {
                                return (
                                    <tr key={i}>
                                        <td>{sttPage(occupationStore.page, i)}</td>
                                        <td>{item.name}</td>
                                        <td>{dateUtils.formatDate(item.updatedAt)}</td>
                                        <td width="15%" className="text-center">
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <button title="Chỉnh sửa" className="btn btn-icon btn-warning btn-sm"
                                                        data-bs-toggle="modal" data-bs-target="#add_edit_occupation"
                                                        onClick={() => occupationStore.getDetail(item.id)}><i
                                                    className="fas fa-pen"/></button>
                                                <span className='p-2'/>
                                                <button title='Xóa' className='btn btn-icon btn-danger btn-sm'
                                                        data-bs-toggle='modal'
                                                        data-bs-target='#popup_confirm'
                                                        onClick={() => occupationStore.id = item.id}><i
                                                    className='fas fa-trash-alt'/></button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table> : <NoContent/>}
                    <Paginate callback={handlePageClick} totalPage={occupationStore.totalPage}
                              currentPage={occupationStore.page}/>
                </div>}

                <AddOrEditOccupation/>
                <PopupConfirm isLoading={occupationStore.isLoadingBt} action={occupationStore.delete}/>
            </div>
        </>
    )

}

export default observer(OccupationPage);