import {categoryStore} from './CategoryStore'
import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from '../../common/helpers/RequestHelper'


class CategoryService {

  public getCate(): Promise<IApiResponse>{
    return getRequest(`/omfarm/v1/blog_category?page=${categoryStore.page}&size=10${categoryStore.keyword ? `&keyword=${categoryStore.keyword}` : ''}${categoryStore.searchLevel ? `&level=${categoryStore.searchLevel}` : ''}`)
  }

  public getCateParent(type?: string): Promise<IApiResponse>{
    return getRequest(`/omfarm/v1/blog_category?res=TREE${type ? `&type=${type}` : ''}`)
  }

  public getDetailCate(id: any): Promise<IApiResponse>{
    return getRequest(`/omfarm/v1/blog_category/${id}`)
  }

  public addCate(data: any): Promise<IApiResponse>{
    return postRequest(`/omfarm/v1/blog_category`, data)
  }

  public updateCate(id: any, data: any): Promise<IApiResponse>{
    return putRequest(`/omfarm/v1/blog_category/${id}`, data)
  }

  public deleteCate(id: any): Promise<IApiResponse>{
    return deleteRequest(`/omfarm/v1/blog_category?ids=${id}`, {})
  }

}

export const categoryService = new CategoryService()

