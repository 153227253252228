import React from 'react';
import {getRequest} from "../../helpers/RequestHelper";
import HttpStatusCode from "../../constants/HttpErrorCode";
import "./Style.scss"
import $ from 'jquery'
import {urlImage} from "../../utils/Utils";
import Loading from "../Loading";
import {chooseImgStore} from "./ChooseImgStore";
import {observer} from "mobx-react";
import uploadFileHelper from "../../helpers/UploadFileHelper";

interface IProps {
    id?: string,
    service: 'farmorg' | 'food' | 'farm' | 'life',
    changeImg: (e: any) => void,
}

const UploadChooseImg = ({id, service, changeImg}:IProps) => {
    let showMore = React.createRef<HTMLDivElement>();

    const handleOnScroll = (e: any) =>{
        if (showMore.current) {
            const self = e.currentTarget;
            if (($(self).height() as number + self.scrollTop) === self.scrollHeight) {
                chooseImgStore.page ++
                chooseImgStore.page < chooseImgStore.totalPage && readMore();
            }
        }
    }

    const readMore = async () =>{
        const result = await getRequest(`/v1/storage?domain=${service}&page=${chooseImgStore.page}&size=50`, true)
        if (result.status === HttpStatusCode.OK) {
            chooseImgStore.listImg = chooseImgStore.listImg.concat(result.body.data)
            chooseImgStore.totalPage = result.body.metadata.totalPage
        }
    }


    return (
        <div className='modal fade modal_update_choose_img' id={id ?? 'modal_upload_choose_img'} aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered mw-1000px'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h4>Tải ảnh / Chọn ảnh</h4>
                        <button type="button" id="close_upload_choose_img" onClick={() => chooseImgStore.active = 1} className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <ul className="nav nav-tabs" id="header_tab" role="tab_list">
                            <li className="nav-item position-relative" role="presentation" style={{minWidth: 120}}>
                                <button className={`nav-link w-100 ${chooseImgStore.active == 1 && 'active'}`} onClick={() => chooseImgStore.active = 1} id="tab_upload_img" data-bs-toggle="tab" data-bs-target="#tab_upload" aria-controls="tab_upload" type="button" role="tab">
                                    Tải ảnh lên
                                </button>
                            </li>
                            <li className="nav-item position-relative" role="presentation" style={{minWidth: 120}}>
                                <button className={`nav-link w-100 ${chooseImgStore.active == 2 && 'active'}`} onClick={async () => {
                                    chooseImgStore.active = 2
                                    await chooseImgStore.getListImg(service)
                                }} id="tab_choose_img" data-bs-toggle="tab" data-bs-target="#tab_choose" aria-controls="tab_choose" type="button" role="tab">
                                    Chọn ảnh
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content border pl-0 pr-0 border-top-0" style={{padding: `16px`}}>
                            <div className={`tab-pane fade ${chooseImgStore.active == 1 && 'active show'}`} id="tab_upload" role="tabpanel" aria-labelledby="tab_upload_img">
                                <div className="d-flex justify-content-center align-items-center">
                                    <button className="btn upload_image pl-0 pt-5" disabled={chooseImgStore.isLoadingBt} >
                                        <div className="w-100 justify-content-center">
                                            <img src="/assets/images/upload-cloud.svg" alt=""/>
                                            <p className="mt-4">{chooseImgStore.isLoadingBt ? 'Loading ...' : 'Tải ảnh lên'}</p>
                                        </div>
                                        <input accept="image/jpeg, image/jpg, image/png, image/svg" type="file"
                                               onChange={async (e: any) => await uploadFileHelper(e, changeImg)} placeholder="Tải ảnh lên"/>
                                    </button>
                                </div>
                            </div>
                            <div className={`tab-pane fade ${chooseImgStore.active == 2 && 'active show'}`} id="tab_choose" role="tabpanel" aria-labelledby="tab_choose_img">
                                {chooseImgStore.isLoading ? <Loading/> : <div className="listImg d-flex flex-wrap" ref={showMore} onScroll={handleOnScroll}>
                                    {chooseImgStore.listImg && chooseImgStore.listImg.length > 0 ? chooseImgStore.listImg.map((item, i) => {
                                        return <div key={i} className="images"><img onClick={() => {changeImg(item.url); chooseImgStore.active = 1}} data-bs-dismiss="modal" src={urlImage(item.url)} alt=""/></div>
                                    }) : <div className="w-100 d-flex align-items-center justify-content-center" style={{height: 250}}>Chưa có dữ liệu</div>}
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default observer(UploadChooseImg);