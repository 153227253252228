import React, {useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import {shopStore} from '../ShopStore'
import AddFactory from './AddFactory'
import NoContent from "../../../../common/components/NoContent";
import UploadMultiImg from "../../../../common/components/UploadMultiImg";
import UploadImg from "../../../../common/components/UploadImg";
import {getIDParameter} from "../../../../common/utils/Utils";
import {recruitmentStore} from "../../../recruitment/RecruitmentStore";
import {Link, Navigate} from "react-router-dom";
import Input from "../../../../common/components/form/Input";
import Select from "../../../../common/components/form/Select";
import ChooseUser from "./ChooseUser";
import {numberUtil} from "../../../../common/utils/NumberUtil";

const EditShop = observer(() => {

    let id = getIDParameter(4)

    useEffect(() => {
        shopStore.getShopDetail(id).then()
        recruitmentStore.getProvinces().then()
        return () => {
            shopStore.redirect = false
            shopStore.clearError()
        }
    }, [])

    if (shopStore.redirect) {
        return <Navigate to={'/store/shops'}/>
    } else return (
        <div className='edit_shop'>
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 mb-6 pt-1">
                <li className="breadcrumb-item">
                    <Link to="/store/shops" className="text-primary">Cửa hàng</Link>
                </li>
                <li className="breadcrumb-item">
                    <span className="bullet bg-gray-400 w-5px h-2px"/>
                </li>

                <li className="breadcrumb-item text-muted">
                    {shopStore.dataRequest.name}
                </li>
            </ul>
            <div className='d-flex align-items-center justify-content-between mb-8'>
                <h1 className='d-flex text-dark fw-bolder fs-3 align-items-center my-1'>Cập nhập cửa hàng</h1>
            </div>

            <div className='form'>
                <div className='row'>
                    <div className='col-6'>
                        <div className="mb-5">
                            <label className="form-label">Ảnh <span className="text-danger">*</span></label>
                            <UploadImg src={shopStore.dataRequest.avatar} changeImg={shopStore.changeAvatar}/>
                            {shopStore.errors.avatar && <p className="error">{shopStore.errors.avatar}</p>}
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Tên cửa hàng <span className="text-danger">*</span></label>
                            <Input isError={shopStore.errors.name} value={shopStore.dataRequest.name} onChange={(e: any) => shopStore.dataRequest.name = e.currentTarget.value} placeholder="Nhập tên cửa hàng"/>
                        </div>
                        <div className="mb-5">
                            <label className="form-label">CMND/CCCD <span className="text-danger">*</span></label>
                            <Input isError={shopStore.errors.identityCard} value={shopStore.dataRequest.identityCard} onChange={(e: any) => shopStore.dataRequest.identityCard = numberUtil.regexNumber(e)} placeholder="Nhập CMND/CCCD"/>
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Kiểu người bán <span className="text-danger">*</span></label>
                            <Select isError={shopStore.errors.shopType} options={shopStore.listType} value={shopStore.dataRequest.shopType} onChange={(e: any) => shopStore.dataRequest.shopType = e.currentTarget.value}/>
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Quận/Huyện <span className="text-danger">*</span></label>
                            <Select options={recruitmentStore.listDistrict} isError={shopStore.errors.district} value={shopStore.dataRequest.district.id} onChange={(e: any) => shopStore.changeDistrict(e.currentTarget.value)}/>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className="mb-5">
                            <label className="form-label">Ảnh bìa <span className="text-danger">*</span></label>
                            <UploadImg src={shopStore.dataRequest.banner} changeImg={shopStore.changeBanner}/>
                            {shopStore.errors.banner && <p className="error">{shopStore.errors.banner}</p>}
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Chủ cửa hàng <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${(shopStore.errors.userId && !shopStore.dataRequest.user.id) ? 'border_error' : ''}`} value={shopStore.dataRequest.user.name ?? shopStore.dataRequest.user.phone} disabled={true}/>
                            {shopStore.errors.userId && <p className="error">{shopStore.errors.userId}</p>}
                            <ChooseUser/>
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Mã số thuế <span className="text-danger">*</span></label>
                            <Input isError={shopStore.errors.taxNumber} value={shopStore.dataRequest.taxNumber} onChange={(e: any) => shopStore.dataRequest.taxNumber = numberUtil.regexNumber(e)} placeholder="Nhập mã số thuế"/>
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Tỉnh/Thành phố <span className="text-danger">*</span></label>
                            <Select options={recruitmentStore.listProvince} isError={shopStore.errors.province} value={shopStore.dataRequest.province.id} onChange={(e: any) => shopStore.changeProvince(e.currentTarget.value)}/>
                        </div>
                        <div className="">
                            <label className="form-label">Phường/Xã <span className="text-danger">*</span></label>
                            <Select isError={shopStore.errors.ward} options={recruitmentStore.listWard} value={shopStore.dataRequest.ward.id} onChange={(e: any) => shopStore.dataRequest.ward.id = e.currentTarget.value}/>
                        </div>
                    </div>
                    <div className="mb-5">
                        <label className="form-label">Địa chỉ cụ thể <span className="text-danger">*</span></label>
                        <Input isError={shopStore.errors.address} value={shopStore.dataRequest.address} onChange={(e: any) => shopStore.dataRequest.address = e.currentTarget.value} placeholder="Nhập địa chỉ cụ thể"/>
                    </div>
                </div>
                <div className="w-100 pb-4 border-bottom mt-8 mb-8">
                    <strong><h4>Thông tin đơn vị</h4></strong>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <div className="mb-5">
                            <label className="form-label">Tên đại diện</label>
                            <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.unitInformation.representer} onChange={(e: any) => shopStore.dataRequest.shopCompany.unitInformation.representer = e.currentTarget.value} placeholder="Nhập tên đại diện"/>
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Số điện thoại</label>
                            <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.unitInformation.phone} onChange={(e: any) => shopStore.dataRequest.shopCompany.unitInformation.phone = numberUtil.regexNumber(e)} placeholder="Nhập số điện thoại"/>
                        </div>

                        <div className="mb-5">
                            <label className="form-label">Email</label>
                            <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.unitInformation.email} onChange={(e: any) => shopStore.dataRequest.shopCompany.unitInformation.email = e.currentTarget.value} placeholder="Nhập email"/>
                        </div>

                        <div className="mb-5">
                            <label className="form-label">Địa chỉ</label>
                            <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.unitInformation.address} onChange={(e: any) => shopStore.dataRequest.shopCompany.unitInformation.address = e.currentTarget.value} placeholder="Nhập địa chỉ"/>
                        </div>

                        <div className="mb-5">
                            <label className="form-label">Trang web</label>
                            <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.unitInformation.website} onChange={(e: any) => shopStore.dataRequest.shopCompany.unitInformation.website = e.currentTarget.value} placeholder="Nhập link trang web"/>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className="mb-5">
                            <label className="form-label">Loại hình</label>
                            <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.unitInformation.business} onChange={(e: any) => shopStore.dataRequest.shopCompany.unitInformation.business = e.currentTarget.value} placeholder="Nhập loại hình"/>
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Sản phẩm chính</label>
                            <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.unitInformation.mainProduct} onChange={(e: any) => shopStore.dataRequest.shopCompany.unitInformation.mainProduct = e.currentTarget.value} placeholder="Nhập sản phẩm chính"/>
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Tổng doanh thu</label>
                            <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.unitInformation.revenue} onChange={(e: any) => shopStore.dataRequest.shopCompany.unitInformation.revenue = e.currentTarget.value} placeholder="Nhập tổng doanh thu"/>
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Tổng nhân viên</label>
                            <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.unitInformation.numberOfEmployees} onChange={(e: any) => shopStore.dataRequest.shopCompany.unitInformation.numberOfEmployees = e.currentTarget.value} placeholder="Nhập tổng nhân viên"/>
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Năm thành lập</label>
                            <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.unitInformation.foundedYear} onChange={(e: any) => shopStore.dataRequest.shopCompany.unitInformation.foundedYear = e.currentTarget.value} placeholder="Nhập năm thành lập"/>
                        </div>
                    </div>
                    <div className="mb-5">
                        <label className="form-label">Thông tin cơ bản</label>
                        <textarea className="form-control" rows={4} value={shopStore.dataRequest.shopCompany.description} onChange={(e: any) => shopStore.dataRequest.shopCompany.description = e.currentTarget.value} placeholder="Nhập thông tin cơ bản"/>
                    </div>
                </div>
                <div className="w-100 pb-4 border-bottom mt-8 mb-8">
                    <strong><h4>Năng lực công ty</h4></strong>
                </div>
                <div className="row mb-5">
                    <div className="col-6">
                        <div className="mb-5">
                            <label className="form-label">Quy mô</label>
                            <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.productionCapacity.factoryArea} onChange={(e: any) => shopStore.dataRequest.shopCompany.productionCapacity.factoryArea = e.currentTarget.value} placeholder="Nhập kích thước nhà máy"/>
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Công nghệ sử dụng</label>
                            <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.productionCapacity.technology} onChange={(e: any) => shopStore.dataRequest.shopCompany.productionCapacity.technology = e.currentTarget.value} placeholder="Nhập công nghệ sử dụng"/>
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Giấy chứng nhận</label>
                            <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.productionCapacity.certificate} onChange={(e: any) => shopStore.dataRequest.shopCompany.productionCapacity.certificate = e.currentTarget.value} placeholder="Nhập giấy chứng nhận"/>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="mb-5">
                            <label className="form-label">Sản lượng hằng năm</label>
                            <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.productionCapacity.annualOutput} onChange={(e: any) => shopStore.dataRequest.shopCompany.productionCapacity.annualOutput = e.currentTarget.value} placeholder="Nhập sản lượng hằng năm"/>
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Đối tác</label>
                            <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.productionCapacity.partner} onChange={(e: any) => shopStore.dataRequest.shopCompany.productionCapacity.partner = e.currentTarget.value} placeholder="Nhập đối tác"/>
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Thị trường phân phối</label>
                            <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.productionCapacity.distributionMarket} onChange={(e: any) => shopStore.dataRequest.shopCompany.productionCapacity.distributionMarket = e.currentTarget.value} placeholder="Nhập thị trường phân phối"/>
                        </div>
                    </div>
                </div>
                <div className="w-100 pb-4 border-bottom mt-4 mb-8">
                    <strong><h4>Thương hiệu</h4></strong>
                </div>
                <div className="mb-5">
                    <label className="form-label">Tầm nhìn</label>
                    <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.brand.vision} onChange={(e: any) => shopStore.dataRequest.shopCompany.brand.vision = e.currentTarget.value} placeholder="Nhập tầm nhìn"/>
                </div>
                <div className="mb-5">
                    <label className="form-label">Sứ mệnh</label>
                    <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.brand.mission} onChange={(e: any) => shopStore.dataRequest.shopCompany.brand.mission = e.currentTarget.value} placeholder="Nhập sứ mệnh"/>
                </div>
                <div className="mb-5">
                    <label className="form-label">Về chúng tôi</label>
                    <textarea className="form-control" rows={4} value={shopStore.dataRequest.shopCompany.brand.aboutUs} onChange={(e: any) => shopStore.dataRequest.shopCompany.brand.aboutUs = e.currentTarget.value} placeholder="Nhập về chúng tôi"/>
                </div>
                <div className="w-100 pb-4 border-bottom mt-8 mb-8">
                    <strong><h4>Liên hệ</h4></strong>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <div className="mb-5">
                            <label className="form-label">Tên đại diện</label>
                            <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.contact.representer} onChange={(e: any) => shopStore.dataRequest.shopCompany.contact.representer = e.currentTarget.value} placeholder="Nhập tên đại diện"/>
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Số điện thoại</label>
                            <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.contact.phone} onChange={(e: any) => shopStore.dataRequest.shopCompany.contact.phone = numberUtil.regexNumber(e)} placeholder="Nhập số điện thoại"/>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className="mb-5">
                            <label className="form-label">Email</label>
                            <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.contact.email} onChange={(e: any) => shopStore.dataRequest.shopCompany.contact.email = e.currentTarget.value} placeholder="Nhập email"/>
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Địa chỉ</label>
                            <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.contact.address} onChange={(e: any) => shopStore.dataRequest.shopCompany.contact.address = e.currentTarget.value} placeholder="Nhập địa chỉ"/>
                        </div>
                    </div>
                </div>
                <div className="mb-5">
                    <label className="form-label">Trang web</label>
                    <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.contact.website} onChange={(e: any) => shopStore.dataRequest.shopCompany.contact.website = e.currentTarget.value} placeholder="Nhập link trang web"/>
                </div>
                <div className="w-100 pb-4 border-bottom mt-8 mb-8">
                    <strong><h4>Giấy chứng nhận</h4></strong>
                </div>
                <UploadMultiImg listImg={shopStore.dataRequest.shopCompany.certification} changeImg={shopStore.uploadImgCertification} removeImg={shopStore.removeImgCertification}/>
                <div className="w-100 pb-4 border-bottom mt-8 mb-8">
                    <strong><h4>Cơ sở sản xuất</h4></strong>
                </div>
                <div className='table-responsive'>
                    {shopStore.dataRequest.shopCompany.factory && shopStore.dataRequest.shopCompany.factory.length > 0 ?
                        <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
                            <thead>
                            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                <th>Ảnh</th>
                                <th>Tên cơ sở</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {shopStore.dataRequest.shopCompany.factory.map((item: any, i: number) => {
                                return (
                                    <tr key={i}>
                                        <td>
                                            <div className="symbol symbol-30px symbol-md-40px">
                                                <img src={item.image} alt=""/>
                                            </div>
                                        </td>
                                        <td>{item.name}</td>
                                        <td width="10%">
                                            <button className="btn btn-icon btn-danger btn-sm" onClick={() => shopStore.dataRequest.shopCompany.factory.splice(i, 1)}><i className="fas fa-trash-alt"/></button>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table> : <NoContent/>}
                </div>
                <button className="btn btn-md btn-primary mt-5 mb-5" onClick={() => shopStore.clearFormFactory()} data-bs-toggle='modal' data-bs-target='#addFactory'>+ Add</button>
                <AddFactory/>
            </div>

            <div className='d-flex pt-5 align-items-center justify-content-end'>
                <button type='submit' className='btn btn-primary' onClick={() => shopStore.add_update('edit')}
                        disabled={shopStore.isLoadingBt}>
                    {shopStore.isLoadingBt ? (
                        <span className='indicator-progress' style={{display: 'block'}}>
                  Vui lòng chờ...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'/>
                </span>
                    ) : 'Lưu'}
                </button>
            </div>
        </div>
    )
})

export default EditShop