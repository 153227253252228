import React from 'react'
type Props = {
  className?: string
  path: string
  svgClassName?: string
}

const KTSVG: React.FC<Props> = ({className = '', path, svgClassName = 'mh-50px'}) => {

  return (
    <span className={`svg-icon ${className}`}>
      <img src={path} alt='' />
    </span>
  )
}

export {KTSVG}
