import {observer} from 'mobx-react'
import {occupationStore} from '../OccupationStore'
import React from 'react'


const AddOrEditOccupation = () => {

  // const changeImg = (value: any) => {
  //   occupationStore.dataRequest.image = value
  // }


  return (
    <div className="modal fade" id="add_edit_occupation" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{occupationStore.typeModal == 'edit' ? 'Cập nhật' : 'Tạo'} ngành nghề</h5>
            <button type="button" id="close_add_edit_occupation" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
          </div>
          <div className="modal-body">
            {/*<div className='mb-4'>*/}
            {/*  <label className='form-label w-100'>Ảnh</label>*/}
            {/*  <div className='upLoadImage d-flex w-100'>*/}
            {/*    {occupationStore.dataRequest.image ?*/}
            {/*      <img src={urlImage(occupationStore.dataRequest.image)} alt='' /> :*/}
            {/*      <div className='no_img d-flex align-items-center justify-content-center'><i*/}
            {/*        className='far fa-image' /></div>}*/}
            {/*    <button className='btn change-avatar pl-0 pt-5'><span>Upload</span>*/}
            {/*      <input accept='image/jpeg, image/jpg, image/png, image/svg' type='file'*/}
            {/*             onChange={async (e: any) => await uploadImgHelper.onChangeFileThumb(e, changeImg)} />*/}
            {/*    </button>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="mb-4">
              <label className="form-label">Tên ngành nghề <span className="text-danger">*</span></label>
              <input type="text" value={occupationStore.dataRequest.name} onChange={(e: any) => occupationStore.dataRequest.name = e.currentTarget.value} className="form-control" placeholder="Nhập tên ngành nghề"/>
            </div>

          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Hủy</button>
            <button type="button" className="btn btn-primary" onClick={() => occupationStore.addOrEdit()} disabled={occupationStore.isLoadingBt}>Hoàn thành</button>
          </div>
        </div>
      </div>
    </div>
  )

}

export default observer(AddOrEditOccupation)