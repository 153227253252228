import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import Loading from '../../common/components/Loading'
import NoContent from '../../common/components/NoContent'
import Paginate from '../../common/components/Paginate'
import {observer} from 'mobx-react'
import {configStore} from './ConfigStore'
import {dateUtils} from "../../common/utils/DateUtils";


const ConfigPage = () => {

    const handlePageClick = async (data: any) => {
        configStore.page = data.selected;
        await configStore.getConfig();
    };

    const switchName = (type: any) => {
        switch (type) {
            case 'PAGE_HOME':
                return "Cấu hình trang chủ"
            case 'TERM_POLICY':
                return "Chính sách bảo mật"
            case 'COMPLAINTS':
                return "Quy trình giải quyết kiếu nại"
            case 'GENERAL_RULES':
                return "Quy định chung"
            case 'SUGGESTIONS_COMPLAINTS':
                return "Góp ý kiếu nại"
            case 'TECHNICAL_ASSISTANCE':
                return "Hỗ trợ kỹ thuật"
            case 'USER_MANUAL':
                return "Tài liệu hướng dẫn sử dụng"
            case 'WORKING_PROCESS':
                return "Quy trình làm việc"
        }
    }

    useEffect(() => {
        configStore.getConfig().then()
    }, [])

    return (
        <>
            <div className='d-flex align-items-center justify-content-between mb-6'>
                <h1 className='d-flex text-dark fw-bolder fs-3 align-items-center my-1'>Cấu hình</h1>
                <Link to={'/add-config?type=add'} onClick={() => configStore.clearForm()}><button className='btn btn-sm btn-primary'>Tạo mới</button></Link>
            </div>

            <div id="kt_ecommerce_sales_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                {configStore.isLoading ? <Loading/> : <div className='table-responsive'>
                    {configStore.listConfig && configStore.listConfig.length > 0 ?
                        <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
                            <thead>
                            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                <th>STT</th>
                                <th>Tên</th>
                                <th>Thời gian cập nhật</th>
                                <th className="text-center">Thao tác</th>
                            </tr>
                            </thead>
                            <tbody>
                            {configStore.listConfig.map((item: any, i: number) => {
                                return (
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{switchName(item.id)}</td>
                                        <td>{dateUtils.formatDate(item.updatedAt)}</td>
                                        <td width="15%" className="text-center">
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <Link to={`/update-config/${item.id}?type=edit`} title="Chỉnh sửa"
                                                      className="btn btn-icon btn-warning btn-sm"><i
                                                    className="fas fa-pen"/></Link>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table> : <NoContent/>}
                    <Paginate callback={handlePageClick} totalPage={configStore.totalPage}
                              currentPage={configStore.page}/>
                </div>}
            </div>
        </>
    )
}

export default observer(ConfigPage)