import React from 'react'
import {observer} from 'mobx-react'
import uploadFileHelper from "../helpers/UploadFileHelper";
import {urlImage} from "../utils/Utils";

interface IProps {
    src: string,
    changeImg: any
}

const UploadImg = ({src, changeImg}: IProps) => {

    return  <div className='upLoadImage w-100 d-flex'>
        {src ? <img src={urlImage(src)} alt='' /> :
            <div className='no_img d-flex align-items-center justify-content-center'><i className='far fa-image' /></div>}
        <button className='btn change-avatar'><span>Upload</span>
            <input accept='image/jpeg, image/jpg, image/png, image/*' type='file'
                   onChange={async (e: any) => await uploadFileHelper(e, changeImg)} />
        </button>
    </div>
}

export default observer(UploadImg)