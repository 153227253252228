import React, {useEffect} from "react"
import {observer} from "mobx-react"
import {getIDParameter, getUrlParameter, urlImage} from "../../../common/utils/Utils"
import TextEditor from "../../../common/components/TextEditor"
import Loading from "../../../common/components/Loading"
import {TreeSelect} from "antd"
import {Link, Navigate} from "react-router-dom"
import {categoryStore} from "../../categories/CategoryStore"
import {blogStore} from "../BlogStore"
import UploadChooseImg from "../../../common/components/modalChooseImg/UploadChooseImg";
import {chooseImgStore} from "../../../common/components/modalChooseImg/ChooseImgStore";

const AddEditBlog = () => {

  const changeImg = (value: any) => {
    blogStore.dataRequest.introduce.image = value
  }

  const onChange = (newValue: any) => {
    blogStore.dataRequest.blogCategory.id = newValue
  }

  useEffect(() => {
    let id = getIDParameter()
    blogStore.type = getUrlParameter("type") ?? "add"
    categoryStore.getCateParent().then()
    if (id) {
      blogStore.getBlogDetail(id).then()
    }
     chooseImgStore.getListImg('farmorg').then()
    return (() => {
      blogStore.redirect = false
    })
  }, [])


  if (blogStore.redirect) {
    return <Navigate to={"/blog"} />
  } else return (
    <div id="kt_ecommerce_sales_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
      <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 mb-6 pt-1">
        <li className="breadcrumb-item">
          <Link to="/blog" className="text-primary">Blog</Link>
        </li>
        <li className="breadcrumb-item">
          <span className="bullet bg-gray-400 w-5px h-2px" />
        </li>

        <li className="breadcrumb-item text-muted">
          {blogStore.type == "add" ? "Tạo blog" : blogStore.dataRequest.title}
        </li>
      </ul>

      <div className="d-flex align-items-center justify-content-between mb-8">
        <h1
          className="d-flex text-dark fw-bolder fs-3 align-items-center my-1">{blogStore.type == "add" ? "Tạo" : "Cập nhật"} blog</h1>
      </div>

      <UploadChooseImg service="farmorg" changeImg={changeImg}/>

      {blogStore.isLoadingDetail ? <Loading /> : <div className="form">

        <div className="border_bottom pb-8">
          <a data-bs-toggle="collapse" className="d-flex justify-content-between align-items-center"
             href="#content_main">
            <h4 className="font-bold mb-0">Content</h4>
            <div className="line" />
            <i className="fas fa-chevron-down" style={{fontSize: 18}} />
          </a>
          <div className="collapse show mt-8" id="content_main" style={{padding: `0 24px`}}>
            <div className="mb-5">
              <label className="form-label w-100">Hình minh họa (780x390) <span className="text-danger">*</span></label>
              <div className="upLoadImageCategory w-100">
                {blogStore.dataRequest.introduce.image ?
                  <img src={urlImage(blogStore.dataRequest.introduce.image)} alt="" /> :
                  <div className="no_img d-flex align-items-center justify-content-center"><i
                    className="far fa-image" /></div>}
                <button className="btn change-avatar pl-0 pt-5" data-bs-toggle='modal' data-bs-target='#modal_upload_choose_img'>
                  Upload
                </button>
              </div>
            </div>
            <div className="mb-5">
              <label className="form-label">Tiêu đề <span className="text-danger">*</span></label>
              <input type="text" className="form-control" value={blogStore.dataRequest.title}
                     onChange={(e: any) => blogStore.dataRequest.title = e.currentTarget.value}
                     placeholder="Nhập tiêu đề" />
            </div>
          <div className="mb-5">
              <label className="form-label">Danh mục <span className="text-danger">*</span></label>
              <TreeSelect
                style={{width: "100%"}}
                value={blogStore.dataRequest.blogCategory.id}
                dropdownStyle={{maxHeight: 400, overflow: "auto"}}
                treeData={categoryStore.listCateParent}
                placeholder="Chọn danh mục"
                treeDefaultExpandAll
                onChange={onChange}
              />
            </div>
            <div className="mb-5">
              <label className="form-label">Nội dung</label>
              <TextEditor isChooseImg={true} height={300} content={blogStore.dataRequest.introduce?.special}
                          onChange={(e: any) => blogStore.dataRequest.introduce.special = e} />
            </div>
          </div>
        </div>

        <div className="mt-8">
          <a data-bs-toggle="collapse" className="d-flex justify-content-between align-items-center" href="#operation">
            <h4 className="font-bold mb-0">Đối tượng</h4>
            <div className="line" />
            <i className="fas fa-chevron-down" style={{fontSize: 18}} />
          </a>
          <div className="collapse mt-8" style={{padding: `0 24px`}} id="operation">
            <div className="mb-5">
              <label className="form-label">Tiêu đề</label>
              <input type="text" className="form-control" value={blogStore.dataRequest.operation.title}
                     onChange={(e: any) => blogStore.dataRequest.operation.title = e.currentTarget.value}
                     placeholder="Nhập tiêu đề" />
            </div>
            <div className="d-flex justify-content-end mb-4">
              <button className="btn btn-sm btn-primary" onClick={() => blogStore.dataRequest.operation.item.push(
                { title1: "", title2: "", description: "", action: [{title: "", link: ""}]}
              )}>Thêm đối tượng</button>
            </div>
            {blogStore.dataRequest.operation.item && blogStore.dataRequest.operation.item.map((item: any, index: number) => {
              return (
                <div className="d-flex mb-8 justify-content-between symbol bg-light p-4" key={index}>
                  <div style={{width: `96%`}}>
                    <div className="mb-5">
                      <label className="form-label">Tiêu đề 1</label>
                      <input type="text" className="form-control" value={item.title1}
                             onChange={(e: any) => item.title1 = e.currentTarget.value} placeholder="Nhập tiêu đề 1" />
                    </div>
                    <div className="mb-5">
                      <label className="form-label">Tiêu đề 2</label>
                      <input type="text" className="form-control" value={item.title2}
                             onChange={(e: any) => item.title2 = e.currentTarget.value} placeholder="Nhập tiêu đề 2" />
                    </div>
                    <div className="mb-5">
                      <label className="form-label">Mô tả</label>
                      <input type="text" className="form-control" value={item.description}
                             onChange={(e: any) => item.description = e.currentTarget.value} placeholder="Nhập mô tả" />
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <h5 className="font-bold mb-4">Nút bấm</h5>
                      <button className="btn btn-sm btn-primary"
                              onClick={() => item.action.push({title: "", link: ""})}>+
                        Thêm nút bấm
                      </button>
                    </div>
                    {item.action && item.action.map((val: any, i: number) => {
                      return (
                        <div className="d-flex align-items-center justify-content-between mb-4" key={i}>
                          <div className="w-48">
                            <label className="form-label">Tiêu đề</label>
                            <input type="text" className="form-control" value={val.title}
                                   onChange={(e: any) => val.title = e.currentTarget.value}
                                   placeholder="Nhập tiêu đề" />
                          </div>
                          <div className="w-48">
                            <label className="form-label">Link</label>
                            <input type="text" className="form-control" value={val.link}
                                   onChange={(e: any) => val.link = e.currentTarget.value} placeholder="Nhập link" />
                          </div>
                          <i className="fas text-danger fa-trash-alt cursor-pointer"
                             style={{fontSize: 20, marginTop: 28}}
                             onClick={() => item.action.splice(i, 1)} />
                        </div>
                      )
                    })}
                  </div>
                  <i className="fas text-danger fa-trash-alt cursor-pointer"
                     style={{fontSize: 20, marginTop: 28, marginRight: 10}}
                     onClick={() => blogStore.dataRequest.operation.item.splice(index, 1)} />
                </div>
              )
            })}
          </div>
        </div>

      </div>}
      <div className="action d-flex justify-content-end mt-10">
        <button type="submit" className="btn btn-primary" onClick={() => blogStore.addOrEdit()}
                disabled={blogStore.isLoadingBt}>
          {blogStore.isLoadingBt ? (
            <span className="indicator-progress" style={{display: "block"}}>
                  Vui lòng chờ...{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
                </span>
          ) : "Hoàn thành"}
        </button>
      </div>

    </div>
  )
}

export default observer(AddEditBlog)
