import React from 'react';
import {observer} from "mobx-react";
import {shopStore} from "../ShopStore";
import $ from 'jquery'
import NoContent from "../../../../common/components/NoContent";
import "./ChooseUserStyle.scss"
import {getRequest} from "../../../../common/helpers/RequestHelper";
import HttpStatusCode from "../../../../common/constants/HttpErrorCode";
import Loading from "../../../../common/components/Loading";

const ChooseUser = () => {
    let showMore = React.createRef<HTMLDivElement>();

    const handleOnScroll = (e: any) =>{
        if (showMore.current) {
            const self = e.currentTarget;
            if (($(self).height() as number + self.scrollTop) === self.scrollHeight) {
                shopStore.metadata.page ++
                shopStore.metadata.page < shopStore.metadata.totalPage && readMore();
            }
        }
    }

    const readMore = async () =>{
        const result = await getRequest(`/v1/users?page=${shopStore.metadata.page}&size=50${shopStore.keyword ? `&keyword=${shopStore.keyword}` : ''}`)
        if (result.status === HttpStatusCode.OK) {
            shopStore.listUser = shopStore.listUser.concat(result.body.data)
            shopStore.metadata = result.body.metadata
        }
    }

    const chooseUser = (item: any) => {
        shopStore.dataRequest.user = item
        $('#close_choose_user').trigger('click')
    }

    return (
        <div className='modal fade' id='choose_user' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered mw-900px'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h2>Chọn tài khoản</h2>
                        <button type="button" id="close_choose_user" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"/>
                    </div>
                    <div className='modal-body py-lg-10 px-lg-10'>
                        <div className="d-flex align-items-center">
                            <div className='d-flex align-items-center position-relative'>
                                <i className='bi bi-search cursor-pointer fs-1x fs-3 position-absolute ms-4'
                                   onClick={async () => {
                                       shopStore.metadata.page = 0;
                                       await shopStore.getUsers()
                                   }}/>
                                <input type='text' value={shopStore.keyword}
                                       onChange={(e: any) => shopStore.keyword = e.currentTarget.value}
                                       onKeyDown={async (e: any) => {
                                           if (e.key === 'Enter') {
                                               shopStore.metadata.page = 0;
                                               await shopStore.getUsers()
                                           }
                                       }} data-kt-ecommerce-order-filter='search'
                                       className='form-control form-control-solid w-350px ps-12'
                                       placeholder='Tìm kiếm họ và tên, sdt, email'/>
                            </div>
                        </div>
                        <div className="d-flex p-2 mt-4 border-bottom align-items-center">
                            <h4 className="name w-30 fw-semibold">Họ và tên</h4>
                            <h4 className="phone w-30 fw-semibold">Số điện thoại</h4>
                            <h4 className="email w-30 fw-semibold">Email</h4>
                        </div>
                        {shopStore.isLoading ? <Loading/> : <div className="list_user" ref={showMore} onScroll={handleOnScroll}>
                            {shopStore.listUser && shopStore.listUser.length > 0 ? shopStore.listUser.map((item, i) => {
                                return (
                                    <div key={i} className="user p-2 border-bottom mb-2 d-flex align-items-center justify-content-between">
                                        <div className="name w-30">{item.name}</div>
                                        <div className="phone w-30">{item.phone}</div>
                                        <div className="email w-30">{item.email}</div>
                                        {item.id !== shopStore.dataRequest.user.id && <button className="btn btn-sm btn-outline-primary"
                                                onClick={() => chooseUser(item)}>Chọn
                                        </button>}
                                    </div>
                                )
                            }) : <NoContent/>}
                        </div>}
                    </div>
                    <div className='modal-footer d-flex justify-content-end py-6 px-9'>
                        <button type='reset' className='btn btn-light btn-active-light-primary me-2'
                                data-bs-dismiss='modal'>Hủy
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default observer(ChooseUser);