import {IApiResponse, getRequest, putRequest} from '../../../common/helpers/RequestHelper'
import {contactStore} from './ContactStore'

class ContactService {

  public getContact(): Promise<IApiResponse>{
    return getRequest(`/omfarm/v1/contact?page=${contactStore.page}&size=10${contactStore.status ? `&status=${contactStore.status}` : ''}`)
  }

  public changeStatus(data: any): Promise<IApiResponse>{
    return putRequest(`/omfarm/v1/contact/change_status`, data)
  }

}

export const contactService = new ContactService()