import {makeAutoObservable} from "mobx";
import $ from "jquery";
import {productService} from "./ProductService";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {checkWebsite} from "../../../common/utils/Utils";

class ProductStore {
    id: any = "";
    shopId: any = "";
    filter_shopId: any = "";
    state: any = "";
    keyword: any = "";
    categoryId: any = "";
    page: any = 0;
    listState: string[] = ["PUBLIC", "DRAFT", "PENDING", "REJECT", "DELETED", "REPORTED"];
    totalPage: any = 0;
    listUnit: any[] = [];
    listProduct: any[] = [];
    dataRequest: any = {
        name: "",
        shopId: "",
        categoryId: "",
        price: "",
        salePrice: "",
        quantity: "",
        unitId: "",
        transportFees: [
            {
                fromWeight: '',
                toWeight: '',
                priceUrban: '',
                priceSuburban: ''
            }
        ],
        originSourceLink: "",
        shopCategoryId: "",
        description: "",
        packingSize: ["", "", ""],
        weight: "",
        state: "",
        videos: [],
        sku: "",
        media: [],
        wholeSales: [],
        variants: []
    };
    errors: any = {
        name: "",
        shopId: "",
        categoryId: "",
        price: "",
        salePrice: "",
        quantity: "",
        transportFees: '',
        originSourceLink: '',
        unitId: "",
        description: "",
        validPackingSize: "",
        isValidPrice: '',
        weight: "",
        media: '',
        variants: ''
    };
    redirect: boolean = false;
    isLoading: boolean = false;
    isLoadingBt: boolean = false;
    isLoadingDetail: boolean = false;


    constructor() {
        makeAutoObservable(this);
    }

    addTransportFees(){
        this.dataRequest.transportFees.push({
            fromWeight: '',
            toWeight: '',
            priceUrban: '',
            priceSuburban: ''
        })
    }

    clearError() {
        this.errors = {
            name: "",
            shopId: "",
            categoryId: "",
            price: "",
            salePrice: "",
            transportFees: '',
            quantity: "",
            originSourceLink: '',
            unitId: "",
            description: "",
            validPackingSize: "",
            isValidPrice: "",
            weight: "",
            media: '',
            variants: ''
        };

    }

    clearForm() {
        this.dataRequest = {
            name: "",
            shopId: "",
            categoryId: "",
            price: "",
            salePrice: "",
            quantity: "",
            unitId: "",
            originSourceLink: "",
            shopCategoryId: "",
            description: "",
            packingSize: ["", "", ""],
            weight: "",
            state: "",
            videos: [],
            transportFees: [
                {
                    fromWeight: '',
                    toWeight: '',
                    priceUrban: '',
                    priceSuburban: ''
                }
            ],
            sku: "",
            media: [],
            wholeSales: [],
            variants: []
        };

        this.clearError()
    }


    async getProducts() {
        this.isLoading = true;
        const result = await productService.getProducts();
        this.isLoading = false;

        if (result.status === HttpStatusCode.OK) {
            this.listProduct = result.body.data;
            this.totalPage = result.body.metadata.totalPage;
        }

    }

    async getProductDetail(id: any) {
        this.clearError()
        this.isLoadingDetail = true
        const result = await productService.getDetailProduct(id);
        this.isLoadingDetail = false
        if (result.status === HttpStatusCode.OK) {
            this.dataRequest = result.body;
            if(!this.dataRequest.transportFees){
                this.dataRequest.transportFees = [
                    {
                        fromWeight: '',
                        toWeight: '',
                        priceUrban: '',
                        priceSuburban: ''
                    }
                ]
            }
            this.dataRequest.price = result.body.variants[0].price
            this.dataRequest.salePrice = result.body.variants[0].salePrice
            if (!productStore.dataRequest.packingSize || productStore.dataRequest.packingSize.length < 0) {
                productStore.dataRequest.packingSize = ["", "", ""];
            }
        }
    }

    async getUnit() {
        const result = await productService.getUnit();
        if (result.status === HttpStatusCode.OK) {
            this.listUnit = result.body;
        }
    }

    async uploadImg(value: any) {
        productStore.dataRequest.media.push({
            url: value,
            type: "IMAGE",
            default: productStore.dataRequest.media && productStore.dataRequest.media.length < 1
        });
    }

    async removeImg(i: any) {
        productStore.dataRequest.media.splice(i, 1);
    }


    async addProduct() {
        let {
            name,
            categoryId,
            price,
            salePrice,
            quantity,
            unitId,
            originSourceLink,
            description,
            sku,
            weight,
            packingSize,
            transportFees,
            media
        } = this.dataRequest;

        this.clearError()


        let data: any = {
            name: name,
            categoryId: categoryId,
            price: price,
            salePrice: salePrice,
            quantity: quantity,
            unitId: unitId,
            originSourceLink: originSourceLink,
            shopCategoryId: "",
            description: description,
            packingSize: packingSize,
            weight: weight,
            videos: [],
            sku: sku,
            transportFees: transportFees,
            shopId: this.shopId,
            media: media,
            wholeSales: [],
            variants: []
        };

        if (!this.shopId) {
            this.errors.shopId = 'Shop sản phẩm không được để trống !'
        }

        if (!name) {
            this.errors.name = 'Tên sản phẩm không được để trống !'
        }

        if (!categoryId) {
            this.errors.categoryId = 'Danh mục sản phẩm không được để trống !'
        }

        if (media && media.length < 1) {
            this.errors.media = 'Ảnh sản phẩm không được để trống !'
        }

        if (!quantity) {
            this.errors.quantity = 'Giá sản phẩm không được để trống !'
        }

        if (!unitId) {
            this.errors.unitId = 'Đơn vị sản phẩm không được để trống !'
        }

        if (!weight) {
            this.errors.weight = 'Cân nặng sản phẩm không được để trống !'
        }

        if (!packingSize[0] || !packingSize[1] || !packingSize[2]) {
            this.errors.packingSize = 'Kích thước sản phẩm không được để trống !'
        }

        if (!price) {
            this.errors.price = 'Giá gốc sản phẩm không được để trống !'
        }

        if (!salePrice) {
            this.errors.salePrice = 'Giá bán sản phẩm không được để trống !'
        }

        if(price < salePrice){
            this.errors.price = 'Giá gốc phải lớn hơn hoặc bằng giá bán !'
        }

        transportFees && transportFees.length > 0 ? transportFees.map((item: any) => {
            if(!item.toWeight || !item.priceUrban || !item.priceSuburban){
                this.errors.transportFees = "Vui lòng nhập đầy đủ thông tin!"
            }
        }) : this.errors.transportFees = "Vui lòng nhập đầy đủ thông tin!"

        if (!description) {
            this.errors.description = 'Mô tả sản phẩm không được để trống !'
        }

        if(originSourceLink && checkWebsite(originSourceLink)){
            this.errors.originSourceLink = "Link truy xuất nguồn gốc chưa đúng định dạng!"
            return
        }

        if(!this.shopId || !name || this.errors.media || this.errors.transportFees || this.errors.packingSize || !quantity || !weight || !price || !salePrice || !description){
            return
        }

        this.isLoadingBt = true;
        const result = await productService.addProduct(data);
        this.isLoadingBt = false;

        if (result.status === HttpStatusCode.OK) {
            toastr.success("Thêm sản phẩm thành công");
            productStore.redirect = true
            this.clearForm();
            this.clearError()
        } else {
            if (typeof result.body.message === "string") {
                toastr.error(result.body.message);
            } else if (typeof result.body.message === "object") {
                this.errors = result.body.message
            } else {
                toastr.error("Đã có lỗi xảy ra!");
            }
        }

    }

    async editProduct() {
        let {
            id,
            shopId,
            name,
            categoryId,
            price,
            salePrice,
            quantity,
            unitId,
            originSourceLink,
            description,
            sku,
            weight,
            transportFees,
            packingSize,
            media
        } = this.dataRequest;
        let checkDefault = true
        media && media.map((item: any) => {
            if (item.default) {
                checkDefault = false
            }
        })

        if (checkDefault) {
            media[0].default = true
        }
        let data: any = {
            name: name,
            categoryId: categoryId,
            price: price,
            salePrice: salePrice,
            quantity: quantity,
            unitId: unitId,
            originSourceLink: originSourceLink,
            shopCategoryId: "",
            description: description,
            packingSize: packingSize,
            weight: weight,
            transportFees: transportFees,
            videos: [],
            sku: sku,
            media: media,
            wholeSales: [],
            variants: []
        };

        this.clearError()

        if (!name) {
            this.errors.name = 'Tên sản phẩm không được để trống !'
        }

        if (!categoryId) {
            this.errors.categoryId = 'Danh mục sản phẩm không được để trống !'
        }

        if (media && media.length < 1) {
            this.errors.media = 'Ảnh sản phẩm không được để trống !'
        }

        if (!quantity) {
            this.errors.quantity = 'Giá sản phẩm không được để trống !'
        }

        if (!packingSize[0] || !packingSize[1] || !packingSize[2]) {
            this.errors.packingSize = 'Kích thước sản phẩm không được để trống !'
        }

        if (!unitId) {
            this.errors.unitId = 'Đơn vị sản phẩm không được để trống !'
        }

        if (!weight) {
            this.errors.weight = 'Cân nặng sản phẩm không được để trống !'
        }

        if (!price) {
            this.errors.price = 'Giá gốc sản phẩm không được để trống !'
        }

        if (!salePrice) {
            this.errors.salePrice = 'Giá bán sản phẩm không được để trống !'
        }

        transportFees && transportFees.length > 0 ? transportFees.map((item: any) => {
            if(!item.toWeight || !item.priceUrban || !item.priceSuburban){
                this.errors.transportFees = "Vui lòng nhập đầy đủ thông tin!"
            }
        }) :  this.errors.transportFees = "Vui lòng thêm thiết lập giá vận chuyển!"

        if (!description) {
            this.errors.description = 'Mô tả sản phẩm không được để trống !'
        }

        if(originSourceLink && checkWebsite(originSourceLink)){
            this.errors.originSourceLink = "Link truy xuất nguồn gốc chưa đúng định dạng!"
            return
        }

        if(!name || this.errors.media || this.errors.transportFees || this.errors.packingSize || !quantity || !weight || !price || !salePrice || !description){
            return
        }


        this.isLoadingBt = true;
        const result = await productService.updateProduct(id, shopId, data);
        this.isLoadingBt = false;

        if (result.status === HttpStatusCode.OK) {
            toastr.success("Cập nhật sản phẩm thành công");
            productStore.redirect = true
            this.clearForm();
            this.clearError();
        } else {
            if (typeof result.body.message === "string") {
                toastr.error(result.body.message);
            } else if (typeof result.body.message === "object") {
                this.errors = result.body.message
            } else {
                toastr.error("Đã có lỗi xảy ra!");
            }
        }

    }

    returnMessage(state: any) {
        switch (state) {
            case "PUBLIC":
                return "Duyệt"
            case "REJECT":
                return "Từ chối"
        }
    }


    async changeState(id: any, state: any) {
        let data = {
            ids: [id],
            state: state,
            actor: "ADMIN"
        }
        productStore.isLoadingBt = true;
        const result = await productService.changeState(data);
        productStore.isLoadingBt = false;
        if (result.status === HttpStatusCode.OK) {
            toastr.success(`${this.returnMessage(state)} sản phẩm thành công!`);
            await productStore.getProducts();
        } else {
            toastr.error(result.body.message ?? "Đã có lỗi xảy ra!");
        }
    }

    async delete() {
        let data = {
            ids: [productStore.id],
            state: "DELETED",
            actor: "ADMIN"
        }
        productStore.isLoadingBt = true;
        const result = await productService.deleteProduct(data);
        productStore.isLoadingBt = false;

        if (result.status === HttpStatusCode.OK) {
            toastr.success("Xóa sản phẩm thành công");
            $("#close_delete").trigger("click");
            await productStore.getProducts();
        } else {
            toastr.error(result.body.message ?? "Đã có lỗi xảy ra!");
        }
    }

}

export const productStore = new ProductStore();