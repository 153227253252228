import axios, {Method} from "axios";
import StorageService from "../service/StorageService";
import HttpStatusCode from "../constants/HttpErrorCode";
import humps from "humps";
import {profileStore} from "../../modules/profile/ProfileStore";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import {toastUtil} from "../utils/ToastUtil";

export interface IApiResponse {
    readonly status: number;
    readonly body: any;
}

export interface IBodyError {
    readonly errorCode: number;
    readonly message: string
}


let apiUrl = process.env.REACT_APP_API_URL

let apiRefreshToken = apiUrl + '/sso/v1/auth/refresh-token'

const refreshAuthLogic = (failedRequest: { config: { headers: any } }) => axios.post(apiRefreshToken, {'refreshToken': StorageService.getRefreshToken()}).then(response => {
    StorageService.setToken(response.data.token);
    failedRequest.config.headers['Authorization'] = 'Bearer ' + response.data.token;
    return Promise.resolve();
}).catch(function (error) {
    return Promise.reject();
});

createAuthRefreshInterceptor(axios, refreshAuthLogic, {
    pauseInstanceWhileRefreshing: true
});

axios.interceptors.request.use((config: any) => {
    if(StorageService.getToken()){
        config.headers['Authorization'] = 'Bearer ' + StorageService.getToken();
    }
    return config;
});

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (originalRequest.url === apiRefreshToken) {
            toastUtil.error('Phiên đăng nhập hết hạn', 2);
            setTimeout(() => {
                localStorage.clear()
                StorageService.removeToken();
                StorageService.removeRefreshToken();
                window.location.href = '/auth'
            }, 600)
        }
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                await createAuthRefreshInterceptor(axios, refreshAuthLogic);
                return axios(originalRequest);
            } catch (refreshError) {
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);



export async function getRequest(path: string, isGetImg?: boolean): Promise<IApiResponse> {
    var newHeaders: any = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    }

    if (StorageService.isTokenExits()) {
        newHeaders['Authorization'] = 'Bearer ' + StorageService.getToken()
    }

    if (StorageService.isTokenExits() && isGetImg) {
        newHeaders['x-om-user-id'] = profileStore.profileDetail?.id
    }

    return await axios.get(apiUrl + path, {headers: newHeaders})
        .then(
            (response) => {

                const apiResponse: IApiResponse = {
                    status: response.status,
                    body: humps.camelizeKeys(response.data),
                };
                return apiResponse;
            },
            (error) => {

                let bodyError: IBodyError;
                try {
                    bodyError = {
                        errorCode: error.response.data?.errorCode,
                        message: error.response.data?.message
                    }
                } catch (e) {
                    bodyError = {
                        errorCode: HttpStatusCode.UNKNOW_ERROR,
                        message: "Unknow error, please try again later"
                    }
                }

                const apiResponse: IApiResponse = {
                    status: error.response?.status,
                    body: bodyError
                };
                return apiResponse;
            }
        );
}

export async function postRequest(path: string, params: object): Promise<IApiResponse> {
    return apiCall(path, "POST", params);
}

export function apiCall(path: string, _method: Method = "POST", _params: object): Promise<IApiResponse> {

    var newHeaders: any = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    }

    if (StorageService.isTokenExits()) {
        newHeaders['Authorization'] = 'Bearer ' + StorageService.getToken()
    }

    return new Promise<IApiResponse>((resolve) => {
        axios({
            data: JSON.stringify(_params),
            headers: newHeaders,
            method: _method,
            url: apiUrl + path
        })
            .then(function (response) {
                resolve({
                    status: response.status,
                    body: humps.camelizeKeys(response.data),
                });
            })
            .catch(function (error) {
                let bodyError: IBodyError;
                try {
                    bodyError = {
                        errorCode: error.response.data.errorCode,
                        message: error.response.data.message
                    }

                } catch (e) {
                    bodyError = {
                        errorCode: HttpStatusCode.UNKNOW_ERROR,
                        message: "Unknow error, please try again later"
                    }
                }

                const apiResponse: IApiResponse = {
                    status: error.response.status,
                    body: bodyError
                };

                resolve(apiResponse);
            });

    });
}

export async function putRequest(path: string, params: object): Promise<IApiResponse> {
    return apiCall(path, "PUT", params);
}

export async function deleteRequest(path: string, params: object): Promise<IApiResponse> {

    return apiCall(path, "DELETE", params);
}
