import {makeAutoObservable} from 'mobx'
import $ from 'jquery'
import {toastUtil} from '../../common/utils/ToastUtil'
import {newsService} from './NewsService'


class NewsStore {
  id: any = ''
  page: any = 0
  type: any = ''
  state: any = ''
  keyword: any = ''
  redirect: boolean = false
  totalPage: any = 0
  listPost: any[] = []
  dataRequest: any = {
    id: '',
    title: '',
    shortContent: '',
    introduce: {
      special: '',
    },
    operation: {
      title: '',
      item: [
        {
          title1: '',
          title2: '',
          description: '',
          action: [
            {
              title: '',
              link: '',
            },
          ],
        },
      ],
    }
  }
  isLoading: boolean = false
  isLoadingBt: boolean = false
  isLoadingDetail: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  clearForm() {
    this.isLoadingDetail = true
    this.type = 'add'
    this.dataRequest = {
      id: '',
      title: '',
      shortContent: '',
      introduce: {
        special: '',
      },
      operation: {
        title: '',
        item: [],
      }
    }
    setTimeout(() => {
      this.isLoadingDetail = false
    }, 100)
  }


  async getNews() {
    this.isLoading = true
    const result = await newsService.getNews()
    this.isLoading = false
    if (result.status === 200) {
      this.listPost = result.body.data
      this.totalPage = result.body.metadata.totalPage
    }
  }

  async getPostDetail(id: any) {
    this.type = 'edit'
    this.isLoadingDetail = true
    const result = await newsService.getDetail(id)
    if (result.status === 200) {
      this.dataRequest = result.body
      if(!this.dataRequest.operation?.item){
        this.dataRequest.operation.item = []
      }
      this.isLoadingDetail = false
    }
  }

  async changeStatus(item: any) {

    let data = {
      ids: [item.id],
      status: item.status == 'HIDE' ? 'PUBLIC' : 'HIDE',
    }
    this.isLoadingBt = true
    const result = await newsService.changeStatus(data)
    this.isLoadingBt = false
    if (result.status === 200) {
      toastUtil.success('Cập nhật trạng thái thành công!')
      item.status = data.status
    } else toastUtil.error(result.body.message ?? 'Đã có lỗi xảy ra!')

  }

  async addOrEdit() {

    let {id, title, shortContent, introduce, operation} = this.dataRequest

    let data: any = {
      title: title,
      shortContent: shortContent,
      introduce: introduce,
      content: [{title: '', content: ''}],
      operation: operation,
      type: 'NEW',
    }
    

    if (!title) {
      toastUtil.warning('Tiêu đề không được để trống!')
      return
    }

    this.isLoadingBt = true
    let result: any

    if (this.type === 'add') {
      result = await newsService.add(data)
    } else {
      result = await newsService.update(id, data)
    }

    this.isLoadingBt = false
    if (result.status === 200) {
      toastUtil.success(`${this.type == 'add' ? 'Thêm' : 'Cập nhật'} tin tức thành công!`)
      this.clearForm()
      this.redirect = true
    } else toastUtil.error(result.body.message ?? 'Đã có lỗi xảy ra')

  }

  async delete() {
    this.isLoadingBt = true
    const result = await newsService.delete(this.id)
    this.isLoadingBt = false
    if (result.status === 200) {
      toastr.success('Xóa tin tức thành công!')
      $('#close_delete_new').trigger('click')
      await this.getNews()
    } else toastr.error(result.body.message ?? 'Đã có lỗi xảy ra')
  }


}

export const newsStore = new NewsStore()