import {Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import CategoryPage from '../pages/categories/CategoryPage'
import AddCate from '../pages/categories/components/AddEditCate'
import EditCate from '../pages/categories/components/AddEditCate'
import BlogPage from '../pages/blog/BlogPage'
import AddBlog from '../pages/blog/components/AddEditBlog'
import EditBlog from '../pages/blog/components/AddEditBlog'
import ConfigHomePage from '../pages/config/ConfigPage'
import ContactPage from '../pages/service/contact/ContactPage'
import RecruitmentPage from '../pages/recruitment/RecruitmentPage'
import OccupationPage from '../pages/recruitment/occupation/OccupationPage'
import AddRecruitment from '../pages/recruitment/components/AddEditRecruitment'
import EditRecruitment from '../pages/recruitment/components/AddEditRecruitment'
import ApplyPage from '../pages/recruitment/apply/ApplyPage'
import AddConfig from '../pages/config/components/AddEditConfig'
import EditConfig from '../pages/config/components/AddEditConfig'
import CategoriesWrapper from "../pages/store/categories/CategoriesWrapper";
import ProductWrapper from "../pages/store/products/ProductWrapper";
import AddProduct from "../pages/store/products/components/AddProduct";
import EditProduct from "../pages/store/products/components/EditProduct";
import ShopsWrapper from "../pages/store/shops/ShopsWrapper";
import AddShop from "../pages/store/shops/components/AddShop";
import EditShop from "../pages/store/shops/components/EditShop";
import BannerWrapper from "../pages/store/config/banner/BannerWrapper";
import OrderWrapper from "../pages/store/order/OrderWrapper";
import MenuWrapper from "../pages/store/config/menu/MenuWrapper";
import FooterWrapper from "../pages/store/config/footer/FooterWrapper";
import StandardPage from "../pages/standard/StandardPage";
import NewsPage from "../pages/news/NewsPage";
import AddEditStandard from "../pages/standard/components/AddEditStandard";
import AddEditNews from "../pages/news/components/AddEditNews";

const PrivateRoutes = () => {

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/category' />} />
        <Route path='/category' element={<CategoryPage/>} />
        <Route path='/add-category' element={<AddCate/>} />
        <Route path='/update-category/:slug/:id' element={<EditCate/>} />
        <Route path='/blog' element={<BlogPage/>} />
        <Route path='/add-blog' element={<AddBlog/>} />
        <Route path='/update-blog/:slug/:id' element={<EditBlog/>} />

        <Route path='/new' element={<NewsPage/>} />
        <Route path='/add-new' element={<AddEditNews/>} />
        <Route path='/update-new/:slug/:id' element={<AddEditNews/>} />

        <Route path='/standard' element={<StandardPage/>} />
        <Route path='/add-standard' element={<AddEditStandard/>} />
        <Route path='/update-standard/:slug/:id' element={<AddEditStandard/>} />

        <Route path='/config' element={<ConfigHomePage/>} />
        <Route path='/add-config' element={<AddConfig/>} />
        <Route path='/update-config/:id' element={<EditConfig/>} />
        <Route path='/contact' element={<ContactPage/>} />
        <Route path='/recruitment' element={<RecruitmentPage/>} />
        <Route path='/add-recruitment' element={<AddRecruitment/>} />
        <Route path='/update-recruitment/:slug/:id' element={<EditRecruitment/>} />
        <Route path='/occupation' element={<OccupationPage/>} />
        <Route path='/apply' element={<ApplyPage/>} />
        <Route path='/store/categories' element={<CategoriesWrapper />} />
        <Route path='/store/products' element={<ProductWrapper />} />
        <Route path='/store/add-product' element={<AddProduct />} />
        <Route path='/store/edit-product/:slug/:id' element={<EditProduct />} />
        <Route path='/store/shops' element={<ShopsWrapper />} />
        <Route path='/store/add-shop' element={<AddShop />} />
        <Route path='/store/edit-shop/:slug/:id' element={<EditShop />} />
        <Route path='/store/banner' element={<BannerWrapper />} />
        <Route path='/store/order' element={<OrderWrapper />} />
        <Route path='/store/menu' element={<MenuWrapper />} />
        <Route path='/store/footer' element={<FooterWrapper />} />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspendedView = ({children}: any) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })

  // @ts-ignore
  return <Suspense fallback={<TopBarProgress/>}>{children}</Suspense>
}

export {PrivateRoutes}
