import {makeAutoObservable} from 'mobx'
import $ from 'jquery'
import {menuService} from './MenuService'
import HttpStatusCode from "../../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../../common/utils/ToastUtil";

class MenuStore {
  id: any = ''
  title: string = "Tạo mới"
  page: any = 0
  file: any = ''
  listMenu: any[] = []
  dataRequest: any = {
    id: '',
    image: '',
    linkTo: '',
    title: '',
    sort: ''
  }
  isLoadingUpload: boolean = false
  isLoading: boolean = false
  isLoadingBt: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  clearForm() {
    this.file = ''
    this.dataRequest = {
      id: '',
      image: '',
      linkTo: '',
      title: '',
      sort: ''
    }
    this.title = "Tạo mới"
  }


  async getMenu() {
    this.isLoading = true
    const result = await menuService.getMenu()
    this.isLoading = false
    if (result.status === HttpStatusCode.OK) {
      this.listMenu = result.body
    }
  }

  async changeImg(value: any) {
    menuStore.dataRequest.image = value
  }


  getMenuDetail(items: any) {
    menuStore.title = "Cập nhật"
    this.dataRequest = {...items}
  }


  async addOrUpdate() {

    let {id, sort, image, linkTo, title} = this.dataRequest

    let data: any = {
      id: id,
      image: image,
      linkTo: linkTo,
      title: title,
      sort: sort
    }

    this.isLoadingBt = true
    const result = await menuService.addOrUpdateMenu(data)
    this.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      toastUtil.success(menuStore.title == 'Tạo mới' ? 'Thêm' : 'Cập nhật' + 'menu thành công!')
      $('#close_add_edit_menu').trigger('click')
      this.clearForm()
      await this.getMenu()
    }
    else toastUtil.error(result.body.message)
  }

  async delete() {
    menuStore.isLoadingBt = true
    const result = await menuService.deleteMenu(menuStore.id)
    menuStore.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      toastUtil.success('Xóa menu thành công!')
      $('#close_delete').trigger('click')
      await menuStore.getMenu()
    }
    else toastUtil.error(result.body.message)
  }

}

export const menuStore = new MenuStore()