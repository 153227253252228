/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React from 'react'
import {useLayout} from '../../core'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'

export function HeaderWrapper() {
  const {config, classes, attributes} = useLayout()
  const {header} = config

  return (
    <div id='kt_header' className={clsx('header', classes.header.join(' '), 'align-items-stretch')} {...attributes.headerMenu} style={{ boxShadow: `0px 10px 30px 0px rgb(82 63 105 / 5%)`}}>
      <div className={clsx(classes.headerContainer.join(' '), 'd-flex align-items-stretch justify-content-between')}>
        <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
          <span />
          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )}
          <div className='d-flex align-items-stretch flex-shrink-0'>
            <Topbar />
          </div>
        </div>
      </div>
    </div>
  )
}
