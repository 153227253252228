import {getRequest, IApiResponse, postRequest, putRequest} from '../../common/helpers/RequestHelper'
import {configStore} from "./ConfigStore";


class ConfigService {

  public getConfig(): Promise<IApiResponse>{
    return getRequest(`/omfarm/v1/config?page=${configStore.page}&size=20`)
  }

  public getDetailConfig(id: any): Promise<IApiResponse>{
    return getRequest(`/omfarm/v1/config/${id}`)
  }

  public addConfig(data: any): Promise<IApiResponse>{
    return postRequest(`/omfarm/v1/config`, data)
  }

  public editConfig(data: any): Promise<IApiResponse>{
    return putRequest(`/omfarm/v1/config`, data)
  }

}

export const configService = new ConfigService()

