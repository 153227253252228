import {makeAutoObservable} from 'mobx'
import $ from "jquery"
import {footerService} from './FooterService'
import axios from "axios";
import HttpStatusCode from "../../../../common/constants/HttpErrorCode";



class FooterStore {
  id: any = ''
  value: any = ''
  timer: any = null;
  isLoading: boolean = false
  isLoadingBt: boolean = false
  isLoadingDetail: boolean = false
  listFooter: any[] = []
  dataRequest: any = {
    id: '',
    title: '',
    content: ''
  }

  constructor() {
    makeAutoObservable(this)
  }


   handleEditorChange = (e: any) => {
    footerStore.value = e.target.innerHTML.toString();
    clearTimeout(this.timer);
    this.timer = setTimeout( () => {
      footerStore.dataRequest.content = footerStore.value;
    }, 30000);
  }

  clearForm(){
    this.dataRequest = {
      id: '',
      title: '',
      content: ''
    }
  }


  async getFooter(){
    this.isLoading = true;
    const result = await footerService.getFooter()
    this.isLoading = false;
    if(result.status === HttpStatusCode.OK){
      this.listFooter = result.body;
    }
  }
  
  async getDetail(id: any) {
    this.isLoadingDetail = true
    const result = await footerService.getDetail(id);
    this.isLoadingDetail = false
    if (result.status === HttpStatusCode.OK) {
      this.dataRequest = result.body;
    }
  }

  async add() {
    let {title, content} = this.dataRequest

    let data: any = {
      title: title,
      content: content,
      footerCateId: 1,
    }

    if(!title){
      toastr.warning('Tiêu đề không được để trống')
      return
    }

    if(!content){
      toastr.warning('Nội dung không được để trống')
      return
    }


    this.isLoadingBt = true;
    const result = await footerService.addFooter(data);
    this.isLoadingBt = false;
    if (result.status === HttpStatusCode.OK) {
      toastr.success('Thêm footer thành công!')
      $('#close_add').trigger('click')
      this.clearForm()
      await this.getFooter()
    }else {
      toastr.error(result.body.metadata ?? 'Đã có lỗi xảy ra!')
    }
  }


   sendUploadThumb = async (blobInfo: any, data: any) => {
    const uploadImg = axios.create({
      baseURL: data.uploadUrl,
      method: 'PUT',
      headers: {
        'Content-Type': blobInfo.blob().type
      }
    })
    let result = await uploadImg.put(data.uploadUrl, blobInfo.blob())
    if (result.status == 200) {
      footerStore.dataRequest.content += `<img src=${data.imageUrl} alt="">`;
    }

  }

  async update() {

    let {id, title, content} = this.dataRequest

    let data: any = {
      id: id,
      title: title,
      content: content,
      footerCateId: 1,
    }

    this.isLoadingBt = true;
    const result = await footerService.updateFooter(id, data);
    this.isLoadingBt = false;
    if (result.status === HttpStatusCode.OK) {
      $('#close_edit').trigger('click')
      toastr.success('Cập nhật footer thành công!')
      this.listFooter.map((item) => {
        if (item.id === id) {
          item.title = title
        }
      })
      this.clearForm()
    }else {
      toastr.error(result.body.metadata ?? 'Đã có lỗi xảy ra!')
    }
  }

  async delete() {
    footerStore.isLoadingBt = true;
    const result = await footerService.deleteFooter(this.id);
    footerStore.isLoadingBt = false;
    if (result.status === HttpStatusCode.OK) {
      toastr.success('Xóa footer thành công!')
      footerStore.listFooter.map((item, i) => {
        if (item.id === this.id) {
          footerStore.listFooter.splice(i, 1)
        }
      })
      $('#close_delete').trigger('click')
    }else toastr.error(result.body.message ?? 'Đã có lỗi xảy ra!')
  }

}

export const footerStore = new FooterStore();