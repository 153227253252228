import {observer} from 'mobx-react-lite'
import React from 'react'
import {orderStore} from '../OrderStore'
import {number_format, urlImage} from "../../../../common/utils/Utils";
import NoContent from "../../../../common/components/NoContent";


const ListProduct = observer(() => {

  return(
    <div className='modal fade' id='detail_product' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered mw-1000px'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>Danh sách sản phẩm</h2>
            <button type="button" id="close_detail" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
          </div>

          <div className='modal-body py-lg-10 px-lg-10'>
            <div className='table-responsive'>
              {orderStore.listProduct && orderStore.listProduct.length > 0 ?
                <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
                  <thead>
                  <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                    <th>ID</th>
                    <th>Ảnh sản phẩm</th>
                    <th>Tên sản phẩm</th>
                    <th>Giá</th>
                    <th>Số lượng</th>
                    <th>Tổng</th>
                  </tr>
                  </thead>
                  <tbody>
                  {orderStore.listProduct.map((item: any, i: number) => {
                    return (
                      <tr key={i}>
                        <td>{item.id}</td>
                        <td>
                          {item.productInfo?.thumbnail && <div className="symbol symbol-30px symbol-md-40px">
                            <img src={urlImage(item.productInfo?.thumbnail)} alt=""/>
                          </div>}
                        </td>
                        <td>{item.productInfo?.name}</td>
                        <td>{number_format(item.price)} đ</td>
                        <td>{item.quantity}</td>
                        <td>{number_format(item.price * item.quantity)} đ</td>
                      </tr>
                    )
                  })}
                  </tbody>
                </table> : <NoContent/>}
            </div>
          </div>

        </div>
      </div>
    </div>
  )

})

export default ListProduct