import React, {useEffect} from 'react'
import AddCategory from './components/AddCategory'
import {categoryStore} from './CategoryStore'
import {observer} from 'mobx-react-lite'
import EditCategory from './components/EditCategory'
import DeleteCategory from './components/DeleteCategory'
import Loading from "../../../common/components/Loading";
import NoContent from "../../../common/components/NoContent";
import Paginate from "../../../common/components/Paginate";
import {sttPage, urlImage} from "../../../common/utils/Utils";


const CategoriesWrapper = observer(() => {

  const handlePageClick = async (data: any) => {
    categoryStore.page = data.selected;
    await categoryStore.getCate();
  };

  const returnState = (state: any) => {
     switch (state) {
         case 'ACTIVE':
             return <div className="badge badge-light-primary">Hiện</div>
         case 'INACTIVE':
             return <div className="badge badge-light-warning">Ẩn</div>
     }
  }

  useEffect(() => {
    categoryStore.getCate()
    categoryStore.getCateParent()
  }, [])

  return (
    <>
      <div className='d-flex align-items-center justify-content-between mb-10'>
        <h1 className='d-flex text-dark fw-bolder fs-3 align-items-center my-1'>Danh sách danh mục</h1>
        <a href='#' className='btn btn-sm btn-primary' onClick={() => categoryStore.clearForm()} data-bs-toggle='modal' data-bs-target='#add_cate'>Tạo mới</a>
      </div>

      {categoryStore.isLoading ? <Loading/> : <div className='table-responsive'>
        {categoryStore.listCate && categoryStore.listCate.length > 0 ?  <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
          <thead>
            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
              <th>STT</th>
              <th>Vị trí</th>
              <th>Ảnh</th>
              <th>Tên danh mục</th>
              <th>Cấp danh mục</th>
              <th>Trạng thái</th>
              <th/>
            </tr>
          </thead>
          <tbody>
          {categoryStore.listCate.map((item: any, i: number) => {
            return (
              <tr key={i}>
                <td>{sttPage(categoryStore.page, i)}</td>
                <td>{item.sort}</td>
                <td>
                  <div className="symbol symbol-30px symbol-md-40px">
                    <img src={urlImage(item.image)} alt=""/>
                  </div>
                </td>
                <td>{item.name}</td>
                <td>Danh mục cấp {item.level}</td>
                <td>{returnState(item.state)}</td>
                <td width="12%">
                  <a href="#" className="btn btn-icon btn-warning btn-sm" data-bs-toggle='modal' data-bs-target='#edit_cate' onClick={() => categoryStore.getCateDetail(item.id)}><i className="fas fa-pen"/></a>
                  <span className="p-2"/>
                  <a href="#" className="btn btn-icon btn-danger btn-sm" data-bs-toggle='modal' data-bs-target='#delete_cate' onClick={() => categoryStore.id = item.id}><i className="fas fa-trash-alt"/></a>
                </td>
              </tr>
            )
          })}
          </tbody>
        </table> : <NoContent/>}
        <Paginate callback={handlePageClick} totalPage={categoryStore.totalPage} currentPage={categoryStore.page}/>
      </div>}
      <AddCategory />
      <EditCategory/>
      <DeleteCategory/>
    </>
  )
})

export default CategoriesWrapper