import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from '../../common/helpers/RequestHelper'
import {blogStore} from './BlogStore'


class BlogService {

  public getBlog(): Promise<IApiResponse>{
    return getRequest(`/omfarm/v1/blog?page=${blogStore.page}&size=10&collection=admin${blogStore.blogCategoryId ? `&blogCategoryId=${blogStore.blogCategoryId}` : ''}${blogStore.keyword ? `&keyword=${blogStore.keyword}` : ''}`)
  }

  public getDetailBlog(id: any): Promise<IApiResponse>{
    return getRequest(`/omfarm/v1/blog/${id}`)
  }

  public changeStatus(data: any): Promise<IApiResponse>{
    return postRequest(`/omfarm/v1/blog/change_status`, data)
  }

  public addBlog(data: any): Promise<IApiResponse>{
    return postRequest(`/omfarm/v1/blog`, data)
  }

  public updateBlog(id: any, data: any): Promise<IApiResponse>{
    return putRequest(`/omfarm/v1/blog/${id}`, data)
  }

  public deleteBlog(id: any): Promise<IApiResponse>{
    return deleteRequest(`/omfarm/v1/blog`, [id])
  }

}

export const blogService = new BlogService()

