import React, {useRef, useState} from 'react'
import {Editor} from '@tinymce/tinymce-react'
import ChooseImg from "./modalChooseImg/ChooseImg";
import {getRequest} from "../helpers/RequestHelper";
import axios from "axios";



interface IProps {
    idModal?:string,
    content: any,
    height?: number,
    onChange: any,
    isChooseImg?: boolean
}


let bucket = process.env.REACT_APP_BUCKET

const getGenerateUrl = async (files: any, success: any, failure: any) => {
    let result = await getRequest(`/v1/s3/generate-upload-url?fileName=${files.name}&bucket=${bucket}&type=IMAGE`)

    if (result.status == 200) {
        await uploadFilesS3(files, result.body, success, failure)
    } else failure(result.body.message)
}

const uploadFilesS3 = async (files: any, data: any, success: any, failure: any) => {
    const uploadFile = axios.create({
        baseURL: data.uploadUrl,
        method: 'PUT',
        headers: {
            'Content-Type': files.type
        }
    })
    let resultGenFile = await uploadFile.put(data.uploadUrl, files)
    if (resultGenFile.status == 200) {
        success(data.imageUrl)
    } else failure('Lỗi upload'!)
}


const TextEditor = ({idModal, content, height, onChange, isChooseImg}: IProps) => {
    const editorRef = useRef<any>(null);
    const [value, setValue] = useState<any>(content)

    const handleChange = (content: any, editor: any) => {
        setValue(content)
        onChange(content)
    }


    const handleImageUpload = async (blobInfo: any, success: any, failure: any) => {
        const formData = new FormData();
        formData.append("files", blobInfo.blob(), blobInfo.filename());
        await getGenerateUrl(blobInfo.blob(), success, failure)
    };

    const changeImg = (img: any) => {
        if (editorRef.current) {
            const editor = editorRef.current?.editor;
            editor.insertContent(`<img src="${img}" alt="" />`);
        }
    }

    const fontFormats = [
        'Helvetica',
        'Arial',
        'Arial Black',
        'Comic Sans MS',
        'Courier New',
        'Georgia',
        'Impact',
        'Lucida Console',
        'Tahoma',
        'Times New Roman',
        'Verdana'
    ];

    return (
       <div className="position-relative">
           {isChooseImg && <>
               <div className="position-absolute" style={{top: 10, right: 15, zIndex: 1200}} data-bs-toggle='modal' data-bs-target={`#${idModal ?? 'modal_choose_img'}`}>
                   <i className="fas fa-file-image cursor-pointer" style={{fontSize: 18, color: '#222f3e'}} title={"Chọn ảnh đã tải lên"}/>
               </div>
               <ChooseImg id={idModal} service="farmorg" changeImg={changeImg} isEditor={true}/>
           </>}
           <Editor
               ref={editorRef}
               apiKey='byq8umzzwaluv36t0ot4g497osmdax8vh4qgnqemqdjabr1p'
               value={value}
               onEditorChange={handleChange}
               init={{
                   height: height ?? 350,
                   menubar: 'file edit view insert format tools tc help',
                   plugins: ['image', 'textcolor media link',
                       'advlist autolink lists link image charmap print preview anchor',
                       'searchreplace visualblocks code fullscreen',
                       'insertdatetime media table paste code help wordcount'
                   ],
                   fontsize_formats: '8px 10px 12px 14px 16px 18px 24px 36px',
                   font_formats: fontFormats.map((font) => `${font}= ${font}`).join(';'),
                   toolbar: 'undo redo | formatselect | fontselect | fontsizeselect |' +
                       'bold italic forecolor backcolor | alignleft aligncenter ' +
                       'alignright alignjustify | bullist numlist outdent indent | Lists ' + 'InsertHr' +
                       ' Preview ' +
                       'link image media',
                   images_upload_handler: handleImageUpload,
                   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                   automatic_uploads: true,
                   setup: (editor) => {
                       editor.ui.registry.addButton('insertHr', {
                           icon: 'Line',
                           tooltip: 'Dòng kẻ',
                           onAction: () => {
                               editor.insertContent('<hr />');
                           },
                       });
                   },
               }}
           />
       </div>
    )
}

export default TextEditor