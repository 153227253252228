import {deleteRequest, getRequest, IApiResponse, postRequest} from "../../../../common/helpers/RequestHelper";

class BannerService {

  public getBanner(): Promise<IApiResponse>{
    return getRequest(`/v1/config/banner`)
  }

  public addOrUpdate(data: any): Promise<IApiResponse>{
    return postRequest(`/v1/config/banner`, data)
  }

  public deleteBanner(id: any): Promise<IApiResponse>{
    return deleteRequest(`/v1/config/banner/${id}`, {id})
  }

}

export const bannerService = new BannerService()

