import {shopStore} from './ShopStore'
import {getRequest, IApiResponse, postRequest, putRequest} from "../../../common/helpers/RequestHelper";
import {profileStore} from "../../../modules/profile/ProfileStore";

class ShopService {

  public getShops(size?: any): Promise<IApiResponse>{
    return getRequest(`/v1/shops?page=${shopStore.page}&size=${size ?? '10'}${shopStore.state ? `&state=${shopStore.state}` : ''}${shopStore.name ? `&name=${shopStore.name.trim()}` : ''}`)
  }

  public getDetailShop(id: any): Promise<IApiResponse>{
    return getRequest(`/v1/shops/${id}`)
  }

  public getUsers(): Promise<IApiResponse>{
    return getRequest(`/v1/users?page=${shopStore.metadata.page}&size=50${shopStore.keyword ? `&keyword=${shopStore.keyword}` : ''}`)
  }

  public addShop( data: any ): Promise<IApiResponse>{
    return postRequest(`/v1/cms/shops`, data)
  }

  public updateShop(id: any, data: any): Promise<IApiResponse>{
    return putRequest(`/v1/cms/shops/${id}`, data)
  }

}

export const shopService = new ShopService()