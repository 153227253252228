import React from 'react'
import {observer} from 'mobx-react-lite'
import {menuStore} from '../MenuStore'
import {numberUtil} from "../../../../../common/utils/NumberUtil";
import UploadImg from "../../../../../common/components/UploadImg";

const AddOrUpdate = observer(() => {


  return (
    <div className='modal fade' id='add_or_update' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered mw-1000px'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>{menuStore.title} menu</h2>
            <button type="button" id="close_add_edit_menu" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
          </div>

          <div className='modal-body py-lg-10 px-lg-10'>
            <form noValidate className='form'>
              <div className='mb-5'>
                <label className='form-label w-100'>Ảnh</label>
                <UploadImg src={menuStore.dataRequest.image} changeImg={menuStore.changeImg}/>
              </div>
              <div className='mb-5'>
                <label className='form-label'>Tiêu đề</label>
                <input type='text' className='form-control' value={menuStore.dataRequest.title}
                       onChange={(e: any) => menuStore.dataRequest.title = e.currentTarget.value}
                       placeholder='Nhập tiêu đề' />
              </div>
              <div className='mb-5'>
                <label className='form-label'>Vị trí</label>
                <input type='text' className='form-control' value={menuStore.dataRequest.sort}
                       onChange={(e: any) => menuStore.dataRequest.sort = numberUtil.regexNumber(e)}
                       placeholder='Nhập vị trí' />
              </div>
              <div className='mb-5'>
                <label className='form-label'>Liên kết đến</label>
                <input type='text' className='form-control' value={menuStore.dataRequest.linkTo}
                       onChange={(e: any) => menuStore.dataRequest.linkTo = e.currentTarget.value}
                       placeholder='Nhập liên kết đến' />
              </div>
            </form>
          </div>

          <div className='modal-footer d-flex justify-content-end py-6 px-9'>
            <button type='reset' className='btn btn-light btn-active-light-primary me-2'
                    data-bs-dismiss='modal'>Hủy
            </button>
            <button type='submit' className='btn btn-primary' onClick={() => menuStore.addOrUpdate()} disabled={menuStore.isLoadingBt}>
              {menuStore.isLoadingBt ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Vui lòng chờ...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </span>
              ) : 'Lưu'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )

})

export default AddOrUpdate