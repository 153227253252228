import {productStore} from './ProductStore'
import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from "../../../common/helpers/RequestHelper";


class ProductService {

  public getProducts(): Promise<IApiResponse>{
    return getRequest(`/v1/products?page=${productStore.page}&size=10&collection=CMS${productStore.state ? `&state=${productStore.state}` : ''}${productStore.filter_shopId ? `&shopId=${productStore.filter_shopId}` : ''}${productStore.categoryId ? `&categoryId=${productStore.categoryId}` : ''}${productStore.keyword ? `&keyword=${productStore.keyword.trim()}` : ''}`)
  }

  public getDetailProduct(id: any): Promise<IApiResponse>{
    return getRequest(`/v1/products/${id}?actor=ADMIN`)
  }

  public addProduct(data: any ): Promise<IApiResponse>{
    return postRequest(`/v1/products`, data)
  }

  public updateProduct(productId: any, shopId: any, data: any): Promise<IApiResponse>{
    return putRequest(`/v1/products/${productId}`, data)
  }

  public changeState(data: any): Promise<IApiResponse>{
    return putRequest(`/v1/products/change_state`, data)
  }

  public deleteProduct(data: any): Promise<IApiResponse>{
    return putRequest(`/v1/products/change_state`, data)
  }

  public getUnit(): Promise<IApiResponse>{
    return getRequest(`/v1/resource/unit`)
  }


}

export const productService = new ProductService()