import {makeAutoObservable} from 'mobx'
import {recruitmentService} from './RecruitmentService'
import {toastUtil} from '../../common/utils/ToastUtil'
import $ from 'jquery'
import HttpStatusCode from '../../common/constants/HttpErrorCode'

export enum IType {
  FULL_TIME="FULL_TIME",
  PART_TIME="PART_TIME",
  REMOTE="REMOTE",
  COLLABORATORS="COLLABORATORS"
}

export enum ITypeGender {
  MALE="MALE",
  FEMALE="FEMALE",
  ALL="ALL"
}



class RecruitmentStore {
  state: any = ''
  id: any = ''
  name: any = ''
  page: number = 0
  totalPage: number = 0
  occupationId: any = ''
  redirect: boolean = false
  isLoading: boolean = false
  isLoadingBt: boolean = false
  isLoadingDetail: boolean = false
  listRecruitment: any[] = []
  listProvince: any[] = []
  listDistrict: any[] = []
  listWard: any[] = []
  dataRequest: any = {
    name: "",
    companyName: '',
    companyLogo: '',
    image: "",
    position: '',
    address: '',
    gender: ITypeGender.MALE,
    workExperience: '',
    description: "",
    province: {
      id: ''
    },
    district: {
      id: ''
    },
    ward: {
      id: ''
    },
    expiredDate: '',
    occupation: {
      id: ''
    },
    type: IType.FULL_TIME,
    salaryFrom: '',
    salaryTo: ''
  }


  constructor() {
    makeAutoObservable(this)
  }

  clearForm() {
    this.dataRequest = {
      name: "",
      companyName: '',
      companyLogo: '',
      image: "",
      position: '',
      address: '',
      gender: ITypeGender.MALE,
      workExperience: '',
      description: "",
      province: {
        id: ''
      },
      district: {
        id: ''
      },
      ward: {
        id: ''
      },
      expiredDate: '',
      occupation: {
        id: ''
      },
      type: IType.FULL_TIME,
      salaryFrom: '',
      salaryTo: ''
    }
  }


  async getProvinces() {
    const result = await recruitmentService.getProvinces()
    if (result.status === HttpStatusCode.OK) {
      this.listProvince = result.body
    }
  }

  async getDistricts(provinceId: any) {
    const result = await recruitmentService.getDistricts(provinceId)
    if (result.status === HttpStatusCode.OK) {
      this.listDistrict = result.body
    }
  }

  async getWards(districtId: any) {
    const result = await recruitmentService.getWards(districtId)
    if (result.status === HttpStatusCode.OK) {
      this.listWard = result.body
    }
  }


  async getRecruitment() {
    this.isLoading = true
    const result = await recruitmentService.getRecruitment()
    this.isLoading = false
    if(result.status === 200){
      this.listRecruitment = result.body.data
      this.totalPage = result.body.metadata.totalPage
    }
  }


  async getRecruitmentDetail(id: any, isDetail?: boolean) {
    this.isLoadingDetail = true
    const result = await recruitmentService.getDetail(id)
    this.isLoadingDetail = false
    if (result.status === 200) {
      this.dataRequest = result.body
      if(!result.body?.gender){
        this.dataRequest.gender = ITypeGender.MALE
      }
      if(isDetail) {
        if (this.dataRequest.province?.id) {
          await this.getDistricts(this.dataRequest.province.id)
        }
        if (this.dataRequest.district?.id) {
          await this.getWards(this.dataRequest.district.id)
        }
      }
    }
  }

  async addOrEdit() {

    let {id, name, description, gender, address, workExperience, province, district, type, ward, expiredDate, occupation, salaryFrom, salaryTo, companyName, position, companyLogo} = this.dataRequest

    let data: any = {
      name: name,
      companyName: companyName,
      position: position ? position : null,
      gender: gender,
      address: address,
      workExperience: workExperience,
      companyLogo: companyLogo,
      description: description,
      provinceId: province.id,
      districtId: district.id,
      wardId: ward.id,
      expiredDate: expiredDate,
      occupationId: occupation.id,
      type: type,
      salaryFrom: salaryFrom,
      salaryTo: salaryTo
    }


    if (!companyLogo) {
      toastUtil.warning('Logo công ty không được để trống!')
      return
    }

    if (!companyName) {
      toastUtil.warning('Tên công ty không được để trống!')
      return
    }

    if (!name) {
      toastUtil.warning('Vị trí tuyển dụng không được để trống!')
      return
    }
    if (!workExperience) {
      toastUtil.warning('Kinh nghiệm làm việc không được để trống!')
      return
    }

    if (!data.provinceId) {
      toastUtil.warning('Tỉnh thành phố không được để trống!')
      return
    }

    if (!data.districtId) {
      toastUtil.warning('Quận huyện không được để trống!')
      return
    }

    if (!data.wardId) {
      toastUtil.warning('Phường xã không được để trống!')
      return
    }

    if (!address) {
      toastUtil.warning('Tên đường, Tòa nhà, Số nhà không được để trống!')
      return
    }

    if (!data.occupationId) {
      toastUtil.warning('Ngành nghề không được để trống!')
      return
    }

    if ((data.salaryFrom && data.salaryTo) && (Number(data.salaryFrom) > Number(data.salaryTo))) {
      toastUtil.warning('Mức lương từ phải nhỏ hơn mức lương tối đa!')
      return
    }

    if (!data.expiredDate) {
      toastUtil.warning('Hạn nộp không được để trống!')
      return
    }

    if (!data.description) {
      toastUtil.warning('Mô tả không được để trống!')
      return
    }

    this.isLoadingBt = true
    let result: any

    if (id) {
      result = await recruitmentService.update(id, data)
    } else {
      result = await recruitmentService.add(data)
    }

    this.isLoadingBt = false
    if (result.status === 200) {
      toastUtil.success(`${!id ? 'Thêm' : 'Cập nhật'} tuyển dụng thành công!`)
      this.clearForm()
      recruitmentStore.redirect = true
    } else toastUtil.error(result.body.message ?? 'Đã có lỗi xảy ra')

  }

  async delete() {
    recruitmentStore.isLoadingBt = true
    const result = await recruitmentService.delete(recruitmentStore.id)
    recruitmentStore.isLoadingBt = false
    if (result.status === 200) {
      toastr.success('Xóa tuyển dụng thành công!')
      $('#close_delete').trigger('click')
      await recruitmentStore.getRecruitment()
    } else toastr.error(result.body.message ?? 'Đã có lỗi xảy ra')
  }


}

export const recruitmentStore = new RecruitmentStore()