import React, {useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import {shopStore} from './ShopStore'
import {Link} from 'react-router-dom'
import Loading from "../../../common/components/Loading";
import {getPathName, slug, sttPage, urlImage} from "../../../common/utils/Utils";
import Paginate from "../../../common/components/Paginate";
import NoContent from "../../../common/components/NoContent";


const ShopsWrapper = observer(() => {

    const handlePageClick = async (data: any) => {
        shopStore.page = data.selected;
        await shopStore.getShops();
    };

    const returnState = (state: any) => {
        switch (state) {
            case 'ACTIVE':
                return <div className="badge badge-light-primary">Đã duyệt</div>
            case 'INACTIVE':
                return <div className="badge badge-light-warning">Chờ duyệt</div>
            case 'REJECT':
                return <div className="badge badge-light-danger">Từ chối</div>
            case 'BLOCK':
                return <div className="badge badge-light-danger">Khóa</div>
        }
    }

    useEffect(() => {
        shopStore.getShops().then()
    }, [])


    return (
        <>
            <div className='d-flex align-items-center justify-content-between mb-8'>
                <h1 className='d-flex text-dark fw-bolder fs-3 align-items-center my-1'>Danh sách cửa hàng</h1>
                <Link to={'/store/add-shop'} className='btn btn-sm btn-primary' onClick={() => shopStore.clearForm()}>Tạo
                    mới</Link>
            </div>
            <div className="d-flex mb-5 align-items-center">
                <div className='d-flex align-items-center mr-2 position-relative'>
                    <i className='bi bi-search cursor-pointer fs-1x fs-3 position-absolute ms-4'
                       onClick={async () => shopStore.getShops()}/>
                    <input type='text' value={shopStore.name}
                           onChange={(e: any) => shopStore.name = e.currentTarget.value}
                           onKeyDown={async (e: any) => {
                               if (e.key === 'Enter') {
                                   shopStore.page = 0;
                                   await shopStore.getShops()
                               }
                           }} data-kt-ecommerce-order-filter='search'
                           className='form-control form-control-solid w-250px ps-12'
                           placeholder='Tìm kiếm cửa hàng'/>
                </div>
                <div className="w-100 mw-250px" style={{marginLeft: 16}}>
                    <select onChange={async (e: any) => {
                        shopStore.state = e.currentTarget.value;
                        shopStore.page = 0;
                        await shopStore.getShops()
                    }} className="form-select form-select-solid">
                        <option value="">Chọn trạng thái</option>
                        <option value="INACTIVE">Chờ duyệt</option>
                        <option value="ACTIVE">Đã duyệt</option>
                        <option value="REJECT">Từ chối</option>
                        <option value="BLOCK">Khóa</option>
                    </select>
                </div>
            </div>
            {shopStore.isLoading ? <Loading/> : <div className='table-responsive'>
                {shopStore.listShop && shopStore.listShop.length > 0 ?
                    <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
                        <thead>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                            <th>STT</th>
                            <th>Ảnh</th>
                            <th>Tên cửa hàng</th>
                            <th>Trạng thái</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {shopStore.listShop && shopStore.listShop.map((item: any, i: number) => {
                            return (
                                <tr key={i}>
                                    <td>{sttPage(shopStore.page, i)}</td>
                                    <td>
                                        <div className="symbol symbol-30px symbol-md-40px">
                                            <img src={urlImage(item.avatar)} alt=""/>
                                        </div>
                                    </td>
                                    <td>{item.name}</td>
                                    <td>{returnState(item.state)}</td>
                                    <td width="18%" className="text-end">
                                        {item.state == 'INACTIVE' && <>
                                            <button title="Duyệt shop"
                                                    onClick={() => shopStore.changeState(item.id, "ACTIVE")}
                                                    className="btn btn-icon btn-primary btn-sm"><i
                                                className='fas fa-circle-check'/></button>
                                            <span className='p-2'/>
                                            <button title="Từ chối duyệt"
                                                    onClick={() => shopStore.changeState(item.id, "REJECT")}
                                                    className="btn btn-icon btn-danger btn-sm"><i
                                                className='fas fa-circle-xmark'/></button>
                                            <span className='p-2'/>
                                        </>}
                                        <Link to={`/store/edit-shop/${slug(item.name)}/${item.id}`} title="Chỉnh sửa"
                                              className="btn btn-icon btn-warning btn-sm"><i
                                            className='fas fa-pen'/></Link>
                                        {item.state == 'ACTIVE' && <>
                                            <span className='p-2'/>
                                            <button title="Khóa shop" disabled={shopStore.isLoadingBt}
                                                    onClick={() => shopStore.changeState(item.id, "BLOCK")}
                                                    className="btn btn-icon btn-secondary btn-sm"><i
                                                className='fas fa-lock'/></button>
                                        </>}
                                        {item.state == 'BLOCK' && <>
                                            <span className='p-2'/>
                                            <button title="Mở khóa shop" disabled={shopStore.isLoadingBt}
                                                    onClick={() => shopStore.changeState(item.id, "ACTIVE")}
                                                    className="btn btn-icon btn-success btn-sm"><i
                                                className='fas fa-unlock'/></button>
                                        </>}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table> : <NoContent/>}
            </div>
            }

            <Paginate currentPage={shopStore.page} totalPage={shopStore.totalPage} callback={handlePageClick}/>

        </>
    )
})

export default ShopsWrapper