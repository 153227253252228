import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import Loading from '../../../common/components/Loading'
import NoContent from '../../../common/components/NoContent'
import Paginate from '../../../common/components/Paginate'
import {applyStore} from './ApplyStore'
import {dateUtils} from "../../../common/utils/DateUtils";
import {sttPage} from "../../../common/utils/Utils";


const ApplyPage = () => {

    const handlePageClick = async (data: any) => {
        applyStore.page = data.selected;
        await applyStore.getApply();
    };


    const returnStatus = (status: any) => {
        switch (status) {
            case "NOT_VIEWED":
                return <div className="badge badge-light-warning">Chưa xem</div>
            case "VIEWED":
                return <div className="badge badge-light-primary">Đã xem</div>
        }
    }

    useEffect(() => {
        applyStore.getApply()
    }, [])

    return (
        <>
            <div className='d-flex align-items-center justify-content-between mb-6'>
                <h1 className='d-flex text-dark fw-bolder fs-3 align-items-center my-1'>Ứng tuyển</h1>
            </div>
            <div id="kt_ecommerce_sales_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                <div className="d-flex align-items-center mb-4">
                    <div className='d-flex align-items-center position-relative'>
                        <i className='bi bi-search cursor-pointer fs-1x fs-3 position-absolute ms-4'
                           onClick={async () => {applyStore.page = 0; await applyStore.getApply()}}/>
                        <input type='text' value={applyStore.keyword}
                               onChange={(e: any) => applyStore.keyword = e.currentTarget.value}
                               onKeyDown={async (e: any) => {
                                   if (e.key === 'Enter') {
                                       applyStore.page = 0;
                                       await applyStore.getApply()
                                   }
                               }} data-kt-ecommerce-order-filter='search'
                               className='form-control form-control-solid w-250px ps-12'
                               placeholder='Tìm kiếm họ và tên, sdt, email'/>
                    </div>
                    <div className="w-100 mw-250px" style={{marginLeft: 16}}>
                        <select onChange={async (e: any) => {
                            applyStore.status = e.currentTarget.value;
                            applyStore.page = 0;
                            await applyStore.getApply()
                        }} className="form-select form-select-solid">
                            <option value="">Chọn trạng thái</option>
                            <option value="NOT_VIEWED">Chưa xem</option>
                            <option value="VIEWED">Đã xem</option>
                        </select>
                    </div>
                </div>
                {applyStore.isLoading ? <Loading/> : <div className='table-responsive'>
                    {applyStore.listApply && applyStore.listApply.length > 0 ?
                        <table
                            className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
                            <thead>
                            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                <th>STT</th>
                                <th>Họ và tên</th>
                                <th>Số điện thoại</th>
                                <th>Email</th>
                                <th>Hồ sơ CV</th>
                                <th>Trạng thái</th>
                                <th>Địa chỉ</th>
                                <th>Thời gian</th>
                                <th>Thao tác</th>
                            </tr>
                            </thead>
                            <tbody>
                            {applyStore.listApply.map((item: any, i: number) => {
                                return (
                                    <tr key={i}>
                                        <td>{sttPage(applyStore.page, i)}</td>
                                        <td>{item.fullName}</td>
                                        <td>{item.phone}</td>
                                        <td>{item.email}</td>
                                        <td>{item.cv && <a className="btn btn-sm btn-outline-primary" title="Hồ sơ CV"
                                                           href={process.env.REACT_APP_API_UPIMG + '/storage' + item.cv}
                                                           target="_blank">Xem CV</a>}</td>
                                        <td>{returnStatus(item.status)}</td>
                                        <td>{item.address}</td>
                                        <td>{dateUtils.formatDate(item.updatedAt)}</td>
                                        <td>
                                            {item.status === "NOT_VIEWED" &&
                                            <button onClick={() => applyStore.changeStatus(item, 'VIEWED')}
                                                    className="btn btn-sm btn-primary">Xem</button>}
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table> : <NoContent/>}
                    <Paginate callback={handlePageClick} totalPage={applyStore.totalPage}
                              currentPage={applyStore.page}/>
                </div>}

            </div>
        </>
    )
}


export default observer(ApplyPage);