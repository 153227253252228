import {deleteRequest, getRequest, IApiResponse, postRequest} from "../../../../common/helpers/RequestHelper";


class FooterService {

  public getFooter(): Promise<IApiResponse>{
    return getRequest(`/v1/config/footer`)
  }

  public getDetail(id: any): Promise<IApiResponse>{
    return getRequest(`/v1/config/footer/${id}`)
  }

  public addFooter(data: any): Promise<IApiResponse>{
    return postRequest(`/v1/config/footer`, data)
  }

  public updateFooter(id: any, data: any): Promise<IApiResponse>{
    return postRequest(`/v1/config/footer`, data)
  }

  public deleteFooter(id: any): Promise<IApiResponse>{
    return deleteRequest(`/v1/config/footer/${id}`, {id})
  }

  public generateUpload(fileName: any): Promise<IApiResponse> {
    return getRequest(`/v1/storage/generate-upload-url?fileName=${fileName}`)
  }

}

export const footerService = new FooterService()

