import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from '../../common/helpers/RequestHelper'
import {newsStore} from './NewsStore'
import {standardStore} from "../standard/StandardStore";


class NewsService {

  public getNews(): Promise<IApiResponse>{
    return getRequest(`/omfarm/v1/post?page=${newsStore.page}&size=10&collection=admin&type=NEW${newsStore.keyword ? `&keyword=${newsStore.keyword}` : ''}`)
  }


  public getStandard(): Promise<IApiResponse>{
    return getRequest(`/omfarm/v1/post?page=${standardStore.page}&size=10&collection=admin&type=${standardStore.state}${standardStore.keyword ? `&keyword=${standardStore.keyword}` : ''}`)
  }

  public getDetail(id: any): Promise<IApiResponse>{
    return getRequest(`/omfarm/v1/post/${id}`)
  }

  public changeStatus(data: any): Promise<IApiResponse>{
    return postRequest(`/omfarm/v1/post/change_status`, data)
  }

  public add(data: any): Promise<IApiResponse>{
    return postRequest(`/omfarm/v1/post`, data)
  }

  public update(id: any, data: any): Promise<IApiResponse>{
    return putRequest(`/omfarm/v1/post/${id}`, data)
  }

  public delete(id: any): Promise<IApiResponse>{
    return deleteRequest(`/omfarm/v1/post`, [id])
  }

}

export const newsService = new NewsService()

