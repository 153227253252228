import {makeAutoObservable} from 'mobx'
import $ from 'jquery'
import {shopService} from './ShopService'
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../common/utils/ToastUtil";
import {recruitmentStore} from "../../recruitment/RecruitmentStore";
import {postRequest} from "../../../common/helpers/RequestHelper";
import {ScrollTopComponent} from "../../../../_metronic/assets/ts/components";

interface IFactory {
    name: string,
    image: any
}

class ShopStore {
    state: any = ''
    name: any = ''
    keyword: any = ''
    page: any = 0
    metadata: { page: number, totalPage: number } = {page: 0, totalPage: 0}
    totalPage: any = 0
    listShop: any[] = []
    listUser: any[] = []
    listType: any[] = [
        {
            name: "Hộ gia đình/Cá nhân",
            id: 'PERSON'
        },
        {
            name: "Cửa hàng/Shop",
            id: 'SHOP'
        },
        {
            name: "Doanh nghiệp",
            id: 'ENTERPRISES'
        },
        {
            name: "Hợp tác xã",
            id: 'COOPERATIVE'
        },
        {
            name: "Bưu cục",
            id: 'POST_OFFICES'
        }
    ]
    dataFactory: IFactory = {
        name: '',
        image: ''
    }
    errors: any = {
        name: '',
        userId: '',
        description: '',
        avatar: '',
        banner: '',
        address: '',
        province: '',
        district: '',
        ward: '',
        identityCard: "",
        taxNumber: "",
        shopType: '',
    }
    dataRequest: any = {
        id: '',
        name: '',
        avatar: '',
        user: {
            id: '',
            name: ''
        },
        identityCard: "",
        taxNumber: "",
        shopType: '',
        banner: '',
        address: '',
        province: {
            id: ''
        },
        district: {
            id: ''
        },
        ward: {
            id: ''
        },
        shopCompany: {
            description: "",
            brand: {
                aboutUs: "",
                vision: "",
                mission: ""
            },
            contact: {
                representer: "",
                phone: "",
                email: "",
                address: "",
                website: ""
            },
            productionCapacity: {
                factoryArea: '',
                annual_output: '',
                technology: '',
                partner: '',
                certificate: '',
                distribution_market: '',
                file_attack: '',
            },
            unitInformation: {
                representer: "",
                phone: "",
                email: "",
                address: "",
                website: "",
                business: "",
                mainProduct: "",
                revenue: "",
                numberOfEmployees: "",
                foundedYear: ""
            },
            certification: [],
            factory: []
        }
    }
    isLoading: boolean = false
    redirect: boolean = false
    isLoadingBt: boolean = false


    constructor() {
        makeAutoObservable(this)
    }

    clearFormFactory() {
        this.dataFactory = {
            name: '',
            image: ''
        }
    }

    clearError() {
        this.errors = {
            name: '',
            userId: '',
            avatar: '',
            banner: '',
            address: '',
            province: '',
            district: '',
            ward: '',
        }
    }

    clearForm() {
        this.dataRequest = {
            id: '',
            name: '',
            user: {
                id: '',
                name: ''
            },
            identityCard: "",
            taxNumber: "",
            shopType: '',
            avatar: '',
            banner: '',
            province: {
                id: ''
            },
            district: {
                id: ''
            },
            ward: {
                id: ''
            },
            shopCompany: {
                description: "",
                brand: {
                    aboutUs: "",
                    vision: "",
                    mission: ""
                },
                contact: {
                    representer: "",
                    phone: "",
                    email: "",
                    address: "",
                    website: ""
                },
                productionCapacity: {
                    factoryArea: '',
                    annualOutput: '',
                    technology: '',
                    partner: '',
                    certificate: '',
                    distributionMarket: '',
                    fileAttack: '',
                },
                unitInformation: {
                    representer: "",
                    phone: "",
                    email: "",
                    address: "",
                    website: "",
                    business: "",
                    mainProduct: "",
                    revenue: "",
                    numberOfEmployees: "",
                    foundedYear: ""
                },
                certification: [],
                factory: []
            }
        }
    }

    async changeImg(value: any) {
        shopStore.dataFactory.image = value
    }


    async getShops(size?: any) {
        this.isLoading = true
        const result = await shopService.getShops(size)
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listShop = result.body.data
            this.totalPage = result.body.metadata.totalPage
        }
    }

    async getUsers() {
        this.isLoading = true
        const result = await shopService.getUsers()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listUser = result.body.data
            this.metadata = result.body.metadata
        }
    }


    async getShopDetail(id: any) {
        this.isLoading = true
        const result = await shopService.getDetailShop(id)
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            let {shopCompany, province, district, ward} = result.body
            this.dataRequest = {
                id: result.body.id,
                name: result.body.name,
                user: {
                    id: result.body.userId,
                    name: result.body.user?.name,
                    phone: result.body.user?.phone
                },
                identityCard: result.body.identityCard,
                taxNumber: result.body.taxNumber,
                shopType: result.body.shopType,
                banner: result.body.banner,
                avatar: result.body.avatar,
                address: result.body.address,
                province: {
                    id: province?.id
                },
                district: {
                    id: district?.id
                },
                ward: {
                    id: ward?.id
                },
                shopCompany: {
                    description: shopCompany?.description,
                    contact: {
                        representer: shopCompany?.contact?.representer,
                        phone: shopCompany?.contact?.phone,
                        email: shopCompany?.contact?.email,
                        address: shopCompany?.contact?.address,
                        website: shopCompany?.contact?.website
                    },
                    unitInformation: {
                        representer: shopCompany?.unitInformation?.representer,
                        mainProduct: shopCompany?.unitInformation?.mainProduct,
                        phone: result.body?.phone,
                        revenue: shopCompany?.unitInformation?.revenue,
                        email: shopCompany?.unitInformation?.email,
                        business: shopCompany?.unitInformation?.business,
                        address: shopCompany?.unitInformation?.address,
                        numberOfEmployees: shopCompany?.unitInformation?.numberOfEmployees,
                        website: shopCompany?.unitInformation?.website,
                        certificate: shopCompany?.unitInformation?.certificate,
                        diploma: shopCompany?.unitInformation?.diploma,
                        foundedYear: shopCompany?.unitInformation?.foundedYear,
                    },
                    productionCapacity: {
                        factoryArea: shopCompany?.productionCapacity?.factoryArea,
                        annualOutput: shopCompany?.productionCapacity?.annualOutput,
                        technology: shopCompany?.productionCapacity?.technology,
                        partner: shopCompany?.productionCapacity?.partner,
                        certificate: shopCompany?.productionCapacity?.certificate,
                        distributionMarket: shopCompany?.productionCapacity?.distributionMarket,
                        fileAttack: shopCompany?.productionCapacity?.fileAttack,
                    },
                    brand: {
                        aboutUs: shopCompany?.brand?.aboutUs,
                        vision: shopCompany?.brand?.vision,
                        mission: shopCompany?.brand?.mission
                    },
                    certification: shopCompany?.certification ?? [],
                    factory: shopCompany?.factory ?? [],
                }
            }
            if (result.body.province.id) {
                await recruitmentStore.getDistricts(result.body.province.id)
            }
            if (result.body.district.id) {
                await recruitmentStore.getWards(result.body.district.id)
            }
        }
    }

    async changeAvatar(value: any) {
        shopStore.dataRequest.avatar = value
    }

    async changeBanner(value: any) {
        shopStore.dataRequest.banner = value
    }


    async uploadImgCertification(value: any) {
        shopStore.dataRequest.shopCompany.certification.push(value)
    }

    async removeImgCertification(i: any) {
        shopStore.dataRequest.shopCompany.certification.splice(i, 1)
    }

    async changeProvince(id: any) {
        this.dataRequest.province['id'] = id
        this.dataRequest.district['id'] = ''
        this.dataRequest.ward['id'] = ''
        await recruitmentStore.getDistricts(id)
    }

    async changeDistrict(id: any) {
        this.dataRequest.district['id'] = id
        this.dataRequest.ward['id'] = ''
        await recruitmentStore.getWards(id)
    }


    async add_update(type: string) {
        let {id, name, user, identityCard, taxNumber, shopType,shopCompany, avatar, banner, province, district, ward, address} = this.dataRequest
        this.clearError()
        let data: any = {
            name: name,
            avatar: avatar,
            banner: banner,
            userId: user.id,
            identityCard: identityCard,
            taxNumber: taxNumber,
            shopType: shopType,
            description: shopCompany.description,
            provinceId: province.id,
            districtId: district.id,
            wardId: ward.id,
            address: address,
            shopCompany: shopCompany
        }

        if (!avatar) {
            this.errors.avatar = 'Ảnh cửa hàng không được để trống!'
        }
        if (!banner) {
            this.errors.banner = 'Banner cửa hàng không được để trống!'
        }
        if (!name) {
            this.errors.name = 'Tên cửa hàng không được để trống!'
        }
        if (!user.id) {
            this.errors.userId = 'Chủ cửa hàng không được để trống!'
        }
        if (!identityCard) {
            this.errors.identityCard = 'CMND/CCCD không được để trống!'
        }
        if (!taxNumber) {
            this.errors.taxNumber = 'Mã số thuế không được để trống!'
        }
        if (!shopType) {
            this.errors.shopType = 'Kiểu người bán không được để trống!'
        }
        if (!province.id) {
            this.errors.province = 'Tỉnh thành phố không được để trống!'
        }
        if (!district.id) {
            this.errors.district = 'Quận huyện không được để trống!'
        }
        if (!ward.id) {
            this.errors.ward = 'Phường xã không được để trống!'
        }
        if (!address) {
            this.errors.address = 'Địa chỉ cụ thể không được để trống!'
        }

        if (!taxNumber || !shopType || !identityCard || !avatar || !banner || !name || !address || !province.id || !district.id || !ward.id) {
            toastUtil.warning('Vui lòng nhập đầy đủ thông tin'!)
            ScrollTopComponent.goTop()
            return
        }

        this.isLoadingBt = true
        const result = type == "add" ? await shopService.addShop(data) : await shopService.updateShop(id, data)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`${type == "add" ? 'Tạo' : 'Cập nhật'} shop thành công!`);
            shopStore.redirect = true
            this.clearForm()
        } else {
            if (typeof result.body?.message === "string") {
                toastr.error(result.body.message);
            } else if (typeof result.body?.message === "object") {
                this.errors = {
                    name: result.body.message?.name,
                    avatar: result.body.message?.avatar,
                    banner: result.body.message?.banner,
                    description: result.body.message?.description
                }
            } else {
                toastr.error("Đã có lỗi xảy ra!");
            }
        }
    }


    async addFactory() {

        let data = {
            name: this.dataFactory.name,
            image: this.dataFactory.image
        }
        if (!data.image) {
            toastUtil.warning('Vui lòng chọn ảnh')
            return
        }

        if (!data.name) {
            toastUtil.warning('Tên cơ sở không được để trống')
            return
        }

        if (!this.dataRequest.shopCompany.factory) {
            this.dataRequest.shopCompany.factory = []
        }

        this.dataRequest.shopCompany.factory.push(data)
        $('#close_add_edit_factory').trigger('click')
        this.clearFormFactory()
    }


    returnMessage(state: any) {
        switch (state) {
            case "BLOCK":
                return "Khóa"
            case "ACTIVE":
                return "Duyệt"
            case "REJECT":
                return "Từ chối"
        }
    }

    async changeState(id: any, state: any) {
        const result = await postRequest(`/v1/shops/${id}/state`, {state: state})
        if (result.status === 200) {
            toastUtil.success(`${this.returnMessage(state)} shop thành công!`)
            this.listShop.map((item) => {
                if (item.id === id) {
                    item.state = state
                }
            })
        } else toastUtil.error(result.body.message)
    }


}

export const shopStore = new ShopStore()