import React, {useEffect} from 'react'
import {bannerStore} from './BannerStore'
import {observer} from 'mobx-react-lite'
import AddOrUpdate from './components/AddOrUpdate'
import Loading from "../../../../common/components/Loading";
import NoContent from "../../../../common/components/NoContent";
import PopupConfirm from "../../../../common/components/PopupConfirm";
import {urlImage} from "../../../../common/utils/Utils";

const BannerWrapper = observer(() => {


    useEffect(() => {
        bannerStore.getBanner().then()
    }, [])

    return (
        <>
            <div className='d-flex align-items-center justify-content-between mb-10'>
                <h1 className='d-flex text-dark fw-bolder fs-3 align-items-center my-1'>Danh sách banner</h1>
                <a href='#' className='btn btn-sm btn-primary' onClick={() => bannerStore.clearForm()}
                   data-bs-toggle='modal' data-bs-target='#add_or_update'>Tạo mới</a>
            </div>
            {bannerStore.isLoading ? <Loading/> : <div className='table-responsive'>
                {bannerStore.listBanner && bannerStore.listBanner.length > 0 ?
                    <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
                        <thead>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                            <th>STT</th>
                            <th>Vị trí</th>
                            <th>Ảnh</th>
                            <th>Liên kết đến</th>
                            <th>Nội dung</th>
                            <th>Loại</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {bannerStore.listBanner.map((item: any, i: number) => {
                            return (
                                <tr key={i}>
                                    <td>{i+1}</td>
                                    <td>{item.sort}</td>
                                    <td>
                                        <div className="symbol symbol-40px symbol-md-40px">
                                            <img src={urlImage(item.image)} style={{objectFit: 'cover'}} alt=""/>
                                        </div>
                                    </td>
                                    <td>{item.linkTo}</td>
                                    <td>{item.content}</td>
                                    <td>{item.type}</td>
                                    <td width="10%">
                                        <a href="#" className="btn btn-icon btn-warning btn-sm"
                                           onClick={() => bannerStore.getBannerDetail(item)} data-bs-toggle='modal'
                                           data-bs-target='#add_or_update'><i className="fas fa-pen"/></a>
                                        <span className="p-2"/>
                                        <a href="#" className="btn btn-icon btn-danger btn-sm" data-bs-toggle='modal'
                                           data-bs-target='#popup_confirm' onClick={() => bannerStore.id = item.id}><i
                                            className="fas fa-trash-alt"/></a>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table> : <NoContent/>}
                <AddOrUpdate/>
                <PopupConfirm action={bannerStore.delete} isLoading={bannerStore.isLoadingBt}/>
            </div>}

        </>
    )
})

export default BannerWrapper