import React, {useEffect} from 'react'
import {categoryStore} from '../CategoryStore'
import {observer} from 'mobx-react'
import {getIDParameter, getUrlParameter, urlImage} from '../../../common/utils/Utils'
import TextEditor from '../../../common/components/TextEditor'
import Loading from '../../../common/components/Loading'
import {TreeSelect} from 'antd'
import {Link, Navigate} from 'react-router-dom'
import {chooseImgStore} from "../../../common/components/modalChooseImg/ChooseImgStore";
import UploadChooseImg from "../../../common/components/modalChooseImg/UploadChooseImg";


const AddEditCate = () => {

  const changeImg = (value: any) => {
    categoryStore.dataRequest.coverImg = value
  }

  const onChange = (newValue: any) => {
    categoryStore.dataRequest.parentId = newValue
  }


  useEffect(() => {
    let id = getIDParameter()
    categoryStore.getCateParent().then()
    categoryStore.type = getUrlParameter('type') ?? 'add'
    if (id) {
      categoryStore.getCateDetail(id).then()
    }
    chooseImgStore.getListImg('farmorg').then()
    return (() => {
      categoryStore.dataRequest.type = ''
      categoryStore.redirect = false
    })
  }, [getIDParameter()])

  if (categoryStore.redirect) {
    return <Navigate to={'/category'} />
  } else return (
    <div id='kt_ecommerce_sales_table_wrapper' className='dataTables_wrapper dt-bootstrap4 no-footer'>
      <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 mb-6 pt-1'>
        <li className='breadcrumb-item'>
          <Link to='/category' className='text-primary'>Danh mục</Link>
        </li>
        <li className='breadcrumb-item'>
          <span className='bullet bg-gray-400 w-5px h-2px' />
        </li>

        <li className='breadcrumb-item text-muted'>
          {categoryStore.type == 'add' ? 'Tạo danh mục' : categoryStore.dataRequest.name}
        </li>
      </ul>

      <div className='d-flex align-items-center justify-content-between mb-8'>
        <h1
          className='d-flex text-dark fw-bolder fs-3 align-items-center my-1'>{categoryStore.type == 'add' ? 'Tạo' : 'Cập nhật'} danh
          mục</h1>
      </div>

      <UploadChooseImg service="farmorg" changeImg={changeImg}/>

      {categoryStore.isLoadingDetail ? <Loading /> : <div className='form'>
        <div className='mb-5'>
          <label className='form-label w-100'>Ảnh banner (1500x250) <span className="text-danger">*</span></label>
          <div className='upLoadImageCategory w-100'>
            {categoryStore.dataRequest.coverImg ?
              <img src={urlImage(categoryStore.dataRequest.coverImg)} alt='' /> :
              <div className='no_img d-flex align-items-center justify-content-center'><i
                className='far fa-image' /></div>}
            <button className="btn change-avatar pl-0 pt-5" data-bs-toggle='modal' data-bs-target='#modal_upload_choose_img'>
              Tải banner
            </button>
          </div>
        </div>
        <div className='mb-5'>
          <label className='form-label'>Tên danh mục <span className='text-danger'>*</span></label>
          <input type='text' className='form-control' value={categoryStore.dataRequest.name}
                 onChange={(e: any) => categoryStore.dataRequest.name = e.currentTarget.value}
                 placeholder='Nhập tên' />
        </div>

        <div className='mb-5'>
          <label className='form-label'>Danh mục cha</label>
          <TreeSelect
            style={{width: '100%'}}
            value={categoryStore.dataRequest.parentId}
            dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
            treeData={categoryStore.listCateParent}
            placeholder='Chọn danh mục'
            treeDefaultExpandAll
            onChange={onChange}
          />

        </div>
        <div className='mb-5'>
          <label className='form-label'>Mô tả</label>
          <TextEditor isChooseImg={true} height={500} content={categoryStore.dataRequest.description}
                      onChange={(e: any) => categoryStore.dataRequest.description = e} />
        </div>
      </div>}
      <div className='action d-flex justify-content-end py-6 px-9'>
        <button type='submit' className='btn btn-primary' onClick={() => categoryStore.addOrEdit()}
                disabled={categoryStore.isLoadingBt}>
          {categoryStore.isLoadingBt ? (
            <span className='indicator-progress' style={{display: 'block'}}>
                  Vui lòng chờ...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </span>
          ) : 'Lưu'}
        </button>
      </div>
    </div>
  )
}

export default observer(AddEditCate)
