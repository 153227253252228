import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import Loading from '../../common/components/Loading'
import NoContent from '../../common/components/NoContent'
import Paginate from '../../common/components/Paginate'
import {slug, sttPage, urlImage} from '../../common/utils/Utils'
import {Link} from 'react-router-dom'
import {blogStore} from './BlogStore'
import {TreeSelect} from 'antd'
import {categoryStore} from '../categories/CategoryStore'
import DeleteBlog from './components/DeleteBlog'
import {dateUtils} from "../../common/utils/DateUtils";


const BlogPage = () => {

    const handlePageClick = async (data: any) => {
        blogStore.page = data.selected;
        await blogStore.getBlog();
    };

    const onChange = (newValue: any) => {
        blogStore.blogCategoryId = newValue;
        blogStore.getBlog().then(r => {
        })
    }


    useEffect(() => {
        blogStore.getBlog().then()
        categoryStore.getCateParent().then()
    }, [])

    return (
        <>
            <div className='d-flex align-items-center justify-content-between mb-6'>
                <h1 className='d-flex text-dark fw-bolder fs-3 align-items-center my-1'>Blog</h1>
                <Link to={'/add-blog?type=add'} onClick={() => blogStore.clearForm()}>
                    <button className='btn btn-sm btn-primary'>Tạo mới</button>
                </Link>
            </div>

            <div className="d-flex align-items-center mb-6">
                <div className='d-flex align-items-center mr-2 position-relative'>
                    <i className='bi bi-search cursor-pointer fs-1x fs-3 position-absolute ms-4'
                       onClick={async () => blogStore.getBlog()}/>
                    <input type='text' value={blogStore.keyword}
                           onChange={(e: any) => blogStore.keyword = e.currentTarget.value}
                           onKeyDown={async (e: any) => {
                               if (e.key === 'Enter') {
                                   await blogStore.getBlog()
                               }
                           }} data-kt-ecommerce-order-filter='search'
                           className='form-control form-control-solid w-250px ps-12'
                           placeholder='Tìm kiếm theo tiêu đề'/>
                </div>
                <TreeSelect
                    style={{width: '240px', height: '42px', marginLeft: 16}}
                    value={blogStore.blogCategoryId}
                    dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                    treeData={categoryStore.listCateParent}
                    placeholder='Tìm kiếm theo danh mục'
                    treeDefaultExpandAll
                    onChange={onChange}
                />
            </div>

            <div id="kt_ecommerce_sales_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">

                {blogStore.isLoading ? <Loading/> : <div className='table-responsive'>
                    {blogStore.listBlog && blogStore.listBlog.length > 0 ?
                        <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
                            <thead>
                            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                <th>STT</th>
                                <th>Ảnh</th>
                                <th>Tiêu đề</th>
                                <th>Danh mục</th>
                                <th>Thời gian cập nhật</th>
                                <th>Trạng thái</th>
                                <th className="text-center">Thao tác</th>
                            </tr>
                            </thead>
                            <tbody>
                            {blogStore.listBlog.map((item: any, i: number) => {
                                return (
                                    <tr key={i}>
                                        <td>{sttPage(blogStore.page, i)}</td>
                                        <td>
                                            <div className="symbol symbol-30px symbol-md-40px">
                                                {item.introduce?.image && <img src={urlImage(item.introduce.image)} alt=""/>}
                                            </div>
                                        </td>
                                        <td>{item.title}</td>
                                        <td>{item.blogCategory?.name}</td>
                                        <td>{dateUtils.formatDate(item.updatedAt)}</td>
                                        <td>{item.status == 'PUBLIC' ? 'Hiển thị' : 'Ẩn'}</td>
                                        <td width="15%" className="text-center">
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <i title={item.status == 'PUBLIC' ? 'Ẩn Blog' : 'Hiển thị Blog'}
                                                   onClick={() => blogStore.changeStatus(item)}
                                                   className={`fas cursor-pointer fa-toggle-${item.status === "PUBLIC" ? 'on text-primary' : 'off'}`}
                                                   style={{fontSize: 32}}/>
                                                <span className="p-2"/>
                                                <Link to={`/update-blog/${slug(item.title)}/${item.id}?type=edit`}
                                                      title="Chỉnh sửa" className="btn btn-icon btn-warning btn-sm"><i
                                                    className="fas fa-pen"/></Link>
                                                <span className="p-2"/>
                                                <a href="#" title="Xóa" className="btn btn-icon btn-danger btn-sm"
                                                   data-bs-toggle='modal' data-bs-target='#delete_blog'
                                                   onClick={() => blogStore.id = item.id}><i
                                                    className="fas fa-trash-alt"/></a>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table> : <NoContent/>}
                    <Paginate callback={handlePageClick} totalPage={blogStore.totalPage} currentPage={blogStore.page}/>
                </div>}
            </div>
            <DeleteBlog/>
        </>
    )
}

export default observer(BlogPage)