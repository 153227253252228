import React, {useEffect} from 'react'
import {productStore} from '../ProductStore'
import {categoryStore} from '../../categories/CategoryStore'
import {observer} from 'mobx-react-lite'
import {shopStore} from '../../shops/ShopStore'
import {numberUtil} from "../../../../common/utils/NumberUtil";
import UploadMultiImg from "../../../../common/components/UploadMultiImg";
import {Link, Navigate} from "react-router-dom";
import TextEditor from "../../../../common/components/TextEditor";
import Input from "../../../../common/components/form/Input";
import Select from "../../../../common/components/form/Select";
import {slug} from "../../../../common/utils/Utils";

const AddProduct = () => {


    useEffect(() => {
        shopStore.getShops(1000).then()
        categoryStore.searchLevel = 2
        categoryStore.getCate(1000).then()
        productStore.getUnit().then()
        return () => {
            categoryStore.searchLevel = ''
            productStore.redirect = false
            productStore.clearForm()
        }
    }, [])


    if (productStore.redirect) {
        return <Navigate to={'/store/products'}/>
    } else return (
        <>
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 mb-6 pt-1">
                <li className="breadcrumb-item">
                    <Link to="/store/products" className="text-primary">Sản phẩm</Link>
                </li>
                <li className="breadcrumb-item">
                    <span className="bullet bg-gray-400 w-5px h-2px"/>
                </li>

                <li className="breadcrumb-item text-muted">
                    Tạo sản phẩm
                </li>
            </ul>
            <div className='d-flex align-items-center justify-content-between mb-8'>
                <h1 className='d-flex text-dark fw-bolder fs-3 align-items-center my-1'>Tạo mới sản phẩm</h1>
            </div>
            <div className='form'>
                <div className='row'>
                    <div className='col-6'>
                        <div className='mb-5'>
                            <div className="mb-5">
                                <label className='form-label'>Chọn cửa hàng <span
                                    className="text-danger">*</span></label>
                                <Select isError={productStore.errors.shopId} value={productStore.shopId}
                                        options={shopStore.listShop} placeholder="Chọn cửa hàng"
                                        onChange={(e: any) => productStore.shopId = e.currentTarget.value}/>
                            </div>

                            <div>
                                <label className='form-label'>Tên sản phẩm <span
                                    className="text-danger">*</span></label>
                                <Input value={productStore.dataRequest.name} isError={productStore.errors.name}
                                       onChange={(e: any) => productStore.dataRequest.name = e.currentTarget.value}
                                       placeholder='Nhập tên sản phẩm'/>
                            </div>
                        </div>
                        <div className="mb-5 row">
                            <div className='col-6'>
                                <label className='form-label'>Danh mục <span className="text-danger">*</span></label>
                                <Select value={productStore.dataRequest.categoryId}
                                        isError={productStore.errors.categoryId} options={categoryStore.listCate}
                                        placeholder="Chọn danh mục"
                                        onChange={(e: any) => productStore.dataRequest.categoryId = e.currentTarget.value}/>
                            </div>
                            <div className='col-6'>
                                <label className='form-label'>Đơn vị <span className="text-danger">*</span></label>
                                <Select value={productStore.dataRequest.unitId} isError={productStore.errors.unitId}
                                        options={productStore.listUnit} placeholder="Chọn đơn vị"
                                        onChange={(e: any) => productStore.dataRequest.unitId = e.currentTarget.value}/>
                            </div>
                        </div>
                        <div className='mb-5 row'>
                            <div className='col-6'>
                                <label className='form-label'>Số lượng <span className="text-danger">*</span></label>
                                <Input value={productStore.dataRequest.quantity} isError={productStore.errors.quantity}
                                       onChange={(e: any) => productStore.dataRequest.quantity = numberUtil.regexNumber(e)}
                                       placeholder='Nhập số lượng'/>
                            </div>
                            <div className='col-6'>
                                <label className='form-label'>Cân nặng (Gam) <span
                                    className="text-danger">*</span></label>
                                <Input value={productStore.dataRequest.weight} isError={productStore.errors.weight}
                                       onChange={(e: any) => productStore.dataRequest.weight = numberUtil.regexNumber(e)}
                                       placeholder='Nhập cân nặng'/>
                            </div>
                        </div>
                        <div className='mb-5 row'>
                            <div className='col-6'>
                                <label className='form-label'>Giá gốc <span className="text-danger">*</span></label>
                                <Input value={productStore.dataRequest.price} isError={productStore.errors.price}
                                       onChange={(e: any) => productStore.dataRequest.price = numberUtil.regexNumber(e)}
                                       placeholder='Nhập giá gốc'/>
                            </div>
                            <div className='col-6'>
                                <label className='form-label'>Giá bán <span className="text-danger">*</span></label>
                                <Input value={productStore.dataRequest.salePrice}
                                       isError={productStore.errors.salePrice}
                                       onChange={(e: any) => productStore.dataRequest.salePrice = numberUtil.regexNumber(e)}
                                       placeholder='Nhập giá bán'/>
                            </div>
                            {productStore.errors.isValidPrice &&
                            <p className="error">{productStore.errors.isValidPrice}</p>}
                        </div>
                        <div className='mb-5'>
                            <label className='form-label'>Mô tả sản phẩm <span className="text-danger">*</span></label>
                            <TextEditor content={productStore.dataRequest.description}
                                        onChange={(e: any) => productStore.dataRequest.description = e}/>

                            {productStore.errors.description && !productStore.dataRequest.description &&
                            <p className="error">{productStore.errors.description}</p>}

                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='mb-5'>
                            <label className='form-label'>Ảnh sản phẩm <span className="text-danger">*</span></label>
                            <UploadMultiImg listImg={productStore.dataRequest.media} changeImg={productStore.uploadImg}
                                            removeImg={productStore.removeImg}/>
                            {productStore.errors.media && productStore.dataRequest.media && productStore.dataRequest.media.length < 1 &&
                            <p className="error">{productStore.errors.media}</p>}
                        </div>
                        <div className='mb-5'>
                            <label className='form-label'>Kích thước đóng gói <span
                                className="text-danger">*</span></label>
                            <div className='row'>
                                <div className='col-4'>
                                    <input type='text' className='form-control'
                                           value={productStore.dataRequest.packingSize[0]}
                                           onChange={(e: any) => productStore.dataRequest.packingSize[0] = numberUtil.regexNumber(e)}
                                           placeholder='Chiều dài'/>
                                </div>
                                <div className='col-4'>
                                    <input type='text' className='form-control'
                                           value={productStore.dataRequest.packingSize[1]}
                                           onChange={(e: any) => productStore.dataRequest.packingSize[1] = numberUtil.regexNumber(e)}
                                           placeholder='Chiều rộng'/>
                                </div>
                                <div className='col-4'>
                                    <input type='text' className='form-control'
                                           value={productStore.dataRequest.packingSize[2]}
                                           onChange={(e: any) => productStore.dataRequest.packingSize[2] = numberUtil.regexNumber(e)}
                                           placeholder='Chiều cao'/>
                                </div>
                            </div>
                            {productStore.errors.packingSize &&
                            <p className="error">{productStore.errors.packingSize}</p>}
                        </div>
                        <div className='mb-5'>
                            <label className='form-label'>Sku</label>
                            <input type='text' className='form-control' value={productStore.dataRequest.sku}
                                   onChange={(e: any) => productStore.dataRequest.sku = e.currentTarget.value}
                                   placeholder='Nhập Sku'/>
                        </div>
                        <div className='mb-5'>
                            <label className='form-label'>Link truy xuất nguồn gốc (Vd: https://omfarm.com.vn/truy-xuat/{slug(productStore.dataRequest.name)})</label>
                            <input type='text' className='form-control'
                                   value={productStore.dataRequest.originSourceLink}
                                   onChange={(e: any) => productStore.dataRequest.originSourceLink = e.currentTarget.value}
                                   placeholder='Nhập link nguồn gốc'/>
                            {productStore.errors.originSourceLink &&
                            <p className="error">{productStore.errors.originSourceLink}</p>}
                        </div>
                        <div className='mb-5'>
                            <div className="d-flex align-items-center justify-content-between">
                                <label className='form-label'>Thiết lập giá vận chuyển <span
                                    className="text-danger">*</span></label>
                                <button className="btn btn-sm btn-primary"
                                        onClick={() => productStore.addTransportFees()}>+ Thêm
                                </button>
                            </div>
                            {productStore.dataRequest.transportFees && productStore.dataRequest.transportFees.map((item: any, i: number) => {
                                return (
                                    <div key={i} className="shipping position-relative mb-4 pb-4">
                                        <div className="mb-4 row">
                                            <div
                                                className="d-flex mt-3 mb-2 justify-content-between align-items-center">
                                                <label className='form-label'>Cân nặng (gram)</label>
                                                {i > 0 && <i className="fas text-danger fa-trash-alt cursor-pointer"
                                                             style={{fontSize: 16}}
                                                             onClick={() => productStore.dataRequest.transportFees.splice(i, 1)}/>}
                                            </div>
                                            <div className="col-6">
                                                <input type='text'
                                                       className={`form-control ${!item.fromWeight && productStore.errors.transportFees ? 'border_error' : ''}`}
                                                       value={item.fromWeight}
                                                       onChange={(e: any) => item.fromWeight = numberUtil.regexNumber(e)}
                                                       placeholder='Từ'/>
                                            </div>
                                            <div className="col-6">
                                                <input type='text'
                                                       className={`form-control ${!item.toWeight && productStore.errors.transportFees ? 'border_error' : ''}`}
                                                       value={item.toWeight}
                                                       onChange={(e: any) => item.toWeight = numberUtil.regexNumber(e)}
                                                       placeholder='Đến'/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <label className='form-label'>Giá ship nội thành</label>
                                                <input type='text'
                                                       className={`form-control ${!item.priceUrban && productStore.errors.transportFees ? 'border_error' : ''}`}
                                                       value={item.priceUrban}
                                                       onChange={(e: any) => item.priceUrban = numberUtil.regexNumber(e)}
                                                       placeholder='Nhập giá ship nội thành'/>
                                            </div>
                                            <div className="col-6">
                                                <label className='form-label'>Giá ship ngoại thành</label>
                                                <input type='text'
                                                       className={`form-control ${!item.priceSuburban && productStore.errors.transportFees ? 'border_error' : ''}`}
                                                       value={item.priceSuburban}
                                                       onChange={(e: any) => item.priceSuburban = numberUtil.regexNumber(e)}
                                                       placeholder='Nhập giá ship ngoại thành'/>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {productStore.errors.transportFees || productStore.dataRequest.transportFees && productStore.dataRequest.transportFees.length < 1 &&
                            <p className="error">{productStore.errors.transportFees}</p>}
                        </div>
                    </div>

                </div>
                <div className='d-flex mt-5 align-items-center justify-content-end'>
                    <button type='submit' className='btn btn-primary' onClick={() => productStore.addProduct()}
                            disabled={productStore.isLoadingBt}>
                        {productStore.isLoadingBt ? (
                            <span className='indicator-progress' style={{display: 'block'}}>
                  Vui lòng chờ...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'/>
                </span>
                        ) : 'Lưu'}
                    </button>
                </div>
            </div>
        </>
    )

}

export default observer(AddProduct)