import React, {Component} from 'react';


class NoContent extends Component<{message?: any}, any> {
    render() {
        return (
            <h6 className="w-100 border d-flex align-items-center justify-content-center h-250px">{this.props.message ? this.props.message : 'Chưa có dữ liệu'}</h6>
        )
    }
}

export default NoContent;
