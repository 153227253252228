import {observer} from 'mobx-react'
import React, {useEffect} from 'react'
import Loading from '../../../common/components/Loading'
import NoContent from '../../../common/components/NoContent'
import Paginate from '../../../common/components/Paginate'
import {contactStore} from './ContactStore'
import {dateUtils} from "../../../common/utils/DateUtils";
import {sttPage} from "../../../common/utils/Utils";


const ContactPage = () => {
    const handlePageClick = async (data: any) => {
        contactStore.page = data.selected;
        await contactStore.getContact();
    };

    const returnStatus = (status: any) => {
        switch (status) {
            case "WAITING":
                return <div className="badge badge-light-warning">Chờ xử lý</div>
            case "COMPLETE":
                return <div className="badge badge-light-success">Đã xử lý</div>
        }
    }

    useEffect(() => {
        contactStore.getContact().then()
    }, [])

    return (
        <>
            <div className='d-flex align-items-center justify-content-between mb-6'>
                <h1 className='d-flex text-dark fw-bolder fs-3 align-items-center my-1'>Liên hệ</h1>
            </div>

            <div id="kt_ecommerce_sales_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                <div className="w-100 mw-250px">
                    <select onChange={async (e: any) => {contactStore.status = e.currentTarget.value; await contactStore.getContact();}} className="form-select form-select-solid">
                        <option value="">Chọn trạng thái</option>
                        <option value="WAITING">Chờ xử lý</option>
                        <option value="COMPLETE">Đã xử lý</option>
                    </select>
                </div>
                {contactStore.isLoading ? <Loading/> : <div className='table-responsive'>
                    {contactStore.listContact && contactStore.listContact.length > 0 ?
                        <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
                            <thead>
                            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                <th>STT</th>
                                <th>Họ và tên</th>
                                <th>Số điện thoại</th>
                                <th>Email</th>
                                <th>Địa chỉ</th>
                                <th>Trạng thái</th>
                                <th>Lời nhắn</th>
                                <th>Thời gian</th>
                                {contactStore.status !== "COMPLETE" && <th className="text-center">Thao tác</th>}
                            </tr>
                            </thead>
                            <tbody>
                            {contactStore.listContact.map((item: any, i: number) => {
                                return (
                                    <tr key={i}>
                                        <td>{sttPage(contactStore.page, i)}</td>
                                        <td>{item.name}</td>
                                        <td>{item.phone}</td>
                                        <td>{item.email}</td>
                                        <td>{item.address}</td>
                                        <td>{returnStatus(item.status)}</td>
                                        <td>{item.message}</td>
                                        <td>{dateUtils.formatDate(item.createdAt)}</td>
                                        {contactStore.status !== "COMPLETE" && <td width="15%" className="text-center">
                                            {item.status == 'WAITING' &&
                                                <button onClick={() => contactStore.changeStatus(item)} className="btn btn-sm btn-primary">
                                                    Hoàn thành
                                                </button>
                                            }
                                        </td>}
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table> : <NoContent/>}
                    <Paginate callback={handlePageClick} totalPage={contactStore.totalPage}
                              currentPage={contactStore.page}/>
                </div>}
            </div>
        </>
    )

}

export default observer(ContactPage)