import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import Loading from '../../common/components/Loading'
import NoContent from '../../common/components/NoContent'
import Paginate from '../../common/components/Paginate'
import {slug, sttPage, urlImage} from '../../common/utils/Utils'
import {Link} from 'react-router-dom'
import DeleteNews from './components/DeleteNews'
import {newsStore} from './NewsStore'
import {dateUtils} from "../../common/utils/DateUtils";


const NewsPage = () => {

  const handlePageClick = async (data: any) => {
    newsStore.page = data.selected;
    await newsStore.getNews();
  };


  useEffect(() => {
    newsStore.getNews().then()
  }, [])

  return (
    <>
      <div className='d-flex align-items-center justify-content-between mb-6'>
        <h1 className='d-flex text-dark fw-bolder fs-3 align-items-center my-1'>Tin tức</h1>
        <Link to={'/add-new?type=add'} onClick={() => newsStore.clearForm()}><button className='btn btn-sm btn-primary'>Tạo mới</button></Link>
      </div>

      <div className="card-title mb-6 d-flex align-items-center">
        <div className="d-flex align-items-center">
          <div className='d-flex align-items-center mr-2 position-relative'>
            <i className='bi bi-search cursor-pointer fs-1x fs-3 position-absolute ms-4'
               onClick={async () => newsStore.getNews()} />
            <input type='text' value={newsStore.keyword} onChange={(e: any) => newsStore.keyword = e.currentTarget.value} onKeyDown={async (e: any) => {
              if (e.key === 'Enter') {await newsStore.getNews()}}} data-kt-ecommerce-order-filter='search' className='form-control form-control-solid w-250px ps-12' placeholder='Tìm kiếm theo tiêu đề' />
          </div>
        </div>
      </div>

      <div id="kt_ecommerce_sales_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">

        {newsStore.isLoading ? <Loading/> : <div className='table-responsive'>
          {newsStore.listPost && newsStore.listPost.length > 0 ?  <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
            <thead>
            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
              <th>STT</th>
              <th>Ảnh</th>
              <th>Tiêu đề</th>
              <th>Thời gian cập nhật</th>
              <th>Trạng thái</th>
              <th className="text-center">Thao tác</th>
            </tr>
            </thead>
            <tbody>
            {newsStore.listPost.map((item: any, i: number) => {
              return (
                <tr key={i}>
                  <td>{sttPage(newsStore.page, i)}</td>
                  <td>
                    <div className="symbol symbol-30px symbol-md-40px">
                      {item.introduce.image && <img src={urlImage(item.introduce.image)} alt=""/>}
                    </div>
                  </td>
                  <td>{item.title}</td>
                  <td>{dateUtils.formatDate(item.updatedAt)}</td>
                  <td>{item.status == 'PUBLIC' ? 'Hiển thị' : 'Ẩn'}</td>
                  <td width="15%" className="text-center">
                    <div className='d-flex align-items-center justify-content-center'>
                      <i title={item.status == 'PUBLIC' ? 'Ẩn tin tức' : 'Hiển thị tin tức'} onClick={() => newsStore.changeStatus(item)} className={`fas cursor-pointer fa-toggle-${item.status === "PUBLIC" ? 'on text-primary' : 'off'}`} style={{fontSize: 32}}/>
                      <span className="p-2"/>
                      <Link to={`/update-new/${slug(item.title)}/${item.id}?type=edit`} title="Chỉnh sửa" className="btn btn-icon btn-warning btn-sm"><i className="fas fa-pen"/></Link>
                      <span className="p-2"/>
                      <a href="#" title="Xóa" className="btn btn-icon btn-danger btn-sm" data-bs-toggle='modal' data-bs-target='#delete_news' onClick={() => newsStore.id = item.id}><i className="fas fa-trash-alt"/></a>
                    </div>
                  </td>
                </tr>
              )
            })}
            </tbody>
          </table> : <NoContent/>}
          <Paginate callback={handlePageClick} totalPage={newsStore.totalPage} currentPage={newsStore.page}/>
        </div>}
      </div>
      <DeleteNews/>
    </>
  )
}

export default observer(NewsPage)