/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'

export function AsideMenuMain() {


    return (
        <>
            <div className="menu-item mb-2">
                <div className="menu-content">
                    <span className="menu-heading fw-bold text-uppercase fs-7">Trang chủ</span>
                </div>
            </div>
            <AsideMenuItemWithSub
                to='/'
                title='Bài viết'
                fontIcon='bi-archive'
            >
                <AsideMenuItem
                    to='category'
                    title='Danh mục'
                    hasBullet={true}
                />
                <AsideMenuItem
                    to='blog'
                    title='Blog'
                    hasBullet={true}
                />
                <AsideMenuItem
                    to='new'
                    title='Tin tức'
                    hasBullet={true}
                />
                <AsideMenuItem
                    to='standard'
                    title='Bài viết'
                    hasBullet={true}
                />
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
                to='/'
                title='Tuyển dụng'
                fontIcon='bi-archive'
            >
                <AsideMenuItem
                    to='apply'
                    title='Ứng tuyển'
                    hasBullet={true}
                />
                <AsideMenuItem
                    to='occupation'
                    title='Ngành nghề'
                    hasBullet={true}
                />
                <AsideMenuItem
                    to='recruitment'
                    title='Tuyển dụng'
                    hasBullet={true}
                />
            </AsideMenuItemWithSub>

            <AsideMenuItemWithSub
                to='/'
                title='Dịch vụ'
                fontIcon='bi-archive'
            >
                <AsideMenuItem
                    to='contact'
                    title='Liên hệ'
                    hasBullet={true}
                />
            </AsideMenuItemWithSub>

            <AsideMenuItemWithSub
                to='/'
                title='Cấu hình'
                fontIcon='bi-archive'
            >
                <AsideMenuItem
                    to='config'
                    title='Cấu hình'
                    hasBullet={true}
                />
            </AsideMenuItemWithSub>

            <div className="menu-item mt-5 mb-2">
                <div className="menu-content">
                    <span className="menu-heading fw-bold text-uppercase fs-7">Gian hàng Ommanilife</span>
                </div>
            </div>

            <AsideMenuItemWithSub
                to='/'
                title='Quản lý sản phẩm'
                fontIcon='bi-archive'
            >
                <AsideMenuItem
                    to='/store/products'
                    title='Sản phẩm'
                    fontIcon='bi-layers'
                    hasBullet={true}
                />

                <AsideMenuItem
                    to='/store/categories'
                    title='Danh mục'
                    fontIcon='bi-layers'
                    hasBullet={true}
                />
            </AsideMenuItemWithSub>

            <AsideMenuItemWithSub
                to='/'
                title='Quản lý cửa hàng'
                fontIcon='bi-archive'
            >
                <AsideMenuItem
                    to='/store/shops'
                    title='Cửa hàng'
                    fontIcon='bi-layers' hasBullet={true}
                />

            </AsideMenuItemWithSub>

            <AsideMenuItemWithSub
                to='/'
                title='Quản lý đơn hàng'
                fontIcon='bi-archive'
            >
                <AsideMenuItem
                    to='/store/order'
                    title='Đơn hàng'
                    fontIcon='bi-layers' hasBullet={true}
                />
            </AsideMenuItemWithSub>

            <AsideMenuItemWithSub
                to='/'
                title='Cấu hình'
                fontIcon='bi-archive'
            >
                <AsideMenuItem
                    to='/store/banner'
                    title='Banner'
                    hasBullet={true}
                />
                <AsideMenuItem
                    to='/store/menu'
                    title='Menu'
                    hasBullet={true}
                />

                <AsideMenuItem
                    to='/store/footer'
                    title='Footer'
                    hasBullet={true}
                />

            </AsideMenuItemWithSub>

        </>
    )
}
