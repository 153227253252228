import React, {useEffect} from 'react'
import {orderStore} from './OrderStore'
import {observer} from 'mobx-react-lite'
import ListProduct from './components/ListProduct'
import Loading from "../../../common/components/Loading";
import NoContent from "../../../common/components/NoContent";
import Paginate from "../../../common/components/Paginate";
import {sttPage, urlImage} from "../../../common/utils/Utils";

const OrderWrapper = observer(() => {

  const handlePageClick = async (data: any) => {
    orderStore.page = data.selected;
    await orderStore.getOrder();
  };

  useEffect(() => {
    orderStore.getOrder();
  }, [])

  return (
    <>
      <div className='d-flex align-items-center justify-content-between mb-10'>
        <h1 className='d-flex text-dark fw-bolder fs-3 align-items-center my-1'>Danh sách đơn hàng</h1>
      </div>
      {orderStore.isLoading ? <Loading/> : <div className='table-responsive'>
        {orderStore.listOrder && orderStore.listOrder.length > 0 ? <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
          <thead>
          <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
            <th>STT</th>
            <th>Avatar</th>
            <th>Cửa hàng</th>
            <th>Sản phẩm</th>
            <th>Người mua</th>
            <th>Số điện thoại</th>
            <th>Địa chỉ</th>
          </tr>
          </thead>
          <tbody>
          {orderStore.listOrder.map((item: any, i: number) => {
            return (
              <tr key={i}>
                <td>{sttPage(orderStore.page, i)}</td>
                <td>
                  {item.shopInfo?.avatar && <div className="symbol symbol-30px symbol-md-40px">
                    <img src={urlImage(item.shopInfo?.avatar)} alt='' />
                  </div>}
                </td>
                <td>{item.shopInfo?.name}</td>
                <td><button className="btn btn-sm btn-outline btn-outline-primary" onClick={() => orderStore.listProduct = item.items} data-bs-toggle='modal' data-bs-target='#detail_product'>Chi tiết</button></td>
                <td>{item.orderPayment?.billInfo?.name}</td>
                <td>{item.orderPayment?.billInfo?.phone}</td>
                <td>{item.userAddress?.ward?.name}, {item.userAddress?.district?.name}, {item.userAddress?.province?.name}</td>
              </tr>
            )
          })}
          </tbody>
        </table> : <NoContent/>}
        <Paginate callback={handlePageClick} totalPage={orderStore.totalPage} currentPage={orderStore.page}/>
        <ListProduct/>
      </div>}
    </>
  )
})

export default OrderWrapper