import {makeAutoObservable} from 'mobx'
import {categoryService} from './CategoryService'
import $ from "jquery"
import {toastUtil} from '../../common/utils/ToastUtil'


class CategoryStore{
  id: any = ''
  page: any = 0
  type: any = ''
  searchLevel: any = ''
  redirect: boolean = false
  keyword: any = ''
  totalPage: any = 0
  listCate: any[] = []
  listCateParent: any[] = []
  dataRequest: any = {
    id: '',
    name: '',
    description: '',
    parentId: null,
    coverImg: ''
  }
  isLoading: boolean = false
  isLoadingBt: boolean = false
  isLoadingDetail: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  clearForm(){
    this.isLoadingDetail = true
    this.type = 'add'
    this.dataRequest = {
      id: '',
      name: '',
      description: '',
      parentId: null,
      coverImg: ''
    }
    setTimeout(() => {
      this.isLoadingDetail = false
    }, 100)
  }


  async getCate(){
    this.isLoading = true;
    const result = await categoryService.getCate()
    this.isLoading = false;
    if(result.status === 200){
      this.listCate = result.body.data;
      this.totalPage = result.body.metadata.totalPage
    }
  }


  async getCateParent(type?: string){
    const result = await categoryService.getCateParent(type)
    if(result.status === 200){
      this.listCateParent = result.body
    }
  }


  async getCateDetail(id: any) {
    this.type = 'edit'
    this.isLoadingDetail = true
    const result = await categoryService.getDetailCate(id);
    if (result.status === 200) {
      this.dataRequest = result.body;
      this.isLoadingDetail = false
    }
  }

  async addOrEdit() {

    let {id, name, coverImg, parentId, description} = this.dataRequest

    let data: any = {
      name: name,
      coverImg: coverImg,
      description: description,
      parentId: parentId
    }

    this.isLoadingBt = true;
    let result: any

    if(!coverImg){
      toastUtil.warning('Banner không được để trống!')
      return
    }

    if(!name){
      toastUtil.warning('Tên danh mục không được để trống!')
      return
    }

    if(this.type === 'add'){
      result = await categoryService.addCate(data);
    }else {
      result = await categoryService.updateCate(id, data);
    }

    if(!name){
      toastUtil.warning('Tên danh mục không được để trống!')
      return
    }

    this.isLoadingBt = false;
    if (result.status === 200) {
      toastUtil.success(`${this.type == "add" ? 'Thêm' : 'Cập nhật'} danh mục thành công!`)
      this.clearForm()
      this.redirect = true
    } else toastUtil.error(result.body.message ?? 'Đã có lỗi xảy ra')

  }

  async delete() {
    this.isLoadingBt = true;
    const result = await categoryService.deleteCate(this.id);
    this.isLoadingBt = false;
    if (result.status === 200) {
      toastr.success('Xóa danh mục thành công!')
      $('#close_delete_cate').trigger('click')
     await this.getCate()
    } else toastr.error(result.body.message)
  }

}

export const categoryStore = new CategoryStore();