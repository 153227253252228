import React, {useEffect} from 'react'
import {categoryStore} from './CategoryStore'
import {observer} from 'mobx-react'
import Loading from '../../common/components/Loading'
import NoContent from '../../common/components/NoContent'
import Paginate from '../../common/components/Paginate'
import DeleteCate from './components/DeleteCate'
import {slug, sttPage, urlImage} from '../../common/utils/Utils'
import {Link} from 'react-router-dom'
import {dateUtils} from "../../common/utils/DateUtils";


const CategoryPage = () => {

  const handlePageClick = async (data: any) => {
    categoryStore.page = data.selected
    await categoryStore.getCate()
  }

  useEffect(() => {
    categoryStore.getCate().then()
    categoryStore.getCateParent().then()
  }, [])

  return (
    <>
      <div className='d-flex align-items-center justify-content-between mb-6'>
        <h1 className='d-flex text-dark fw-bolder fs-3 align-items-center my-1'>Danh mục</h1>
        <Link to={'/add-category?type=add'} onClick={() => categoryStore.clearForm()}>
          <button className='btn btn-sm btn-primary'>Tạo mới</button>
        </Link>
      </div>

      <div className='d-flex align-items-center position-relative my-1 me-6 mb-6'>
        <i className='bi bi-search cursor-pointer fs-1x fs-3 position-absolute ms-4'
           onClick={async () => categoryStore.getCate()} />
        <input type='text' value={categoryStore.keyword}
               onChange={(e: any) => categoryStore.keyword = e.currentTarget.value} onKeyDown={async (e: any) => {
          if (e.key === 'Enter') {
            await categoryStore.getCate()
          }
        }} data-kt-ecommerce-order-filter='search' className='form-control form-control-solid w-250px ps-12'
               placeholder='Tìm kiếm theo danh mục' />
      </div>

      <div id='kt_ecommerce_sales_table_wrapper' className='dataTables_wrapper dt-bootstrap4 no-footer'>

        {categoryStore.isLoading ? <Loading /> : <div className='table-responsive'>
          {categoryStore.listCate && categoryStore.listCate.length > 0 ?
            <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
              <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th>STT</th>
                <th>Ảnh</th>
                <th>Danh mục</th>
                <th>Danh mục cha</th>
                <th>Thời gian cập nhật</th>
                <th>Thao tác</th>
              </tr>
              </thead>
              <tbody>
              {categoryStore.listCate.map((item: any, i: number) => {
                return (
                  <tr key={i}>
                      <td>{sttPage(categoryStore.page, i)}</td>
                    <td>
                      <div className='symbol symbol-30px symbol-md-40px'>
                        {item.coverImg && <img src={urlImage(item.coverImg)} alt='' />}
                      </div>
                    </td>
                    <td>{item.name}</td>
                    <td>{item.parentName}</td>
                      <td>{dateUtils.formatDate(item.updatedAt)}</td>
                    <td width='10%'>
                      <Link to={`/update-category/${slug(item.name)}/${item.id}?type=edit`} title='Chỉnh sửa'
                            className='btn btn-icon btn-warning btn-sm'><i className='fas fa-pen' /></Link>
                      <span className='p-2' />
                      <a href='#' title='Xóa' className='btn btn-icon btn-danger btn-sm' data-bs-toggle='modal'
                         data-bs-target='#delete_cate' onClick={() => categoryStore.id = item.id}><i
                        className='fas fa-trash-alt' /></a>
                    </td>
                  </tr>
                )
              })}
              </tbody>
            </table> : <NoContent />}
          <Paginate callback={handlePageClick} totalPage={categoryStore.totalPage} currentPage={categoryStore.page} />
        </div>}
      </div>

      <DeleteCate />
    </>
  )
}

export default observer(CategoryPage)