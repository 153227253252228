import {makeAutoObservable} from 'mobx'
import {toastUtil} from '../../common/utils/ToastUtil'
import {newsService} from "../news/NewsService";
import $ from "jquery";

class StandardStore {
  id: any = ''
  page: any = 0
  type: any = ''
  state: any = 'GAP_STANDARD'
  keyword: any = ''
  redirect: boolean = false
  totalPage: any = 0
  listStandard: any[] = []
  listType: any[] = [
    {
      name: 'Tiêu chuẩn GAP',
      value: 'GAP_STANDARD',
    },
    {
      name: 'Tiêu chuẩn nông nghiệp hữu cơ',
      value: 'ORGANIC_AGRICULTURE_STANDARD',
    }
  ]
  dataRequest: any = {
    id: '',
    thumbnail: '',
    title: '',
    shortContent: '',
    introduce: {
      title: '',
      image: '',
      special: '',
      phone: '',
      action: [
        {
          title: '',
          link: '',
          description: '',
        },
      ],
    },
    content: [{
      title: '',
      content: '',
    }],
    operation: {
      title: '',
      item: [
        {
          title1: '',
          title2: '',
          description: '',
          action: [
            {
              title: '',
              link: '',
            },
          ],
        },
      ],
    },
    type: 'GAP_STANDARD',
  }
  isLoading: boolean = false
  isLoadingBt: boolean = false
  isLoadingDetail: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  clearForm() {
    this.isLoadingDetail = true
    this.type = 'add'
    this.dataRequest = {
      id: '',
      thumbnail: '',
      title: '',
      shortContent: '',
      introduce: {
        title: '',
        image: '',
        special: '',
        phone: '',
        action: [],
      },
      content: [{
        title: '',
        content: '',
      }],
      operation: {
        title: '',
        item: [],
      },
      type: 'GAP_STANDARD',
    }
    setTimeout(() => {
      this.isLoadingDetail = false
    }, 100)
  }


  async getStandard() {
    this.isLoading = true
    const result = await newsService.getStandard()
    this.isLoading = false
    if (result.status === 200) {
      this.listStandard = result.body.data
      this.totalPage = result.body.metadata.totalPage
    }
  }


  async getPostDetail(id: any) {
    this.type = 'edit'
    this.isLoadingDetail = true
    const result = await newsService.getDetail(id)
    if (result.status === 200) {
      this.dataRequest = result.body
      if(!this.dataRequest.introduce?.action){
        this.dataRequest.introduce.action = []
      }
      if(!this.dataRequest.operation?.item){
        this.dataRequest.operation.item = []
      }
      this.isLoadingDetail = false
    }
  }


  async delete() {
    standardStore.isLoadingBt = true
    const result = await newsService.delete(standardStore.id)
    standardStore.isLoadingBt = false
    if (result.status === 200) {
      toastr.success('Xóa bài viết thành công!')
      $('#close_delete').trigger('click')
      await standardStore.getStandard()
    } else toastr.error(result.body.message ?? 'Đã có lỗi xảy ra')
  }


  async addOrEdit() {

    let {id, title, thumbnail, shortContent, introduce, operation, type, content} = this.dataRequest

    let data: any = {
      thumbnail: thumbnail,
      title: title,
      shortContent: shortContent,
      introduce: introduce,
      content: content,
      operation: operation,
      type: type.toUpperCase(),
    }
    

    if (!title) {
      toastUtil.warning('Tiêu đề không được để trống!')
      return
    }

    if (!type) {
      toastUtil.warning('Loại bài viết không được để trống!')
      return
    }


    this.isLoadingBt = true
    let result: any

    if (this.type === 'add') {
      result = await newsService.add(data)
    } else {
      result = await newsService.update(id, data)
    }

    this.isLoadingBt = false
    if (result.status === 200) {
      toastUtil.success(`${this.type == 'add' ? 'Thêm' : 'Cập nhật'} bài viết thành công!`)
      this.clearForm()
      this.redirect = true
    } else toastUtil.error(result.body.message ?? 'Đã có lỗi xảy ra')

  }




}

export const standardStore = new StandardStore()