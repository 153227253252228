import {categoryStore} from './CategoryStore'
import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from "../../../common/helpers/RequestHelper";


class CategoryService {

  public getCate(size?: any): Promise<IApiResponse>{
    return getRequest(`/v1/categories?page=${categoryStore.page}&size=${size ?? 10}${categoryStore.searchLevel ? `&level=${categoryStore.searchLevel}` : ''}`)
  }

  public getCateParent(): Promise<IApiResponse>{
    return getRequest(`/v1/categories?page=0&size=2000&level=1`)
  }

  public getDetailCate(id: any): Promise<IApiResponse>{
    return getRequest(`/v1/categories/${id}`)
  }

  public addCate(data: any): Promise<IApiResponse>{
    return postRequest(`/v1/categories`, data)
  }

  public updateCate(id: any, data: any): Promise<IApiResponse>{
    return putRequest(`/v1/categories/${id}`, data)
  }

  public deleteCate(id: any): Promise<IApiResponse>{
    return deleteRequest(`/v1/categories/${id}`, {id})
  }

}

export const categoryService = new CategoryService()

