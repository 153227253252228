import React, {useState} from 'react'
import {observer} from 'mobx-react'
import {urlImage} from '../../../common/utils/Utils'
import Input from '../../../common/components/form/Input'
import uploadFileHelper from '../../../common/helpers/UploadFileHelper'
import {Player} from 'video-react'
import Loading from '../../../common/components/Loading'
import {configStore} from '../ConfigStore'


const ConfigHome = () => {
    const [isLoading, setLoading] = useState(false)

    const changeLogoFooter = (value: any) => {
        configStore.dataRequest.content.footer.image = value
    }

    const changeBannerHeader = (value: any) => {
        configStore.dataRequest.content.banner.image = value
    }

    const changeBannerIntroduce = (value: any) => {
        configStore.dataRequest.content.block1.image = value
    }

    const changeBannerCoreValue = (value: any) => {
        configStore.dataRequest.content.block2.image = value
    }

    const changeBannerShop = (value: any) => {
        configStore.dataRequest.content.flashSale.image = value
    }


    const uploadVideoHeader = (value?: any) => {
        setLoading(true)
        if (value) {
            configStore.dataRequest.content.banner.link = value
            setLoading(false)
        }
    }


    const onUploadImg = async (e: any, type: any, typeImg: string = 'ico' || 'image', index: any) => {
        const changeImg = (value: any) => {
            if (typeImg == 'image') {
                if (type == 'proService') {
                    configStore.dataRequest.content.proService.info[index].image = value;
                } else {
                    configStore.dataRequest.content.flashSale.info[index].image = value;
                }
            } else {
                if (type == 'proService') {
                    configStore.dataRequest.content.proService.info[index].icon = value;
                } else {
                    configStore.dataRequest.content.flashSale.info[index].icon = value;
                }
            }
        }
        await uploadFileHelper(e, changeImg)
    };


    return (
        <div className="page_home">
            <div className='border_bottom pb-8'>
                <a data-bs-toggle='collapse' className='d-flex justify-content-between align-items-center'
                   href='#content_banner'>
                    <h4 className='font-bold mb-0'>Nội dung Banner</h4>
                    <div className='line'/>
                    <i className='fas fa-chevron-down' style={{fontSize: 18}}/>
                </a>
                <div className='collapse mt-8' id='content_banner' style={{padding: `0 24px`}}>
                    <div className='mb-4 h-auto'>
                        <label className='form-label w-100'>Banner (1920x560) <span
                            className='text-danger'>*</span></label>
                        <div className='upLoadImage w-100'>
                            {configStore.dataRequest.content.banner?.image ?
                                <img style={{width: '100%', height: 280}}
                                     src={urlImage(configStore.dataRequest.content.banner?.image)}
                                     alt=''/> :
                                <div className='no_img d-flex align-items-center justify-content-center'><i
                                    className='far fa-image'/></div>}
                            <button className='btn btn-md btn-outline-info change-avatar'
                                    style={{marginLeft: 0, marginTop: 16}}>
                                <span>Upload</span>
                                <input accept='image/jpeg, image/jpg, image/png' type='file'
                                       onChange={async (e: any) => await uploadFileHelper(e, changeBannerHeader)}/>
                            </button>
                        </div>
                    </div>
                    <div className='mb-4'>
                        <label className='form-label'>Tiêu đề <span className='text-danger'>*</span></label>
                        <Input value={configStore.dataRequest.content.banner.title}
                               onChange={(e: any) => configStore.dataRequest.content.banner.title = e.currentTarget.value}
                               placeholder='Nhập tiêu đề'/>
                    </div>
                    <div className='mb-4'>
                        <label className='form-label'>Tiêu đề con <span className='text-danger'>*</span></label>
                        <Input value={configStore.dataRequest.content.banner.title1}
                               onChange={(e: any) => configStore.dataRequest.content.banner.title1 = e.currentTarget.value}
                               placeholder='Nhập tiêu đề con'/>
                    </div>
                    <div className='mb-4'>
                        <label className='form-label'>Mô tả <span className='text-danger'>*</span></label>
                        <Input value={configStore.dataRequest.content.banner.description}
                               onChange={(e: any) => configStore.dataRequest.content.banner.description = e.currentTarget.value}
                               placeholder='Nhập mô tả'/>
                    </div>

                    <div className='mb-4'>
                        <label className='form-label w-100'>Video Youtube <span className='text-danger'>*</span></label>
                        <div className='upLoadImage w-100'>
                            {isLoading ? <Loading/> :
                                <Player width={600} height={260} autoPlay muted>
                                    <source src={urlImage(configStore.dataRequest.content.banner.link)} width={600}
                                            height={260}/>
                                </Player>}
                            <button className='btn change-avatar' disabled={isLoading}
                                    style={{marginLeft: 0, marginTop: 16}}>
                                <span>{isLoading ? 'Loading...' : 'Upload'}</span>
                                <input accept='video/mp4' type='file'
                                       onChange={async (e: any) => await uploadFileHelper(e, uploadVideoHeader)}/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='border_bottom pb-8 mt-8'>
                <a data-bs-toggle='collapse' className='d-flex justify-content-between align-items-center'
                   href='#introduce'>
                    <h4 className='font-bold mb-0'>Giới thiệu về Omfarm</h4>
                    <div className='line'/>
                    <i className='fas fa-chevron-down' style={{fontSize: 18}}/>
                </a>
                <div className='collapse mt-8' id='introduce' style={{padding: `0 24px`}}>
                    <div className='mb-4'>
                        <label className='form-label w-100'>Ảnh (600x320) <span className='text-danger'>*</span></label>
                        <div className='upLoadImage d-flex w-100'>
                            {configStore.dataRequest.content.block1.image ?
                                <img style={{width: 300, height: 160}}
                                     src={urlImage(configStore.dataRequest.content.block1.image)}
                                     alt=''/> :
                                <div className='no_img d-flex align-items-center justify-content-center'><i
                                    className='far fa-image'/></div>}
                            <button className='btn change-avatar'><span>Upload</span>
                                <input accept='image/jpeg, image/jpg, image/png' type='file'
                                       onChange={async (e: any) => await uploadFileHelper(e, changeBannerIntroduce)}/>
                            </button>
                        </div>
                    </div>
                    <div className='mb-4'>
                        <label className='form-label'>Mô tả <span className='text-danger'>*</span></label>
                        <Input value={configStore.dataRequest.content.block1.description}
                               onChange={(e: any) => configStore.dataRequest.content.block1.description = e.currentTarget.value}
                               placeholder='Nhập mô tả'/>
                    </div>
                    <div className='mb-4'>
                        <label className='form-label'>Link <span className='text-danger'>*</span></label>
                        <Input value={configStore.dataRequest.content.block1.link}
                               onChange={(e: any) => configStore.dataRequest.content.block1.link = e.currentTarget.value}
                               placeholder='Nhập link'/>
                    </div>
                </div>
            </div>

            <div className='border_bottom pb-8 mt-8'>
                <a data-bs-toggle='collapse' className='d-flex justify-content-between align-items-center'
                   href='#core_value'>
                    <h4 className='font-bold mb-0'>Giá trị</h4>
                    <div className='line'/>
                    <i className='fas fa-chevron-down' style={{fontSize: 18}}/>
                </a>
                <div className='collapse mt-8' id='core_value' style={{padding: `0 24px`}}>
                    <div className='mb-4'>
                        <label className='form-label w-100'>Ảnh (600x320) <span className='text-danger'>*</span></label>
                        <div className='upLoadImage d-flex w-100'>
                            {configStore.dataRequest.content.block2.image ?
                                <img style={{width: 300, height: 160}}
                                     src={urlImage(configStore.dataRequest.content.block2.image)}
                                     alt=''/> :
                                <div className='no_img d-flex align-items-center justify-content-center'><i
                                    className='far fa-image'/></div>}
                            <button className='btn change-avatar'><span>Upload</span>
                                <input accept='image/jpeg, image/jpg, image/png' type='file'
                                       onChange={async (e: any) => await uploadFileHelper(e, changeBannerCoreValue)}/>
                            </button>
                        </div>
                    </div>
                    <div className='mb-4'>
                        <label className='form-label'>Mô tả <span className='text-danger'>*</span></label>
                        <Input value={configStore.dataRequest.content.block2.description}
                               onChange={(e: any) => configStore.dataRequest.content.block2.description = e.currentTarget.value}
                               placeholder='Nhập mô tả'/>
                    </div>
                </div>
            </div>

            <div className='border_bottom pb-8 mt-8'>
                <a data-bs-toggle='collapse' className='d-flex justify-content-between align-items-center'
                   href='#outstanding_service'>
                    <h4 className='font-bold mb-0'>Dịch vụ nổi bật</h4>
                    <div className='line'/>
                    <i className='fas fa-chevron-down' style={{fontSize: 18}}/>
                </a>
                <div className='collapse mt-8' id='outstanding_service' style={{padding: `0 24px`}}>
                    <div className='mb-4'>
                        <label className='form-label'>Tiêu đề <span className='text-danger'>*</span></label>
                        <Input value={configStore.dataRequest.content.proService?.title}
                               onChange={(e: any) => configStore.dataRequest.content.proService.title = e.currentTarget.value}
                               placeholder='Nhập mô tả'/>
                    </div>
                    <div className='mb-4'>
                        <label className='form-label'>Mô tả <span className='text-danger'>*</span></label>
                        <Input value={configStore.dataRequest.content.proService.description}
                               onChange={(e: any) => configStore.dataRequest.content.proService.description = e.currentTarget.value}
                               placeholder='Nhập mô tả'/>
                    </div>
                    <div>
                        <div className='d-flex justify-content-end mb-4'>
                            {configStore.dataRequest.content.proService.info && configStore.dataRequest.content.proService.info.length < 4 &&
                            <button className='btn btn-sm btn-primary'
                                    onClick={() => configStore.dataRequest.content.proService.info.push(
                                        {name: '', icon: '', image: '', link: ''})}>Thêm dịch vụ
                            </button>}
                        </div>
                        {configStore.dataRequest.content.proService.info && configStore.dataRequest.content.proService.info.map((item: any, index: number) => {
                            return (
                                <div className='d-flex mb-8 justify-content-between symbol bg-light p-4' key={index}>
                                    <div style={{width: `96%`}}>
                                        <div className='mb-4 d-flex w-100'>
                                            <div className='w-50 pr-4'>
                                                <label className='form-label w-100'>Image (220x124 ảnh không nền) <span
                                                    className='text-danger'>*</span></label>
                                                <div className='upLoadImage d-flex w-100'>
                                                    {item.image ?
                                                        <img style={{width: 220, height: 124}}
                                                             src={urlImage(item.image)}
                                                             alt=''/> :
                                                        <div
                                                            className='no_img d-flex align-items-center justify-content-center'
                                                            style={{width: 220, height: 124}}><i
                                                            className='far fa-image'/></div>}
                                                    <button className='btn change-avatar mt-0'><span>Upload</span>
                                                        <input accept='image/jpeg, image/jpg, image/png' type='file'
                                                               onChange={async (e: any) => onUploadImg(e, 'proService', 'image', index)}/>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='w-50 pl-4'>
                                                <label className='form-label w-100'>Logo (76x76 ảnh không nền) <span
                                                    className='text-danger'>*</span></label>
                                                <div className='upLoadImage d-flex w-100'>
                                                    {item.icon ?
                                                        <img style={{width: 76, height: 76}} src={urlImage(item.icon)}
                                                             alt=''/> :
                                                        <div
                                                            className='no_img d-flex align-items-center justify-content-center'
                                                            style={{width: 76, height: 76}}><i
                                                            className='far fa-image'/></div>}
                                                    <button className='btn change-avatar mt-0'><span>Upload</span>
                                                        <input accept='image/jpeg, image/jpg, image/png' type='file'
                                                               onChange={async (e: any) => onUploadImg(e, 'proService', 'ico', index)}/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mb-4'>
                                            <label className='form-label'>Tên</label>
                                            <input type='text' className='form-control' value={item.name}
                                                   onChange={(e: any) => item.name = e.currentTarget.value}
                                                   placeholder='Nhập tiêu đề'/>
                                        </div>
                                        <div className='mb-4'>
                                            <label className='form-label'>Link</label>
                                            <input type='text' className='form-control' value={item.link}
                                                   onChange={(e: any) => item.link = e.currentTarget.value}
                                                   placeholder='Nhập link'/>
                                        </div>
                                    </div>
                                    <i className='fas text-danger fa-trash-alt cursor-pointer'
                                       style={{fontSize: 20, marginTop: 28, marginRight: 10}}
                                       onClick={() => configStore.dataRequest.content.proService.info.splice(index, 1)}/>
                                </div>
                            )
                        })}
                    </div>

                </div>
            </div>

            <div className='border_bottom pb-8 mt-8'>
                <a data-bs-toggle='collapse' className='d-flex justify-content-between align-items-center'
                   href='#cooperation_development'>
                    <h4 className='font-bold mb-0'>Hợp tác & phát triển</h4>
                    <div className='line'/>
                    <i className='fas fa-chevron-down' style={{fontSize: 18}}/>
                </a>
                <div className='collapse mt-8' id='cooperation_development' style={{padding: `0 24px`}}>
                    <div className='mb-4'>
                        <label className='form-label'>Tiêu đề <span className='text-danger'>*</span></label>
                        <Input value={configStore.dataRequest.content.block3.title}
                               onChange={(e: any) => configStore.dataRequest.content.block3.title = e.currentTarget.value}
                               placeholder='Nhập mô tả'/>
                    </div>
                    <div className='mb-4'>
                        <label className='form-label'>Link video<span className='text-danger'>*</span></label>
                        <Input value={configStore.dataRequest.content.block3.link}
                               onChange={(e: any) => configStore.dataRequest.content.block3.link = e.currentTarget.value}
                               placeholder='Nhập link video'/>
                    </div>

                </div>
            </div>

            <div className='border_bottom pb-8 mt-8'>
                <a data-bs-toggle='collapse' className='d-flex justify-content-between align-items-center'
                   href='#flash_sale'>
                    <h4 className='font-bold mb-0'>Cửa hàng</h4>
                    <div className='line'/>
                    <i className='fas fa-chevron-down' style={{fontSize: 18}}/>
                </a>
                <div className='collapse mt-8' id='flash_sale' style={{padding: `0 24px`}}>
                    <div className='mb-4'>
                        <label className='form-label w-100'>Banner (470x530) <span
                            className='text-danger'>*</span></label>
                        <div className='upLoadImage d-flex w-100'>
                            {configStore.dataRequest.content.flashSale.image ?
                                <img style={{width: 235, height: 265}}
                                     src={urlImage(configStore.dataRequest.content.flashSale.image)}
                                     alt=''/> :
                                <div className='no_img d-flex align-items-center justify-content-center'><i
                                    className='far fa-image'/></div>}
                            <button className='btn change-avatar'><span>Upload</span>
                                <input accept='image/jpeg, image/jpg, image/png' type='file'
                                       onChange={async (e: any) => await uploadFileHelper(e, changeBannerShop)}/>
                            </button>
                        </div>
                    </div>
                    <div className='mb-4'>
                        <label className='form-label'>Tiêu đề <span className='text-danger'>*</span></label>
                        <Input value={configStore.dataRequest.content.flashSale.title}
                               onChange={(e: any) => configStore.dataRequest.content.flashSale.title = e.currentTarget.value}
                               placeholder='Nhập tiêu đề'/>
                    </div>
                    <div className="d-none">
                        <div className='d-flex justify-content-end mb-4'>
                            {configStore.dataRequest.content.flashSale.info && configStore.dataRequest.content.flashSale.info.length < 9 &&
                            <button className='btn btn-sm btn-primary'
                                    onClick={() => configStore.dataRequest.content.flashSale.info.push(
                                        {name: '', icon: '', image: '', link: ''})}>Thêm cửa hàng
                            </button>}
                        </div>
                        {configStore.dataRequest.content.flashSale.info && configStore.dataRequest.content.flashSale.info.map((item: any, index: number) => {
                            return (
                                <div className='d-flex mb-8 justify-content-between symbol bg-light p-4' key={index}>
                                    <div style={{width: `96%`}}>
                                        <div className='mb-4 d-flex w-100'>
                                            <div className='w-50 pr-4'>
                                                <label className='form-label w-100'>Image (142x115 ảnh không nền) <span
                                                    className='text-danger'>*</span></label>
                                                <div className='upLoadImage d-flex w-100'>
                                                    {item.image ?
                                                        <img style={{width: 142, height: 115}}
                                                             src={urlImage(item.image)}
                                                             alt=''/> :
                                                        <div
                                                            className='no_img d-flex align-items-center justify-content-center'>
                                                            <i
                                                                className='far fa-image'/></div>}
                                                    <button className='btn change-avatar mt-0'><span>Upload</span>
                                                        <input accept='image/jpeg, image/jpg, image/png' type='file'
                                                               onChange={async (e: any) => onUploadImg(e, 'flash_sale', 'image', index)}/>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='w-50 pl-4'>
                                                <label className='form-label w-100'>Logo (142x50 ảnh không nền) <span
                                                    className='text-danger'>*</span></label>
                                                <div className='upLoadImage d-flex w-100'>
                                                    {item.icon ?
                                                        <img style={{width: 142, height: 50}} src={urlImage(item.icon)}
                                                             alt=''/> :
                                                        <div
                                                            className='no_img d-flex align-items-center justify-content-center'>
                                                            <i
                                                                className='far fa-image'/></div>}
                                                    <button className='btn change-avatar mt-0'><span>Upload</span>
                                                        <input accept='image/jpeg, image/jpg, image/png' type='file'
                                                               onChange={async (e: any) => onUploadImg(e, 'flash_sale', 'ico', index)}/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mb-4'>
                                            <label className='form-label'>Tên</label>
                                            <input type='text' className='form-control' value={item.name}
                                                   onChange={(e: any) => item.name = e.currentTarget.value}
                                                   placeholder='Nhập tiêu đề'/>
                                        </div>
                                        <div className='mb-4'>
                                            <label className='form-label'>Link</label>
                                            <input type='text' className='form-control' value={item.link}
                                                   onChange={(e: any) => item.link = e.currentTarget.value}
                                                   placeholder='Nhập link'/>
                                        </div>
                                    </div>
                                    <i className='fas text-danger fa-trash-alt cursor-pointer'
                                       style={{fontSize: 20, marginTop: 28, marginRight: 10}}
                                       onClick={() => configStore.dataRequest.content.flashSale.info.splice(index, 1)}/>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className='mt-8'>
                <a data-bs-toggle='collapse' className='d-flex justify-content-between align-items-center'
                   href='#footer'>
                    <h4 className='font-bold mb-0'>Footer</h4>
                    <div className='line'/>
                    <i className='fas fa-chevron-down' style={{fontSize: 18}}/>
                </a>
                <div className='collapse mt-8' id='footer' style={{padding: `0 24px`}}>
                    <div className='mb-4'>
                        <label className='form-label w-100'>Logo (104x100) <span
                            className='text-danger'>*</span></label>
                        <div className='upLoadImage d-flex w-100'>
                            {configStore.dataRequest.content.footer.image ?
                                <img style={{width: 104, height: 100}}
                                     src={urlImage(configStore.dataRequest.content.footer.image)}
                                     alt=''/> :
                                <div className='no_img d-flex align-items-center justify-content-center'><i
                                    className='far fa-image'/></div>}
                            <button className='btn change-avatar mt-5 pt-5'><span>Upload</span>
                                <input accept='image/jpeg, image/jpg, image/png' type='file'
                                       onChange={async (e: any) => await uploadFileHelper(e, changeLogoFooter)}/>
                            </button>
                        </div>
                    </div>
                    <div className='mb-4'>
                        <label className='form-label'>Tiêu đề <span className='text-danger'>*</span></label>
                        <Input value={configStore.dataRequest.content.footer.title}
                               onChange={(e: any) => configStore.dataRequest.content.footer.title = e.currentTarget.value}
                               placeholder='Nhập tiêu đề'/>
                    </div>
                    <div className='mb-4'>
                        <label className='form-label'>Số điện thoại <span className='text-danger'>*</span></label>
                        <Input value={configStore.dataRequest.content.footer.phone}
                               onChange={(e: any) => configStore.dataRequest.content.footer.phone = e.currentTarget.value}
                               placeholder='Nhập số điện thoại'/>
                    </div>
                    <div className='mb-4'>
                        <label className='form-label'>Email <span className='text-danger'>*</span></label>
                        <Input value={configStore.dataRequest.content.footer.email}
                               onChange={(e: any) => configStore.dataRequest.content.footer.email = e.currentTarget.value}
                               placeholder='Nhập email'/>
                    </div>
                    <div className='mb-4'>
                        <label className='form-label'>LinkFacebook <span className='text-danger'>*</span></label>
                        <Input value={configStore.dataRequest.content.footer.linkFacebook}
                               onChange={(e: any) => configStore.dataRequest.content.footer.linkFacebook = e.currentTarget.value}
                               placeholder='Nhập link facebook'/>
                    </div>
                    <div className='mb-4'>
                        <label className='form-label'>LinkYoutube <span className='text-danger'>*</span></label>
                        <Input value={configStore.dataRequest.content.footer.linkYoutube}
                               onChange={(e: any) => configStore.dataRequest.content.footer.linkYoutube = e.currentTarget.value}
                               placeholder='Nhập link youtube'/>
                    </div>
                    <div className='mb-4'>
                        <label className='form-label'>LinkTiktok <span className='text-danger'>*</span></label>
                        <Input value={configStore.dataRequest.content.footer.linkTiktok}
                               onChange={(e: any) => configStore.dataRequest.content.footer.linkTiktok = e.currentTarget.value}
                               placeholder='Nhập link tiktok'/>
                    </div>
                    <div className='d-flex justify-content-end mb-4'>
                        <button className='btn btn-sm btn-primary'
                                onClick={() => configStore.dataRequest.content.footer.linkInfo.push({
                                    title: '',
                                    items: [{name: '', target: ''}],
                                })}>Thêm
                        </button>
                    </div>
                    {configStore.dataRequest.content.footer.linkInfo && configStore.dataRequest.content.footer.linkInfo.map((item: any, index: number) => {
                        return (
                            <div className='d-flex mb-8 justify-content-between symbol bg-light p-4' key={index}>
                                <div style={{width: `96%`}}>
                                    <div className='mb-5'>
                                        <label className='form-label'>Tiêu đề</label>
                                        <input type='text' className='form-control' value={item.title}
                                               onChange={(e: any) => item.title = e.currentTarget.value}
                                               placeholder='Nhập tiêu đề'/>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h5 className='font-bold mb-4'>Content</h5>
                                        <button className='btn btn-sm btn-primary'
                                                onClick={() => item.items.push({name: '', target: ''})}>+
                                            Thêm Content
                                        </button>
                                    </div>
                                    <div className='pl-4 pr-4'>
                                        {item.items && item.items.map((val: any, i: number) => {
                                            return (
                                                <div className='d-flex align-items-center justify-content-between mb-4'
                                                     key={i}>
                                                    <div className='w-48'>
                                                        <label className='form-label'>Tên</label>
                                                        <input type='text' className='form-control' value={val.name}
                                                               onChange={(e: any) => val.name = e.currentTarget.value}
                                                               placeholder='Nhập tên'/>
                                                    </div>
                                                    <div className='w-48'>
                                                        <label className='form-label'>Link</label>
                                                        <input type='text' className='form-control' value={val.target}
                                                               onChange={(e: any) => val.target = e.currentTarget.value}
                                                               placeholder='Nhập link'/>
                                                    </div>
                                                    <i className='fas text-danger fa-trash-alt cursor-pointer'
                                                       style={{fontSize: 20, marginTop: 28}}
                                                       onClick={() => item.items.splice(i, 1)}/>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <i className='fas text-danger fa-trash-alt cursor-pointer'
                                   style={{fontSize: 20, marginTop: 28, marginRight: 10}}
                                   onClick={() => configStore.dataRequest.content.footer.linkInfo.splice(index, 1)}/>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default observer(ConfigHome)
