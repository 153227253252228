import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from '../../common/helpers/RequestHelper'
import {recruitmentStore} from './RecruitmentStore'


class RecruitmentService {

    public getRecruitment(): Promise<IApiResponse> {
        return getRequest(`/omfarm/v1/job?page=${recruitmentStore.page}&size=10${recruitmentStore.state ? `&type=${recruitmentStore.state}` : ''}${recruitmentStore.occupationId ? `&occupationId=${recruitmentStore.occupationId}` : ''}${recruitmentStore.name ? `&name=${recruitmentStore.name.trim()}` : ''}`)
    }

    public getDetail(id: any): Promise<IApiResponse> {
        return getRequest(`/omfarm/v1/job/${id}`)
    }


    public add(data: any): Promise<IApiResponse> {
        return postRequest(`/omfarm/v1/job`, data)
    }

    public update(id: any, data: any): Promise<IApiResponse> {
        return putRequest(`/omfarm/v1/job/${id}`, data)
    }

    public delete(id: any): Promise<IApiResponse> {
        return deleteRequest(`/omfarm/v1/job/${id}`, {})
    }


    public getProvinces(): Promise<IApiResponse> {
        return getRequest(`/omfarm/v1/provinces`)
    }

    public getDistricts(provinceId: any): Promise<IApiResponse> {
        return getRequest(`/omfarm/v1/districts?provinceId=${provinceId}`)
    }

    public getWards(districtId: any): Promise<IApiResponse> {
        return getRequest(`/omfarm/v1/wards?districtId=${districtId}`)
    }

}

export const recruitmentService = new RecruitmentService()

