import {makeAutoObservable} from 'mobx'
import {profileService} from './ProfileService'
import HttpStatusCode from '../../common/constants/HttpErrorCode'

class ProfileStore {
  profileDetail: any

  constructor() {
    makeAutoObservable(this)
  }

  async getProfile(setStatus?: any, saveAuth?: any){
    const result = await profileService.getProfile()
    if(result.status === HttpStatusCode.OK){
      this.profileDetail = result.body
    }else {
      saveAuth('')
      setStatus ? setStatus(result.body.message ?? 'Đã có lỗi xảy ra!') : toastr.error(result.body.message ?? 'Đã có lỗi xảy ra!')
    }
  }

}

export const profileStore = new ProfileStore()