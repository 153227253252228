import {makeAutoObservable} from 'mobx'
import {contactService} from './ContactService'
import {toastUtil} from '../../../common/utils/ToastUtil'


class ContactStore {
    status: any = ''
    page: number = 0
    totalPage: number = 0
    isLoading: boolean = false
    isLoadingBt: boolean = false
    listContact: any[] = []

    constructor() {
        makeAutoObservable(this)
    }

    async getContact() {
        this.isLoading = true
        const result = await contactService.getContact()
        this.isLoading = false
        if (result.status == 200) {
            contactStore.listContact = result.body.data;
            contactStore.totalPage = result.body.metadata.totalPage
        }
    }

    async changeStatus(item: any) {

        let data = {
            ids: [item.id],
            status: item.status == "WAITING" ? "COMPLETE" : ''
        }
        this.isLoadingBt = true
        const result = await contactService.changeStatus(data)
        this.isLoadingBt = false
        if (result.status == 200) {
            toastUtil.success('Cập nhật trạng thái thành công!')
            this.listContact.map((val) => {
                if (val.id == item.id) {
                    item.status = data.status
                }
            })
        } else {
            toastUtil.error(result.body.message ?? 'Đã có lỗi xảy ra!')
        }
    }

}

export const contactStore = new ContactStore()