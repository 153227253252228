import React from 'react';
import {getRequest} from "../../helpers/RequestHelper";
import HttpStatusCode from "../../constants/HttpErrorCode";
import "./Style.scss"
import $ from 'jquery'
import {urlImage} from "../../utils/Utils";
import Loading from "../Loading";
import {chooseImgStore} from "./ChooseImgStore";
import {observer} from "mobx-react";

interface IProps {
    id?: string,
    service: 'farmorg' | 'food' | 'farm' | 'life',
    changeImg: (e: any) => void,
    isEditor?: boolean
}

const ChooseImg = ({id, service, changeImg, isEditor}:IProps) => {
    let showMore = React.createRef<HTMLDivElement>();

    const handleOnScroll = (e: any) =>{
        if (showMore.current) {
            const self = e.currentTarget;
            if (($(self).height() as number + self.scrollTop) === self.scrollHeight) {
                chooseImgStore.page ++
                chooseImgStore.page < chooseImgStore.totalPage && readMore();
            }
        }
    }

    const readMore = async () =>{
        const result = await getRequest(`/v1/storage?domain=${service}&page=${chooseImgStore.page}&size=50`, true)
        if (result.status === HttpStatusCode.OK) {
            chooseImgStore.listImg = chooseImgStore.listImg.concat(result.body.data)
            chooseImgStore.totalPage = result.body.metadata.totalPage
        }
    }


    return (
        <div className='modal fade modal_update_choose_img' id={id ?? 'modal_choose_img'} aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered mw-1000px'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h4>Chọn ảnh</h4>
                        <button type="button" id="close_choose_img" onClick={() => chooseImgStore.active = 1} className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        {chooseImgStore.isLoading ? <Loading/> : <div className="listImg d-flex flex-wrap" ref={showMore} onScroll={handleOnScroll}>
                            {chooseImgStore.listImg && chooseImgStore.listImg.length > 0 ? chooseImgStore.listImg.map((item, i) => {
                                return <div key={i} className="images"><img onClick={() => {changeImg(isEditor ? urlImage(item.url) : item.url); chooseImgStore.active = 1}} data-bs-dismiss="modal" src={urlImage(item.url)} alt=""/></div>
                            }) : <div className="w-100 d-flex align-items-center justify-content-center" style={{height: 250}}>Chưa có dữ liệu</div>}
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );

}

export default observer(ChooseImg);