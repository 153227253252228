import React from 'react';

interface IProps{
    value: any,
    onChange: any
    isError?: any,
    options: any[],
    disabled?: boolean
    placeholder?: string
}


const Select = (item:IProps) => {
    
    return (
        <>
            <div className="input-group">
                <select
                    className={`form-control ${(item.isError && !item.value) ? 'border_error' : ''}`}
                    value={item.value || ""} disabled={item.disabled}
                    onChange={(e: any) => item.onChange(e)}>
                    <option value="" disabled>{item.placeholder ?? 'Chọn'}</option>
                    {item.options && item.options.map((item, i) => {
                        return <option key={i} value={item.id}>{item.name}</option>
                    })}
                </select>
            </div>
            {(item.isError && !item.value) && <p className="error">{item.isError}</p>}
        </>
    );
}

export default Select;