

class ToastUtil{

    public info(message:string, id?: any) {
        toastr.info(message, "",{
            containerId: id,
            showDuration: 500,
            timeOut: 3000,
            closeDuration: 200,
            progressBar: false,
            closeButton: true
        })
    }

    public success(message:string, id?: any) {
        toastr.success(message, 'Thành công',{
            containerId: id,
            showDuration: 500,
            timeOut: 3000,
            closeDuration: 200,
            progressBar: false,
            closeButton: true
        })
    }

    public warning(message:string, id?: any) {
        toastr.warning(message, 'Cảnh báo', {
            containerId: id,
            showDuration: 500,
            timeOut: 3000,
            closeDuration: 200,
            progressBar: false,
            closeButton: true
        })
    }

    public error(message:string, id?: any) {
        toastr.error(message, 'Lỗi',{
            containerId: id,
            showDuration: 500,
            timeOut: 3000,
            closeDuration: 200,
            progressBar: false,
            closeButton: true
        })
    }

}

export const toastUtil = new ToastUtil();

