import React, {Component} from 'react';


class Loading extends Component<any, any> {

    render() {
        return (
            <div className="d-flex border align-items-center justify-content-center w-100 h-250px">
              <span>Loading ...</span>
            </div>
        )
    }
}

export default Loading;
