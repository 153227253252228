import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import Input from '../../../common/components/form/Input'
import {configStore} from '../ConfigStore'
import TextEditor from '../../../common/components/TextEditor'
import Loading from '../../../common/components/Loading'

const ConfigPolicyComplaints = () => {

    useEffect(() => {
        if(configStore.type == 'add'){
            configStore.getConfig().then()
        }
    }, [])

    if (configStore.isLoadingDetail) {
        return <Loading/>
    } else {
        return (
            <>
                {configStore.type == 'add' && <div className='mb-4'>
                    <label className='form-label'>Loại cấu hình <span className='text-danger'>*</span></label>
                    <select className="form-control" onChange={(e: any) => {
                        configStore.typeConfig = e.currentTarget.value
                        configStore.dataRequest.id = e.currentTarget.value
                    }}>
                        <option value="">Chọn</option>
                        {configStore.listType.map((item, i) => {
                            return <option value={item.id} key={i}>{item.name}</option>
                        })}
                    </select>
                </div>}
                <div className='mb-4'>
                    <label className='form-label'>Tiêu đề <span className='text-danger'>*</span></label>
                    <Input value={configStore.dataRequest.content.title}
                           onChange={(e: any) => configStore.dataRequest.content.title = e.currentTarget.value}
                           placeholder='Nhập tiêu đề'/>
                </div>
                <div className='mb-4'>
                    <label className='form-label'>Tiêu đề con</label>
                    <Input value={configStore.dataRequest.content.subTitle}
                           onChange={(e: any) => configStore.dataRequest.content.subTitle = e.currentTarget.value}
                           placeholder='Nhập tiêu đề con'/>
                </div>

                <div className='mb-4'>
                    <label className='form-label'>Nội dung <span className='text-danger'>*</span></label>
                    <TextEditor isChooseImg={true} content={configStore.dataRequest.content.term}
                                onChange={(e: any) => configStore.dataRequest.content.term = e}/>
                </div>
            </>
        )
    }

}

export default observer(ConfigPolicyComplaints)