import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import {Link, Navigate} from 'react-router-dom'
import {getIDParameter, number_format, urlImage} from '../../../common/utils/Utils'
import {IType, ITypeGender, recruitmentStore} from '../RecruitmentStore'
import Loading from '../../../common/components/Loading'
import TextEditor from '../../../common/components/TextEditor'
import {occupationStore} from '../occupation/OccupationStore'
import DatePickerSingle from '../../../common/components/DatePickerSingle'
import {numberUtil} from '../../../common/utils/NumberUtil'
import {chooseImgStore} from "../../../common/components/modalChooseImg/ChooseImgStore";
import UploadChooseImg from "../../../common/components/modalChooseImg/UploadChooseImg";


const AddEditRecruitment = () => {

    const handleDate = async (startDate: Date) => {
        recruitmentStore.dataRequest.expiredDate = startDate.getTime()
    }

    const changeImg = async (value: any) => {
        recruitmentStore.dataRequest.companyLogo = value
    }

    useEffect(() => {
        let id = getIDParameter()
        recruitmentStore.getProvinces().then()
        occupationStore.getOccupation(100).then()
        if (id) {
            recruitmentStore.getRecruitmentDetail(id, true).then()
        }
        chooseImgStore.getListImg('farmorg').then()
        return (() => {
            recruitmentStore.redirect = false
        })

    }, [])


    if (recruitmentStore.redirect) {
        return <Navigate to={'/recruitment'}/>
    } else return (
        <div id='kt_ecommerce_sales_table_wrapper' className='dataTables_wrapper dt-bootstrap4 no-footer'>
            <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 mb-6 pt-1'>
                <li className='breadcrumb-item'>
                    <Link to='/recruitment' className='text-primary'>Tuyển dụng</Link>
                </li>
                <li className='breadcrumb-item'>
                    <span className='bullet bg-gray-400 w-5px h-2px'/>
                </li>

                <li className='breadcrumb-item text-muted'>
                    {recruitmentStore.dataRequest.id ? recruitmentStore.dataRequest.name : 'Tạo mới'}
                </li>
            </ul>

            <div className='d-flex align-items-center justify-content-between mb-8'>
                <h1 className='d-flex text-dark fw-bolder fs-3 align-items-center my-1'>{recruitmentStore.dataRequest.id ? "Cập nhật" : 'Tạo mới'}</h1>
            </div>

            <UploadChooseImg service="farmorg" changeImg={changeImg}/>

            {recruitmentStore.isLoadingDetail ? <Loading/> : <div className='form'>

                <div className='border_bottom pb-8'>
                    <div className='mb-5'>
                        <label className='form-label w-100'>Logo (56x56) <span className='text-danger'>*</span></label>
                        <div className='upLoadImage d-flex w-100'>
                            {recruitmentStore.dataRequest.companyLogo ?
                                <img width={112} height={112} src={urlImage(recruitmentStore.dataRequest.companyLogo)}
                                     alt=''/> :
                                <div className='no_img d-flex align-items-center justify-content-center'
                                     style={{width: 112, height: 112}}><i className='far fa-image'/></div>}
                            <button className="btn change-avatar pl-0 pt-5" data-bs-toggle='modal' data-bs-target='#modal_upload_choose_img'>
                                Upload
                            </button>
                        </div>
                    </div>

                    <div className='mb-5'>
                        <label className='form-label'>Tên công ty <span className='text-danger'>*</span></label>
                        <input type='text' className='form-control' value={recruitmentStore.dataRequest.companyName}
                               onChange={(e: any) => recruitmentStore.dataRequest.companyName = e.currentTarget.value}
                               placeholder='Nhập tên công ty'/>
                    </div>

                    <div className='mb-5'>
                        <label className='form-label'>Vị trí tuyển dụng <span className='text-danger'>*</span></label>
                        <input type='text' className='form-control' value={recruitmentStore.dataRequest.name}
                               onChange={(e: any) => recruitmentStore.dataRequest.name = e.currentTarget.value}
                               placeholder='Nhập vị trí tuyển dụng'/>
                    </div>

                    <div className='mb-5'>
                        <label className='form-label'>Kinh nghiệm <span className='text-danger'>*</span></label>
                        <input type='text' className='form-control' value={recruitmentStore.dataRequest.workExperience}
                               onChange={(e: any) => recruitmentStore.dataRequest.workExperience = e.currentTarget.value}
                               placeholder='Nhập kinh nghiệm làm việc'/>
                    </div>

                    <div className='mb-5'>
                        <label className='form-label'>Địa chỉ <span className='text-danger'>*</span></label>
                        <div className="w-100 d-flex justify-content-between">
                            <select className='form-control w-32' value={recruitmentStore.dataRequest.province?.id}
                                    onChange={async (e: any) => {
                                        recruitmentStore.dataRequest.province.id = e.currentTarget.value
                                        recruitmentStore.dataRequest.district.id = ''
                                        recruitmentStore.dataRequest.ward.id = ''
                                        await recruitmentStore.getDistricts(recruitmentStore.dataRequest.province.id)
                                    }}>
                                <option>Tỉnh/Thành phố</option>
                                {recruitmentStore.listProvince && recruitmentStore.listProvince.map((item, i) => {
                                    return (
                                        <option value={item.id} key={i}>{item.name}</option>
                                    )
                                })}
                            </select>
                            <select className='form-control w-32' value={recruitmentStore.dataRequest.district?.id}
                                    onChange={async (e: any) => {
                                        recruitmentStore.dataRequest.district.id = e.currentTarget.value
                                        recruitmentStore.dataRequest.ward.id = '';
                                        await recruitmentStore.getWards(recruitmentStore.dataRequest.district.id)
                                    }}>
                                <option>Quận/Huyện</option>
                                {recruitmentStore.listDistrict && recruitmentStore.listDistrict.map((item, i) => {
                                    return (
                                        <option value={item.id} key={i}>{item.name}</option>
                                    )
                                })}
                            </select>
                            <select className='form-control w-32' value={recruitmentStore.dataRequest.ward?.id}
                                    onChange={(e: any) => recruitmentStore.dataRequest.ward.id = e.currentTarget.value}>
                                <option>Phường/Xã</option>
                                {recruitmentStore.listWard && recruitmentStore.listWard.map((item, i) => {
                                    return (
                                        <option value={item.id} key={i}>{item.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div className='mb-5'>
                        <label className='form-label'>Tên đường, Tòa nhà, Số nhà <span className='text-danger'>*</span></label>
                        <input type='text' className='form-control' value={recruitmentStore.dataRequest.address}
                               onChange={(e: any) => recruitmentStore.dataRequest.address = e.currentTarget.value}
                               placeholder='Nhập Tên đường, Tòa nhà, Số nhà'/>
                    </div>
                    <div className='mb-5'>
                        <label className='form-label'>Ngành nghề <span className='text-danger'>*</span></label>
                        <select className='form-control'
                                onChange={(e: any) => recruitmentStore.dataRequest.occupation.id = e.currentTarget.value}>
                            <option value=''>Chọn</option>
                            {occupationStore.listOccupation && occupationStore.listOccupation.map((item, i) => {
                                return <option value={item.id} key={i}
                                               selected={recruitmentStore.dataRequest.occupation.id == item.id}>{item.name}</option>
                            })}
                        </select>
                    </div>

                    <div className='mb-5'>
                        <label className='form-label'>Cấp bậc <span className='text-danger'>*</span></label>
                        <select className='form-control'
                                onChange={(e: any) => recruitmentStore.dataRequest.position = e.currentTarget.value}>
                            <option value="">Chọn</option>
                            <option value="STAFF"
                                    selected={recruitmentStore.dataRequest.position == "STAFF"}>Nhân viên
                            </option>
                            <option value="MANAGE"
                                    selected={recruitmentStore.dataRequest.position == "MANAGE"}>Quản lý
                            </option>
                        </select>
                    </div>

                    <div className='mb-5'>
                        <label className='form-label'>Hình thức làm việc <span className='text-danger'>*</span></label>
                        <select className='form-control'
                                onChange={(e: any) => recruitmentStore.dataRequest.type = e.currentTarget.value}>
                            <option value={IType.FULL_TIME}
                                    selected={recruitmentStore.dataRequest.type == IType.FULL_TIME}>FULL TIME
                            </option>
                            <option value={IType.PART_TIME}
                                    selected={recruitmentStore.dataRequest.type == IType.PART_TIME}>PART TIME
                            </option>
                            <option value={IType.REMOTE}
                                    selected={recruitmentStore.dataRequest.type == IType.REMOTE}>REMOTE
                            </option>
                            <option value={IType.COLLABORATORS}
                                    selected={recruitmentStore.dataRequest.type == IType.COLLABORATORS}>Cộng tác viên
                            </option>
                        </select>
                    </div>

                    <div className='mb-5'>
                        <label className='form-label'>Mức lương</label>
                        <div className='d-flex w-100 justify-content-between'>
                            <input type='text' className='form-control w-49'
                                   value={number_format(recruitmentStore.dataRequest.salaryFrom)}
                                   onChange={(e: any) => recruitmentStore.dataRequest.salaryFrom = numberUtil.regexNumber(e)}
                                   placeholder='Từ'/>
                            <input type='text' className='form-control w-49'
                                   value={number_format(recruitmentStore.dataRequest.salaryTo)}
                                   onChange={(e: any) => recruitmentStore.dataRequest.salaryTo = numberUtil.regexNumber(e)}
                                   placeholder='Đến'/>
                        </div>
                    </div>

                    <div className='mb-5'>
                        <label className='form-label'>Hạn nộp <span className='text-danger'>*</span></label>
                        <DatePickerSingle
                            selected={recruitmentStore.dataRequest.expiredDate}
                            onChange={handleDate} placeholder='Chọn hạn nộp'/>
                    </div>

                    <div className='mb-5'>
                        <label className='form-label'>Giới tính</label>
                        <select className='form-control' onChange={(e: any) => recruitmentStore.dataRequest.gender = e.currentTarget.value}>
                            <option value={ITypeGender.MALE} selected={recruitmentStore.dataRequest.gender == ITypeGender.MALE}>Nam</option>
                            <option value={ITypeGender.FEMALE} selected={recruitmentStore.dataRequest.gender == ITypeGender.FEMALE}>Nữ</option>
                            <option value={ITypeGender.ALL} selected={recruitmentStore.dataRequest.gender == ITypeGender.ALL}>Không giới hạn</option>
                        </select>
                    </div>
                    <div className=''>
                        <label className='form-label'>Mô tả <span className='text-danger'>*</span></label>
                        <TextEditor isChooseImg={true} height={250} content={recruitmentStore.dataRequest.description}
                                    onChange={(e: any) => recruitmentStore.dataRequest.description = e}/>
                    </div>

                </div>

            </div>}
            <div className='action d-flex justify-content-end mt-10'>
                <button type='submit' className='btn btn-primary' onClick={() => recruitmentStore.addOrEdit()}
                        disabled={recruitmentStore.isLoadingBt}>
                    {recruitmentStore.isLoadingBt ? (
                        <span className='indicator-progress' style={{display: 'block'}}>
                  Vui lòng chờ...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'/>
                </span>
                    ) : 'Hoàn thành'}
                </button>
            </div>

        </div>
    )
}

export default observer(AddEditRecruitment)
