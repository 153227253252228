import {makeAutoObservable} from "mobx";
import {getRequest} from "../../helpers/RequestHelper";
import HttpStatusCode from "../../constants/HttpErrorCode";
class ChooseImgStore {
    page: number = 0
    totalPage: number = 0
    active: number = 1
    isLoading: boolean = false
    isLoadingBt: boolean = false
    listImg: any[] = []

    constructor() {
        makeAutoObservable(this)
    }

    getListImg = async (service: 'farmorg' | 'farm' | 'food' | 'life') => {
        chooseImgStore.isLoading = true
        const result = await getRequest(`/v1/storage?domain=${service}&page=${chooseImgStore.page}&size=50`, true)
        chooseImgStore.isLoading = false
        if(result.status === HttpStatusCode.OK){
            chooseImgStore.listImg = result.body.data
            chooseImgStore.totalPage = result.body.metadata.totalPage
        }
    }


}

export const chooseImgStore = new ChooseImgStore()