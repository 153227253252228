import React, {useEffect} from 'react'
import {getIDParameter, getUrlParameter} from '../../../common/utils/Utils'
import {configStore} from '../ConfigStore'
import {Link, Navigate} from 'react-router-dom'
import {observer} from 'mobx-react'
import ConfigHome from './ConfigHome'
import ConfigPolicyComplaints from './ConfigPolicyComplaints'
import {chooseImgStore} from "../../../common/components/modalChooseImg/ChooseImgStore";

const AddEditConfig = () => {
  
  const switchForm = () => {
    switch (configStore.typeConfig) {
      case 'PAGE_HOME':
        return <ConfigHome/>
      default :
        return <ConfigPolicyComplaints/>
    }
  }

  const switchName = (type: any) => {
    switch (type) {
      case 'PAGE_HOME':
        return "Cấu hình trang chủ"
      case 'TERM_POLICY':
        return "Chính sách bảo mật"
      case 'COMPLAINTS':
        return "Quy trình giải quyết kiếu nại"
      case 'GENERAL_RULES':
        return "Quy định chung"
      case 'SUGGESTIONS_COMPLAINTS':
        return "Góp ý kiếu nại"
      case 'TECHNICAL_ASSISTANCE':
        return "Hỗ trợ kỹ thuật"
      case 'USER_MANUAL':
        return "Tài liệu hướng dẫn sử dụng"
      case 'WORKING_PROCESS':
        return "Quy trình làm việc"
    }
  }

  useEffect(() => {
    let id = getIDParameter(2)
    configStore.typeConfig = id

    configStore.type = getUrlParameter('type') ?? 'add'
    if (id) {
      configStore.getDetailConfig().then()
    }
    chooseImgStore.getListImg('farmorg').then()
    return (() => {
      configStore.redirect = false
      configStore.clearForm()
    })
  }, [])



  if (configStore.redirect) {
    return <Navigate to={'/config'} />
  } else return (
    <div id='kt_ecommerce_sales_table_wrapper' className='dataTables_wrapper dt-bootstrap4'>
      <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 mb-6 pt-1'>
        <li className='breadcrumb-item'>
          <Link to='/config' className='text-primary'>Cấu hình</Link>
        </li>
        <li className='breadcrumb-item'>
          <span className='bullet bg-gray-400 w-5px h-2px' />
        </li>
        <li className='breadcrumb-item text-muted'>
          {configStore.type == 'add' ? 'Tạo' : switchName(configStore.dataRequest.id)}
        </li>
      </ul>
      <div className='d-flex align-items-center justify-content-between mb-8'>
        <h1
          className='d-flex text-dark fw-bolder fs-3 align-items-center my-1'>{configStore.type == 'add' ? 'Tạo' : 'Cập nhật'}</h1>
      </div>

      <div className='form'>
        {switchForm()}
      </div>
      
      <div className='action d-flex justify-content-end mt-10'>
        <button type='submit' className='btn btn-primary' onClick={() => configStore.addOrEdit()}
                disabled={configStore.isLoadingBt}>
          {configStore.isLoadingBt ? (
            <span className='indicator-progress' style={{display: 'block'}}>Vui lòng chờ...{' '}<span
              className='spinner-border spinner-border-sm align-middle ms-2' /></span>) : 'Hoàn thành'}
        </button>
      </div>
    </div>
  )
  
}

export default observer(AddEditConfig)