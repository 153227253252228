import axios from 'axios'
import {AuthModel, UserModel} from './_models'
import {postRequest} from "../../../common/helpers/RequestHelper";
const API_URL = process.env.REACT_APP_API_URL
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/sso/v1/auth/valid-token`
export const GET_USER_ME = `${API_URL}/v1/users/me`
export const LOGIN_URL = `${API_URL}/sso/v1/auth/login`
export const REGISTER_URL = `${API_URL}/v1/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/v1/auth/forgot_password`


// Server should return AuthModel
export function login(username: string, password: string, service: string) {
  // return axios.post<AuthModel>(LOGIN_URL, {
  //   username,
  //   password,
  // })
  return postRequest(`/sso/v1/auth/login`, {username, password, service})
}

// Server should return AuthModel
export function register(
  username: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    username,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: any) {
  var newHeaders: any;
  newHeaders = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token
  }
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {headers: newHeaders})


}

// export function getProfile() {
//   var newHeaders: any;
//
//   newHeaders = {
//     'Content-Type': 'application/json',
//     Authorization: 'Bearer ' + StorageService.getToken()
//   }
//
//   return axios.get<UserModel>(GET_USER_ME, {headers: newHeaders})
// }

