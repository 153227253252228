import {occupationStore} from './OccupationStore'
import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from '../../../common/helpers/RequestHelper'

class OccupationService {

  public getOccupation(size?: any): Promise<IApiResponse>{
    return getRequest(`/omfarm/v1/occupation?page=${occupationStore.page}&size=${size ?? 10}${occupationStore.keyword ? `&keyword=${occupationStore.keyword}` : ''}`)
  }

  public getDetail(id: any): Promise<IApiResponse>{
    return getRequest(`/omfarm/v1/occupation/${id}`)
  }


  public add(data: any): Promise<IApiResponse>{
    return postRequest(`/omfarm/v1/occupation`, data)
  }

  public update(id: any, data: any): Promise<IApiResponse>{
    return putRequest(`/omfarm/v1/occupation/${id}`, data)
  }


  public delete(id: any): Promise<IApiResponse>{
    return deleteRequest(`/omfarm/v1/occupation/${id}`, {})
  }

}

export const occupationService = new OccupationService()

