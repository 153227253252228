import {makeAutoObservable} from 'mobx'
import $ from 'jquery'
import {toastUtil} from '../../common/utils/ToastUtil'
import {blogService} from './BlogService'


class BlogStore {
  id: any = ''
  page: any = 0
  type: any = ''
  keyword: any = ''
  redirect: boolean = false
  blogCategoryId: any = null
  totalPage: any = 0
  listBlog: any[] = []
  dataRequest: any = {
    id: '',
    blogCategory: {
      id: null
    },
    title: '',
    introduce: {
      special: '',
      image: ''
    },
    operation: {
      title: '',
      item: [],
    }
  }
  isLoading: boolean = false
  isLoadingBt: boolean = false
  isLoadingDetail: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  clearForm() {
    this.isLoadingDetail = true
    this.type = 'add'
    this.dataRequest = {
      id: '',
      title: '',
      blogCategory: {
        id: null
      },
      introduce: {
        image: '',
        special: '',
      },
      operation: {
        title: '',
        item: [],
      }
    }
    setTimeout(() => {
      this.isLoadingDetail = false
    }, 100)
  }


  async getBlog() {
    this.isLoading = true
    const result = await blogService.getBlog()
    this.isLoading = false
    if (result.status === 200) {
      this.listBlog = result.body.data
      this.totalPage = result.body.metadata.totalPage
    }
  }


  async getBlogDetail(id: any) {
    this.type = 'edit'
    this.isLoadingDetail = true
    const result = await blogService.getDetailBlog(id)
    if (result.status === 200) {
      this.dataRequest = result.body
      if(!this.dataRequest.operation.item){
        this.dataRequest.operation.item = []
      }
      this.isLoadingDetail = false
    }
  }

  async changeStatus(item: any) {

    let data = {
      ids: [item.id],
      status: item.status == 'HIDE' ? 'PUBLIC' : 'HIDE',
    }
    this.isLoadingBt = true
    const result = await blogService.changeStatus(data)
    this.isLoadingBt = false
    if (result.status === 200) {
      toastUtil.success('Cập nhật trạng thái thành công!')
      item.status = data.status
    } else toastUtil.error(result.body.message ?? 'Đã có lỗi xảy ra!')

  }

  async addOrEdit() {

    let {id, title, blogCategory, introduce, operation} = this.dataRequest

    let data: any = {
      blogCategoryId: blogCategory.id,
      title: title,
      content: [{title: '', content: ''}],
      introduce: introduce,
      operation: operation
    }

    if (!introduce.image) {
      toastUtil.warning('Hình minh họa không được để trống!')
      return
    }

    if (!title) {
      toastUtil.warning('Tiêu đề không được để trống!')
      return
    }

    if (!data.blogCategoryId) {
      toastUtil.warning('Danh mục không được để trống!')
      return
    }

    this.isLoadingBt = true
    let result: any

    if (this.type === 'add') {
      result = await blogService.addBlog(data)
    } else {
      result = await blogService.updateBlog(id, data)
    }

    this.isLoadingBt = false
    if (result.status === 200) {
      toastUtil.success(`${this.type == 'add' ? 'Thêm' : 'Cập nhật'} blog thành công!`)
      this.clearForm()
      this.redirect = true
    } else toastUtil.error(result.body.message ?? 'Đã có lỗi xảy ra')

  }

  async delete() {
    this.isLoadingBt = true
    const result = await blogService.deleteBlog(this.id)
    this.isLoadingBt = false
    if (result.status === 200) {
      toastr.success('Xóa blog thành công!')
      $('#close_delete_blog').trigger('click')
      await this.getBlog()
    } else toastr.error(result.body.message ?? 'Đã có lỗi xảy ra')
  }


}

export const blogStore = new BlogStore()