import React from 'react'
import uploadFileHelper from "../helpers/UploadFileHelper";
import {urlImage} from "../utils/Utils";
import {observer} from "mobx-react";

interface IProps {
  listImg: string[],
  changeImg: any,
  removeImg: any
}


const UploadMultiImg = ({listImg, changeImg, removeImg}: IProps) => {

  return (
      <div className='upLoadImage upLoadMultiImage w-100 d-flex'>
        {listImg && listImg.length > 0 ? <div className='d-flex align-items-center'>
          {listImg.map((item: any, i: number) => {
            return <div className="position-relative" key={i} style={{marginRight: 16}}>
              {typeof item === "string" ? <img src={urlImage(item)} key={i} alt='' /> : <img src={urlImage(item.url)} key={i} alt='' />}
              <span onClick={() => removeImg(i)} style={{right: -9, top: -9}} className="btn rm_img position-absolute btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                    data-kt-image-input-action="remove" data-bs-toggle="tooltip" title=""
                    data-bs-original-title="Remove Image">
								<i className="bi bi-x fs-2"/>
						</span>
            </div>
          })}
        </div> : <div className='no_img d-flex align-items-center justify-content-center'><i className='far fa-image' /></div>}
        {listImg && listImg.length < 3 && <button className='btn change-avatar'><span>Upload</span>
          <input accept='image/jpeg, image/jpg, image/png' type='file'
                 onChange={async (e: any) => await uploadFileHelper(e, changeImg)} />
        </button>}
      </div>
  )
}

export default observer(UploadMultiImg)