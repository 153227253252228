import {makeAutoObservable} from 'mobx'
import $ from 'jquery'
import {occupationService} from './OccupationService'
import {toastUtil} from '../../../common/utils/ToastUtil'

class OccupationStore {
  page: number = 0
  totalPage: number = 0
  typeModal: any = ''
  state: any = ''
  id: any = ''
  keyword: any = ''
  redirect: boolean = false
  isLoading: boolean = false
  isLoadingBt: boolean = false
  isLoadingDetail: boolean = false
  listOccupation: any[] = []
  dataRequest: any = {
    name: '',
    image: '',
    parentId: null
  }


  constructor() {
    makeAutoObservable(this)
  }

  clearForm() {
    occupationStore.typeModal = 'add'
    occupationStore.dataRequest = {
      name: '',
      image: '',
      parentId: null
    }
  }

  async getOccupation(size?: any) {
    occupationStore.isLoading = true
    const result = await occupationService.getOccupation(size)
    occupationStore.isLoading = false
    if(result.status == 200){
      occupationStore.listOccupation = result.body.data
      occupationStore.totalPage = result.body.metadata.totalPage
    }
  }


  async getDetail(id: any) {
    occupationStore.typeModal = 'edit'
    const result = await occupationService.getDetail(id)
    if (result.status == 200) {
      occupationStore.dataRequest = result.body
    }
  }

  async addOrEdit() {

    let {id, name} = occupationStore.dataRequest

    let data: any = {
      name: name
    }

    if (!name) {
      toastUtil.warning('Tên không được để trống!')
      return
    }

    occupationStore.isLoadingBt = true
    let result: any

    if (occupationStore.typeModal === 'add') {
      result = await occupationService.add(data)
    } else {
      result = await occupationService.update(id, data)
    }

    occupationStore.isLoadingBt = false
    if (result.status === 200) {
      toastUtil.success(`${occupationStore.typeModal == 'add' ? 'Thêm' : 'Cập nhật'} ngành nghề thành công!`)
      $('#close_add_edit_occupation').trigger('click')
      occupationStore.clearForm()
      await occupationStore.getOccupation()
    } else toastUtil.error(result.body.message ?? 'Đã có lỗi xảy ra')

  }

  async delete() {
    occupationStore.isLoadingBt = true
    const result = await occupationService.delete(occupationStore.id)
    occupationStore.isLoadingBt = false
    if (result.status < 300) {
      toastr.success('Xóa ngành nghề thành công!')
      $('#close_delete').trigger('click')
      await occupationStore.getOccupation()
    } else toastr.error(result.body.message ?? 'Đã có lỗi xảy ra')
  }


}

export const occupationStore = new OccupationStore()