import React from 'react'
import {observer} from 'mobx-react-lite'
import {shopStore} from '../ShopStore'
import UploadImg from "../../../../common/components/UploadImg";


const AddFactory = observer(() => {


  return (
    <div className='modal fade' id='addFactory' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered mw-900px'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>Tạo cơ sở sản xuất</h2>
            <button type="button" id="close_add_edit_factory" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
          </div>

          <div className='modal-body py-lg-10 px-lg-10'>
            <form noValidate className='form'>
              <div className='mb-5'>
                <label className='form-label w-100'>Ảnh</label>
                <UploadImg src={shopStore.dataFactory.image} changeImg={shopStore.changeImg}/>
              </div>

              <div className='mb-5'>
                <label className='form-label'>Tên cơ sở sản xuất</label>
                <input type='text' className='form-control' value={shopStore.dataFactory.name}
                       onChange={(e: any) => shopStore.dataFactory.name = e.currentTarget.value}
                       placeholder='Tên cơ sở sản xuất' />
              </div>
            </form>
          </div>

          <div className='modal-footer d-flex justify-content-end py-6 px-9'>
            <button type='reset' className='btn btn-light btn-active-light-primary me-2' data-bs-dismiss='modal'>Hủy
            </button>
            <button type='submit' className='btn btn-primary' onClick={() => shopStore.addFactory()} disabled={shopStore.isLoadingBt}>
              {shopStore.isLoadingBt ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Vui lòng chờ...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </span>) : 'Add'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
})

export default AddFactory