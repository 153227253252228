import {makeAutoObservable} from 'mobx'
import $ from 'jquery'
import {bannerService} from './BannerService'
import HttpStatusCode from "../../../../common/constants/HttpErrorCode";
import {toastUtil} from "../../../../common/utils/ToastUtil";

interface IData {
  id: any,
  sort: any,
  image: string,
  type: string,
  imageMobile: string,
  linkTo: string,
  content: string,
}

class BannerStore {
  id: any = ''
  file: any = ''
  title: any = ''
  listBanner: any[] = []
  dataRequest: IData = {
    id: '',
    sort: '',
    image: '',
    type: 'MAIN' || 'SUB',
    imageMobile: '',
    linkTo: '#',
    content: '',
  }
  isLoadingUpload: boolean = false
  isLoading: boolean = false
  isLoadingBt: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  clearForm() {
    this.file = ''
    this.dataRequest = {
      id: '',
      sort: '',
      image: '',
      type: 'MAIN' || 'SUB',
      imageMobile: '',
      linkTo: '#',
      content: '',
    }
    this.title = 'Tạo mới'
  }


  async getBanner() {
    this.isLoading = true
    const result = await bannerService.getBanner()
    this.isLoading = false
    if (result.status === HttpStatusCode.OK) {
      console.log(result.body)
      this.listBanner = result.body.mAIN
    }
  }

  async changeImg(value: any) {
    bannerStore.dataRequest.image = value
  }


  async changeImgMb(value: any) {
    bannerStore.dataRequest.imageMobile = value
  }


  getBannerDetail(items: any) {
    this.title = 'Cập nhật'
    this.dataRequest = {...items}
  }

  async addOrUpdate() {

    let {id, imageMobile, sort, image, linkTo, content} = this.dataRequest

    let data: any = {
      id: id,
      sort: sort,
      image: image,
      type: 'MAIN',
      imageMobile: imageMobile,
      linkTo: linkTo,
      content: content,
    }

    this.isLoadingBt = true
    const result = await bannerService.addOrUpdate(data)
    this.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      toastUtil.success(this.title == "Tạo mới" ? "Thêm" : 'Cập nhật' + 'banner thành công!')
      $('#close_modal').trigger('click')
      this.clearForm()
      await this.getBanner()
    }
    else toastUtil.error(result.body.message)
  }


  async delete() {
    bannerStore.isLoadingBt = true
    const result = await bannerService.deleteBanner(this.id)
    bannerStore.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      toastUtil.success('Xóa banner thành công!')
      $('#close_delete').trigger('click')
      await bannerStore.getBanner()
    }
    else toastUtil.error(result.body.message ?? 'Đã có lỗi xảy ra')
  }

}

export const bannerStore = new BannerStore()