import {getRequest, IApiResponse} from '../../common/helpers/RequestHelper'

class ProfileService {

  public getProfile(): Promise<IApiResponse>{
    return getRequest(`/sso/v1/user`)
  }

}

export const profileService = new ProfileService();