import {makeAutoObservable} from 'mobx'
import {toastUtil} from '../../common/utils/ToastUtil'
import {configService} from './ConfigService'

interface IDataRequest {
  id: string,
  content: {
    title: '',
    subTitle: '',
    term: '',
    banner: {
      title: '',
      title1: '',
      description: '',
      link: '',
      image: '',
    },
    block1: {
      description: '',
      link: '',
      image: '',
    },
    block2: {
      description: '',
      image: '',
    },
    proService: {
      title: '',
      description: '',
      info: [
        {
          name: '',
          icon: '',
          image: '',
          link: '',
        },
      ],
    },
    block3: {
      title: '',
      link: '',
    },
    flashSale: {
      title: '',
      image: '',
      info: [
        {
          name: '',
          icon: '',
          image: '',
          link: '',
        }
      ],
    },
    footer: {
      title: '',
      phone: '',
      email: '',
      linkFacebook: '',
      linkYoutube: '',
      linkTiktok: '',
      image: '',
      linkInfo: [
        {
          title: '',
          items: [
            {
              name: '',
              target: '',
            },
          ],
        }
      ]
    },
  },
}

class ConfigStore {
  id: any = ''
  page: any = 0
  type: any = ''
  file: any = ''
  typeConfig: any = 'PAGE_HOME'
  totalPage: any = 0
  listConfig: any[] = []
  listType: any[] = [
    {id: 'PAGE_HOME', name: 'Cấu hình trang chủ'},
    {id: 'COMPLAINTS', name: 'Quy trình giải quyết kiếu nại'},
    {id: 'TERM_POLICY', name: 'Chính sách bảo mật'},
    {id: 'TECHNICAL_ASSISTANCE', name: 'Hỗ trợ kỹ thuật'},
    {id: 'GENERAL_RULES', name: 'Quy định chung'},
    {id: 'SUGGESTIONS_COMPLAINTS', name: 'Góp ý kiếu nại'},
    {id: 'USER_MANUAL', name: 'Tài liệu hướng dẫn sử dụng'},
    {id: 'WORKING_PROCESS', name: 'Quy trình làm việc'}
  ]
  dataRequest: IDataRequest = {
    id: this.typeConfig,
    content: {
      title: '',
      subTitle: '',
      term: '',
      banner: {
        title: '',
        title1: '',
        description: '',
        link: '',
        image: '',
      },
      block1: {
        description: '',
        link: '',
        image: '',
      },
      block2: {
        description: '',
        image: '',
      },
      proService: {
        title: '',
        description: '',
        info: [
          {
            name: '',
            icon: '',
            image: '',
            link: '',
          },
        ],
      },
      block3: {
        title: '',
        link: '',
      },
      flashSale: {
        title: '',
        image: '',
        info: [
          {
            name: '',
            icon: '',
            image: '',
            link: '',
          },
        ],
      },
      footer: {
        title: '',
        phone: '',
        email: '',
        linkFacebook: '',
        linkYoutube: '',
        linkTiktok: '',
        image: '',
        linkInfo: [
          {
            title: '',
            items: [
              {
                name: '',
                target: '',
              },
            ],
          }
        ]
      },
    },
  }
  redirect: boolean = false
  isLoading: boolean = false
  isLoadingBt: boolean = false
  isLoadingDetail: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  clearForm() {
    this.type = 'add'
    this.typeConfig = ''
    this.dataRequest = {
      id: this.typeConfig,
      content: {
        title: '',
        subTitle: '',
        term: '',
        banner: {
          title: '',
          title1: '',
          description: '',
          link: '',
          image: '',
        },
        block1: {
          description: '',
          link: '',
          image: '',
        },
        block2: {
          description: '',
          image: '',
        },
        proService: {
          title: '',
          description: '',
          info: [
            {
              name: '',
              icon: '',
              image: '',
              link: '',
            },
          ],
        },
        block3: {
          title: '',
          link: '',
        },
        flashSale: {
          title: '',
          image: '',
          info: [
            {
              name: '',
              icon: '',
              image: '',
              link: '',
            },
          ],
        },
        footer: {
          title: '',
          phone: '',
          email: '',
          linkFacebook: '',
          linkYoutube: '',
          linkTiktok: '',
          image: '',
          linkInfo: [
            {
              title: '',
              items: [
                {
                  name: '',
                  target: '',
                },
              ],
            }
          ]
        },
      },
    }
  }

  async getConfig() {
    this.isLoading = true
    const result = await configService.getConfig()
    this.isLoading = false
    if (result.status === 200) {
      this.listConfig = result.body.data
      this.listConfig && this.listConfig.map((item) => {
        this.listType && this.listType.map((val, i) => {
          if(item.id == val.id){
            this.listType.splice(i, 1)
          }
        })
      })
      this.totalPage = result.body.metadata.totalPage
    }
  }

  async getDetailConfig() {
    this.isLoadingDetail = true
    const result = await configService.getDetailConfig(this.typeConfig)
    if (result.status === 200) {
      this.dataRequest = result.body
      this.isLoadingDetail = false
    }
  }

  uploadImage(){

  }

  async addOrEdit() {
    let data: any

    if(this.type === 'add' && !this.dataRequest.id){
      toastUtil.warning('Vui lòng chọn loại cấu hình'!)
      return
    }

    if(this.typeConfig !== 'PAGE_HOME'){
      data = {
        id: this.dataRequest.id,
        content: {
          title: this.dataRequest.content.title,
          subTitle: this.dataRequest.content.subTitle,
          term: this.dataRequest.content.term
        }
      }

      if(!this.dataRequest.content.title){
        toastUtil.warning('Tiêu đề không được để trống'!)
        return
      }

      if(!this.dataRequest.content.term){
        toastUtil.warning('Nội dung không được để trống'!)
        return
      }
    }else data = this.dataRequest


    this.isLoadingBt = true
    const result = this.type === 'add' ? await configService.addConfig(data) : await configService.editConfig(data)
    this.isLoadingBt = false
    if (result.status === 200) {
      toastUtil.success(`${this.type == 'add' ? 'Thêm' : 'Cập nhật'} config thành công!`)
      this.clearForm()
      this.redirect = true
    } else toastUtil.error(result.body.message ?? 'Đã có lỗi xảy ra')

  }

}

export const configStore = new ConfigStore();