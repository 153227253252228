import {makeAutoObservable, observable} from 'mobx'
import {getRequest, putRequest} from '../../../common/helpers/RequestHelper'
import HttpStatusCode from '../../../common/constants/HttpErrorCode'
import {toastUtil} from "../../../common/utils/ToastUtil";

enum IStatus {
  NOT_VIEWED = 'NOT_VIEWED',
  VIEWED = 'VIEWED',
  UNDER_REVIEW = 'UNDER_REVIEW',
  INTERVIEWED = 'INTERVIEWED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED'
}


class ApplyStore {
  urlViewer: any = ''
  status: any = ''
  keyword: any = ''
  page: number = 0
  totalPage: number = 0
  isLoading: boolean = false
  isLoadingBt: boolean = false
  listApply: any[] = []


  constructor() {
    makeAutoObservable(this)
  }

  async getApply() {
    this.isLoading = true
    const result = await getRequest(`/omfarm/v1/job_apply?page=${this.page}&size=10${this.status ? `&status=${this.status}` : ''}${this.keyword ? `&keyword=${this.keyword.trim()}` : ''}`)
    this.isLoading = false
    if(result.status === HttpStatusCode.OK){
      this.listApply = result.body.data
      this.totalPage = result.body.metadata.totalPage
    }
  }

  async changeStatus(item: any, status: any){

    let data = {
      status: status
    }

    this.isLoadingBt = true
    const result = await putRequest(`/omfarm/v1/job_apply/${item.id}/status`, data)
    this.isLoadingBt = false
    if(result.status == 200){
      toastUtil.success('Cập nhật trạng thái thành công!')
      this.listApply.map((val) => {
        if(val.id == item.id){
          item.status = data.status
        }
      })
    }else {
      toastUtil.error(result.body.message ?? 'Đã có lỗi xảy ra!')
    }
  }


}

export const applyStore = new ApplyStore();