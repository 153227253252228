import React, {useEffect} from "react"
import {observer} from "mobx-react"
import {getIDParameter, getUrlParameter, urlImage} from "../../../common/utils/Utils"
import TextEditor from "../../../common/components/TextEditor"
import Loading from "../../../common/components/Loading"
import {Link, Navigate} from "react-router-dom"
import {standardStore} from "../StandardStore";
import UploadChooseImg from "../../../common/components/modalChooseImg/UploadChooseImg";
import {chooseImgStore} from "../../../common/components/modalChooseImg/ChooseImgStore";
import uploadFileHelper from "../../../common/helpers/UploadFileHelper";

const AddEditStandard = () => {

    const changeImg = (value: any) => {
        standardStore.dataRequest.thumbnail = value
    }

    const changeImgIntroduce = (value: any) => {
        standardStore.dataRequest.introduce.image = value
    }


    useEffect(() => {
        let id = getIDParameter()
        standardStore.type = getUrlParameter("type") ?? "add"
        if (id) {
            standardStore.getPostDetail(id).then()
        }
        chooseImgStore.getListImg('farmorg').then()
        return (() => {
            standardStore.redirect = false
        })
    }, [])


    if (standardStore.redirect) {
        return <Navigate to={"/standard"}/>
    } else return (
        <div id="kt_ecommerce_sales_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 mb-6 pt-1">
                <li className="breadcrumb-item">
                    <Link to="/standard" className="text-primary">Bài viết</Link>
                </li>
                <li className="breadcrumb-item">
                    <span className="bullet bg-gray-400 w-5px h-2px"/>
                </li>

                <li className="breadcrumb-item text-muted">
                    {standardStore.type == "add" ? "Tạo bài viết" : standardStore.dataRequest.title}
                </li>
            </ul>

            <div className="d-flex align-items-center justify-content-between mb-8">
                <h1 className="d-flex text-dark fw-bolder fs-3 align-items-center my-1">{standardStore.type == "add" ? "Tạo" : "Cập nhật"} bài
                    viết</h1>
            </div>
            <UploadChooseImg service="farmorg" changeImg={changeImg}/>
            {standardStore.isLoadingDetail ? <Loading/> : <div className="form">

                <div className="border_bottom pb-8">
                    <a data-bs-toggle="collapse" className="d-flex justify-content-between align-items-center"
                       href="#content_main">
                        <h4 className="font-bold mb-0">Content</h4>
                        <div className="line"/>
                        <i className="fas fa-chevron-down" style={{fontSize: 18}}/>
                    </a>
                    <div className="collapse show mt-8" id="content_main" style={{padding: `0 24px`}}>
                        <div className="mb-5">
                            <label className="form-label w-100">Banner (1500x250)</label>
                            <div className="upLoadImageCategory w-100">
                                {standardStore.dataRequest.thumbnail ?
                                    <img src={urlImage(standardStore.dataRequest.thumbnail)} alt=""/> :
                                    <div className="no_img d-flex align-items-center justify-content-center"><i
                                        className="far fa-image"/></div>}
                                <button className="btn change-avatar pl-0 pt-5" data-bs-toggle='modal'
                                        data-bs-target='#modal_upload_choose_img'>
                                    <span>Upload</span>
                                </button>
                            </div>
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Tiêu đề <span className="text-danger">*</span></label>
                            <input type="text" className="form-control" value={standardStore.dataRequest.title}
                                   onChange={(e: any) => standardStore.dataRequest.title = e.currentTarget.value}
                                   placeholder="Nhập tiêu đề"/>
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Loại <span className="text-danger">*</span></label>
                            <select className="form-control" value={standardStore.dataRequest.type}
                                    onChange={(e: any) => standardStore.dataRequest.type = e.currentTarget.value}>
                                {standardStore.listType.map((item, i) => {
                                    return (<option value={item.value} key={i}>{item.name}</option>)
                                })}
                            </select>
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Mô tả ngắn</label>
                            <textarea rows={4} className="form-control" value={standardStore.dataRequest.shortContent}
                                      placeholder="Nhập mô tả ngắn"
                                      onChange={(e: any) => standardStore.dataRequest.shortContent = e.currentTarget.value}/>
                        </div>
                    </div>
                </div>

                <div className="border_bottom pb-8 mt-8">
                    <a data-bs-toggle="collapse" className="d-flex justify-content-between align-items-center"
                       href="#content_header">
                        <h4 className="font-bold mb-0">Nổi bật</h4>
                        <div className="line"/>
                        <i className="fas fa-chevron-down" style={{fontSize: 18}}/>
                    </a>

                    <div className="collapse mt-8" id="content_header" style={{padding: `0 24px`}}>
                        <div className="mb-5">
                            <label className="form-label w-100">Thumbnail (420x250)</label>
                            <div className="upLoadImage w-100 d-flex align-items-center">
                                {standardStore.dataRequest.introduce?.image ?
                                    <img style={{width: 420, height: 250}}
                                         src={urlImage(standardStore.dataRequest.introduce?.image)}
                                         alt=""/> :
                                    <div style={{width: 420, height: 250}}
                                         className="no_img d-flex align-items-center justify-content-center"><i
                                        className="far fa-image"/></div>}
                                <button className="btn change-avatar mt-0"><span>Upload</span>
                                    <input accept="image/jpeg, image/jpg, image/png, image/svg" type="file"
                                           onChange={async (e: any) => await uploadFileHelper(e, changeImgIntroduce)}/>
                                </button>
                            </div>
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Tiêu đề</label>
                            <input type="text" className="form-control"
                                   value={standardStore.dataRequest.introduce?.title}
                                   onChange={(e: any) => standardStore.dataRequest.introduce.title = e.currentTarget.value}
                                   placeholder="Nhập tiêu đề "/>
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Nội dung</label>
                            <TextEditor isChooseImg={true} idModal="modal_choose_img_top" height={250}
                                        content={standardStore.dataRequest.introduce?.special}
                                        onChange={(e: any) => standardStore.dataRequest.introduce.special = e}/>
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Số điện thoại liên hệ</label>
                            <input type="text" className="form-control"
                                   value={standardStore.dataRequest.introduce?.phone}
                                   onChange={(e: any) => standardStore.dataRequest.introduce.phone = e.currentTarget.value}
                                   placeholder="Nhập SDT"/>
                        </div>
                        <div className="mb-5">
                            <div className="d-flex align-items-center justify-content-between">
                                <h5 className="font-bold mb-4">Nút bấm</h5>
                                <button className="btn btn-sm btn-primary"
                                        onClick={() => standardStore.dataRequest.introduce.action.push({
                                            title: "",
                                            description: ""
                                        })}>+ Add
                                </button>
                            </div>
                            {standardStore.dataRequest.introduce.action && standardStore.dataRequest.introduce.action.map((item: any, i: number) => {
                                return (<div className="d-flex mb-4 justify-content-between align-items-center" key={i}>
                                    <div className="w-30">
                                        <label className="form-label">Tiêu đề</label>
                                        <input type="text" className="form-control" value={item.title}
                                               onChange={(e: any) => item.title = e.currentTarget.value}
                                               placeholder="Nhập tiêu đề"/>
                                    </div>
                                    <div className="w-30">
                                        <label className="form-label">Mô tả</label>
                                        <input type="text" className="form-control" value={item.description}
                                               onChange={(e: any) => item.description = e.currentTarget.value}
                                               placeholder="Nhập mô tả"/>
                                    </div>
                                    <div className="w-30">
                                        <label className="form-label">Link</label>
                                        <input type="text" className="form-control" value={item.link}
                                               onChange={(e: any) => item.link = e.currentTarget.value}
                                               placeholder="Nhập link"/>
                                    </div>
                                    <i className="fas text-danger fa-trash-alt cursor-pointer"
                                       style={{fontSize: 20, marginTop: 28}}
                                       onClick={() => standardStore.dataRequest.introduce.action.splice(i, 1)}/>

                                </div>)
                            })}
                        </div>
                    </div>
                </div>

                <div className="border_bottom pb-8 mt-8">

                    <a data-bs-toggle="collapse" className="d-flex justify-content-between align-items-center"
                       href="#tab_post">
                        <h4 className="font-bold mb-0">Tab</h4>
                        <div className="line"/>
                        <i className="fas fa-chevron-down" style={{fontSize: 18}}/>
                    </a>
                    <div className="collapse mt-8" id="tab_post" style={{padding: `0 24px`}}>
                        <div className="d-flex mb-4 mt-6 align-items-center justify-content-end">
                            <button className="btn btn-sm btn-primary"
                                    onClick={() => standardStore.dataRequest.content.push({title: "", content: ""})}>+
                                Add Tab
                            </button>
                        </div>

                        <ul className="nav nav-tabs" id="header_tab" role="tab_list">
                            {standardStore.dataRequest.content && standardStore.dataRequest.content.map((item: any, index: number) => {
                                return (
                                    <li className="nav-item position-relative" role="presentation" key={index}
                                        style={{minWidth: 120}}>
                                        <button className={`nav-link w-100 ${index == 0 ? "active" : ""}`}
                                                id={`tab_post_${index}`} data-bs-toggle="tab"
                                                data-bs-target={`#tabPost${index}`} aria-controls={`tabPost${index}`}
                                                type="button" role="tab">
                                            Tab {item.title ? item.title : index + 1}
                                            {index > 0 &&
                                            <i className="fas text-danger fa-times cursor-pointer position-absolute"
                                               style={{fontSize: 16, top: -5, right: -5, zIndex: 1000}}
                                               onClick={() => standardStore.dataRequest.content.splice(index, 1)}/>}
                                        </button>
                                    </li>
                                )
                            })}
                        </ul>
                        <div className="tab-content pl-0 pr-0" style={{paddingTop: 24}} id="tab_content_post">
                            {standardStore.dataRequest.content && standardStore.dataRequest.content.map((item: any, index: number) => {
                                return (
                                    <div className={`tab-pane fade ${index == 0 ? "show active" : ""}`}
                                         id={`tabPost${index}`} role="tabpanel" aria-labelledby={`tab_post_${index}`}
                                         key={index}>
                                        <div className="mb-5">
                                            <label className="form-label">Tab</label>
                                            <input type="text" className="form-control" value={item.title}
                                                   onChange={(e: any) => item.title = e.currentTarget.value}
                                                   placeholder="Nhập tiêu đề"/>
                                        </div>
                                        <div>
                                            <label className="form-label">Nội dung</label>
                                            <TextEditor isChooseImg={true} idModal={`modal_choose_img_tab_${index}`}
                                                        height={250} content={item.content}
                                                        onChange={(e: any) => item.content = e}/>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <div className="mt-8">
                    <a data-bs-toggle="collapse" className="d-flex justify-content-between align-items-center"
                       href="#operation">
                        <h4 className="font-bold mb-0">Đối tượng</h4>
                        <div className="line"/>
                        <i className="fas fa-chevron-down" style={{fontSize: 18}}/>
                    </a>
                    <div className="collapse mt-8" style={{padding: `0 24px`}} id="operation">
                        <div className="mb-5">
                            <label className="form-label">Tiêu đề</label>
                            <input type="text" className="form-control"
                                   value={standardStore.dataRequest.operation.title}
                                   onChange={(e: any) => standardStore.dataRequest.operation.title = e.currentTarget.value}
                                   placeholder="Nhập tiêu đề"/>
                        </div>
                        <div className="d-flex justify-content-end mb-4">
                            <button className="btn btn-sm btn-primary"
                                    onClick={() => standardStore.dataRequest.operation.item.push(
                                        {title1: "", title2: "", description: "", action: [{title: "", link: ""}]}
                                    )}>Thêm đối tượng
                            </button>
                        </div>
                        {standardStore.dataRequest.operation.item && standardStore.dataRequest.operation.item.map((item: any, index: number) => {
                            return (
                                <div className="d-flex mb-8 justify-content-between symbol bg-light p-4" key={index}>
                                    <div style={{width: `96%`}}>
                                        <div className="mb-5">
                                            <label className="form-label">Tiêu đề 1</label>
                                            <input type="text" className="form-control" value={item.title1}
                                                   onChange={(e: any) => item.title1 = e.currentTarget.value}
                                                   placeholder="Nhập tiêu đề 1"/>
                                        </div>
                                        <div className="mb-5">
                                            <label className="form-label">Tiêu đề 2</label>
                                            <input type="text" className="form-control" value={item.title2}
                                                   onChange={(e: any) => item.title2 = e.currentTarget.value}
                                                   placeholder="Nhập tiêu đề 2"/>
                                        </div>
                                        <div className="mb-5">
                                            <label className="form-label">Mô tả</label>
                                            <input type="text" className="form-control" value={item.description}
                                                   onChange={(e: any) => item.description = e.currentTarget.value}
                                                   placeholder="Nhập mô tả"/>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h5 className="font-bold mb-4">Nút bấm</h5>
                                            <button className="btn btn-sm btn-primary"
                                                    onClick={() => item.action.push({title: "", link: ""})}>+
                                                Thêm nút bấm
                                            </button>
                                        </div>
                                        {item.action && item.action.map((val: any, i: number) => {
                                            return (
                                                <div className="d-flex align-items-center justify-content-between mb-4"
                                                     key={i}>
                                                    <div className="w-48">
                                                        <label className="form-label">Tiêu đề</label>
                                                        <input type="text" className="form-control" value={val.title}
                                                               onChange={(e: any) => val.title = e.currentTarget.value}
                                                               placeholder="Nhập tiêu đề"/>
                                                    </div>
                                                    <div className="w-48">
                                                        <label className="form-label">Link</label>
                                                        <input type="text" className="form-control" value={val.link}
                                                               onChange={(e: any) => val.link = e.currentTarget.value}
                                                               placeholder="Nhập link"/>
                                                    </div>
                                                    <i className="fas text-danger fa-trash-alt cursor-pointer"
                                                       style={{fontSize: 20, marginTop: 28}}
                                                       onClick={() => item.action.splice(i, 1)}/>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <i className="fas text-danger fa-trash-alt cursor-pointer"
                                       style={{fontSize: 20, marginTop: 28, marginRight: 10}}
                                       onClick={() => standardStore.dataRequest.operation.item.splice(index, 1)}/>
                                </div>
                            )
                        })}
                    </div>
                </div>

            </div>}
            <div className="action d-flex justify-content-end mt-10">
                <button type="submit" className="btn btn-primary" onClick={() => standardStore.addOrEdit()}
                        disabled={standardStore.isLoadingBt}>
                    {standardStore.isLoadingBt ? (
                        <span className="indicator-progress" style={{display: "block"}}>
                  Vui lòng chờ...{" "}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"/>
                </span>
                    ) : "Hoàn thành"}
                </button>
            </div>

        </div>
    )
}

export default observer(AddEditStandard)
