import React, {useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import {productStore} from './ProductStore'
import {Link} from 'react-router-dom'
import {number_format, slug, sttPage, urlImage} from "../../../common/utils/Utils";
import Paginate from "../../../common/components/Paginate";
import PopupConfirm from "../../../common/components/PopupConfirm";
import Loading from "../../../common/components/Loading";
import NoContent from "../../../common/components/NoContent";
import {shopStore} from "../shops/ShopStore";
import {categoryStore} from "../categories/CategoryStore";



const ProductWrapper = () => {


    const handlePageClick = async (data: any) => {
        productStore.page = data.selected;
        await productStore.getProducts();
    };

    const returnState = (state: any) => {
        switch (state) {
            case 'PUBLIC':
                return <div className="badge badge-light-primary">Đã duyệt</div>
            case 'PENDING':
                return <div className="badge badge-light-warning">Chờ duyệt</div>
            case 'REJECT':
                return <div className="badge badge-light-danger">Từ chối</div>
        }
    }

    useEffect(() => {
        productStore.getProducts().then();
        shopStore.getShops(1000).then()
        categoryStore.searchLevel = 2
        categoryStore.getCate(1000).then()
        return () => {
            productStore.filter_shopId = ''
            productStore.state = ''
            productStore.categoryId = ''
            productStore.keyword = ''
            categoryStore.searchLevel = ''
        }
    }, [])

    return (
        <>

            <div className='d-flex align-items-center justify-content-between mb-8'>
                <h1 className='d-flex text-dark fw-bolder fs-3 align-items-center my-1'>Danh sách sản phẩm</h1>
                <Link to={`/store/add-product`} className='btn btn-sm btn-primary'
                      onClick={() => productStore.clearForm()}>Tạo mới</Link>
            </div>
            <div className="d-flex mb-5 align-items-center">
                <div className='d-flex align-items-center mr-2 position-relative'>
                    <i className='bi bi-search cursor-pointer fs-1x fs-3 position-absolute ms-4'
                       onClick={async () => productStore.getProducts()}/>
                    <input type='text' value={productStore.keyword}
                           onChange={(e: any) => productStore.keyword = e.currentTarget.value}
                           onKeyDown={async (e: any) => {
                               if (e.key === 'Enter') {
                                   productStore.page = 0;
                                   await productStore.getProducts()
                               }
                           }} data-kt-ecommerce-order-filter='search'
                           className='form-control form-control-solid w-250px ps-12'
                           placeholder='Tìm kiếm sản phẩm'/>
                </div>
                <div className="w-100 mw-250px mr-2" style={{marginLeft: 16}}>
                    <select onChange={async (e: any) => {
                        productStore.filter_shopId = e.currentTarget.value;
                        productStore.page = 0;
                        await productStore.getProducts()
                    }} className="form-select form-select-solid">
                        <option value="">Chọn cửa hàng</option>
                        {shopStore.listShop.map((item, i) => {
                            return (
                                <option key={i} value={item.id}>{item.name}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="w-100 mw-250px mr-2" style={{marginLeft: 16}}>
                    <select onChange={async (e: any) => {
                        productStore.categoryId = e.currentTarget.value;
                        productStore.page = 0;
                        await productStore.getProducts()
                    }} className="form-select form-select-solid">
                        <option value="">Chọn danh mục</option>
                        {categoryStore.listCate.map((item, i) => {
                            return (
                                <option key={i} value={item.id}>{item.name}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="w-100 mw-250px" style={{marginLeft: 16}}>
                    <select onChange={async (e: any) => {
                        productStore.state = e.currentTarget.value;
                        productStore.page = 0;
                        await productStore.getProducts()
                    }} className="form-select form-select-solid">
                        <option value="">Chọn trạng thái</option>
                        <option value="PENDING">Chờ duyệt</option>
                        <option value="PUBLIC">Đã duyệt</option>
                        <option value="REJECT">Từ chối</option>
                    </select>
                </div>
            </div>
            {productStore.isLoading ? <Loading/> : <div className='table-responsive'>
                {productStore.listProduct && productStore.listProduct.length > 0 ?
                    <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
                        <thead>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                            <th>STT</th>
                            <th>Ảnh sản phẩm</th>
                            <th>Tên sản phẩm</th>
                            <th>Cửa hàng</th>
                            <th>Danh mục</th>
                            <th>Giá</th>
                            <th>Trạng thái</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {productStore.listProduct.map((item: any, i: number) => {
                            return (
                                <tr key={i}>
                                    <td>{sttPage(productStore.page, i)}</td>
                                    <td>
                                        {item.media && <div className="symbol symbol-30px symbol-md-40px">
                                            {item.media.map((val:any, index: number) => {
                                                if(val.default){
                                                    return <img src={urlImage(val.url)} key={index} alt=""/>
                                                }
                                            })}
                                        </div>}
                                    </td>
                                    <td>{item.name}</td>
                                    <td>{item.shop?.name}</td>
                                    <td>{item.category?.name ?? ''} </td>
                                    <td>{item.variants && <span>{number_format(item.variants[0].salePrice)} đ</span>}</td>
                                    <td>{returnState(item.state)}</td>
                                    <td width="18%" className="text-end">
                                        {item.state == 'PENDING' && <>
                                            <button title="Duyệt shop" disabled={productStore.isLoadingBt}
                                                    onClick={() => productStore.changeState(item.id, "PUBLIC")}
                                                    className="btn btn-icon btn-primary btn-sm"><i
                                                className='fas fa-circle-check'/></button>
                                            <span className='p-2'/>
                                            <button title="Từ chối duyệt" disabled={productStore.isLoadingBt}
                                                    onClick={() => productStore.changeState(item.id, "REJECT")}
                                                    className="btn btn-icon btn-danger btn-sm"><i
                                                className='fas fa-circle-xmark'/></button>
                                            <span className='p-2'/>
                                        </>}
                                        <Link to={`/store/edit-product/${slug(item.name)}/${item.id}`} title="Chỉnh sửa"
                                              className="btn btn-icon btn-warning btn-sm"
                                              onClick={() => productStore.getProductDetail(item.id)}><i
                                            className='fas fa-pen'/></Link>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table> : <NoContent/>}
            </div>
            }
            <PopupConfirm action={productStore.delete} isLoading={productStore.isLoadingBt}/>
            <Paginate currentPage={productStore.page} totalPage={productStore.totalPage} callback={handlePageClick}/>
        </>

    )
}

export default observer(ProductWrapper)