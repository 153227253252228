import React from 'react'
import {observer} from 'mobx-react'
import {newsStore} from '../NewsStore'

const DeleteNews = observer(() => {

  return (
    <div className='modal fade' id='delete_news' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered mw-500px'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>Bạn có chắc muốn xóa ?</h2>
            <button type="button" id="close_delete_new" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
          </div>
          <div className='modal-footer d-flex justify-content-end py-6 px-9'>
            <button type="reset" className="btn btn-light btn-active-light-primary me-2" data-bs-dismiss='modal'>Hủy</button>
            <button type='submit' className='btn btn-primary' onClick={() => newsStore.delete()} disabled={newsStore.isLoadingBt}>
              {newsStore.isLoadingBt ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Vui lòng chờ...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'/>
                </span>
              ) : 'Đồng ý'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
})

export default DeleteNews